import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, TextField, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Chip } from '@mui/material';
import TinkerLabListAPIs from '../../utilities/api/TinkersLab/TinkerLabListAPIs';

const CreateTL = ({ open, handleClose }) => {
  const [ldapDomains, setLdapDomains] = useState([]);

  const formik = useFormik({
    initialValues: {
      collegeName: '',
      tlName: '',
      adminEmail: '',
      ldapDomain: ''
    },
    validationSchema: Yup.object({
      collegeName: Yup.string().required('Required'),
      tlName: Yup.string().required('Required'),
      adminEmail: Yup.string().email('Invalid email address').required('Required')
    }),
    onSubmit: async (values, { resetForm }) => {
      const newTL = {
        name: values.tlName,
        college: values.collegeName,
        accepted_ldaps: ldapDomains
      };

      try {
        const response = await TinkerLabListAPIs.NewTinkerLabsAdd(newTL);
        console.log('New TL created:', response);
        handleClose();
        resetForm();
        setLdapDomains([]);
      } catch (error) {
        console.error('Error creating new TL:', error);
      }
    }
  });

  const handleCancel = () => {
    formik.resetForm();
    setLdapDomains([]);
    handleClose();
  };  

  const handleLdapDomainChange = (event) => {
    const value = event.target.value;
    if (value.endsWith(' ') || value.endsWith(',')) {
      const trimmedValue = value.trim().replace(',', '');
      if (trimmedValue.startsWith('@') && !ldapDomains.includes(trimmedValue)) {
        setLdapDomains([...ldapDomains, trimmedValue]);
        formik.setFieldValue('ldapDomain', '');
      }
    } else {
      formik.setFieldValue('ldapDomain', value);
    }
  };

  const handleDeleteChip = (chipToDelete) => () => {
    setLdapDomains((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="md" fullWidth>
      <DialogTitle>Create TL</DialogTitle>
      <DialogContent sx={{ padding: '36px 44px' }}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
          }}
          onSubmit={formik.handleSubmit}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '50%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography component="label" variant="body1" sx={{ color: '#3f3f3f', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                College Name <span style={{ color: '#c90505' }}>*</span>:
              </Typography>
              <TextField
                required
                name="collegeName"
                variant="outlined"
                value={formik.values.collegeName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.collegeName && Boolean(formik.errors.collegeName)}
                helperText={formik.touched.collegeName && formik.errors.collegeName}
                placeholder="Name of college"
                sx={{
                  borderRadius: '8px',
                  borderColor: '#b5b5b5',
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography component="label" variant="body1" sx={{ color: '#3f3f3f', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                Admin Email Address <span style={{ color: '#c90505' }}>*</span>:
              </Typography>
              <TextField
                required
                name="adminEmail"
                variant="outlined"
                value={formik.values.adminEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.adminEmail && Boolean(formik.errors.adminEmail)}
                helperText={formik.touched.adminEmail && formik.errors.adminEmail}
                placeholder="Enter email address"
                sx={{
                  borderRadius: '8px',
                  borderColor: '#b5b5b5',
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '50%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography component="label" variant="body1" sx={{ color: '#3f3f3f', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                TL Name <span style={{ color: '#c90505' }}>*</span>:
              </Typography>
              <TextField
                required
                name="tlName"
                variant="outlined"
                value={formik.values.tlName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.tlName && Boolean(formik.errors.tlName)}
                helperText={formik.touched.tlName && formik.errors.tlName}
                placeholder="Name of TL"
                sx={{
                  borderRadius: '8px',
                  borderColor: '#b5b5b5',
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography component="label" variant="body1" sx={{ color: '#3f3f3f' }}>
                LDAP Domain:
              </Typography>
              <TextField
                name="ldapDomain"
                variant="outlined"
                placeholder="Enter LDAP domain"
                value={formik.values.ldapDomain}
                onChange={handleLdapDomainChange}
                onBlur={formik.handleBlur}
                error={formik.touched.ldapDomain && Boolean(formik.errors.ldapDomain)}
                helperText={formik.touched.ldapDomain && formik.errors.ldapDomain}
                sx={{
                  borderRadius: '8px',
                  borderColor: '#b5b5b5',
                }}
              />
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                {ldapDomains.map((domain, index) => (
                  <Chip
                    key={index}
                    label={domain}
                    onDelete={handleDeleteChip(domain)}
                    sx={{
                      backgroundColor: '#e0e0e0',
                      borderRadius: '8px',
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px', justifyContent: 'flex-end' }}>
        <Button
          onClick={handleCancel}
          variant="outlined"
          sx={{
            marginRight: '16px',
            padding: '12px 16px',
            borderColor: '#b5b5b5',
            borderRadius: '12px',
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={formik.handleSubmit}
          sx={{
            padding: '12px 16px',
            borderRadius: '12px',
            backgroundColor: '#007bff',
          }}
        >
          Create TL
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTL;
