import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import TimeAxis from "./TimeAxis";
import {
  eachDayOfInterval,
  endOfWeek,
  format,
  startOfWeek,
  getDay,
  getDate,
  getMonth,
  getMinutes,
} from "date-fns";
import {
  Grid,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BookingCard from "./cardCalender";
const times = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23"
];
let arr =[];

function WeeksData({ reservationList, handleMachineSelect, handleProjectSelect, selectedDate, setSelectedDate }) {
  const days = eachDayOfInterval({
    start: startOfWeek(selectedDate, { weekStartsOn: 1 }),
    end: endOfWeek(selectedDate, { weekStartsOn: 1 }),
  });


  const machineNames = useMemo(() => {
    let names = [];
    reservationList.forEach((data) => {
      const chk1 = startOfWeek(selectedDate) < new Date(data.start_time);
      const chk2 = endOfWeek(selectedDate) > new Date(data.start_time);
      if(chk1 && chk2){
        names.push(data.name);
      }
    });
    return [...new Set(names)];
  }, [selectedDate]);

  useEffect(() => {
    handleMachineSelect(machineNames);
  }, [machineNames]);

  const projectNames = useMemo(() => {
    let names = [];
    reservationList.forEach((data) => {
      const chk1 = startOfWeek(selectedDate) < new Date(data.start_time);
      const chk2 = endOfWeek(selectedDate) > new Date(data.start_time);
      if(chk1 && chk2){
        names.push(data.project_name);
      }
    });
    return [...new Set(names)];
  }, [selectedDate]);

  useEffect(() => {
    handleProjectSelect(projectNames);
  }, [projectNames]);

  return (
    <Grid container>
      <Grid item>
        <TimeAxis />
      </Grid>

      <Grid item xs>
        {/* <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <IconButton
            onClick={() =>
              setSelectedDate(
                (prevDate) =>
                  new Date(
                    prevDate.getFullYear(),
                    prevDate.getMonth(),
                    prevDate.getDate() - 7
                  )
              )
            }
          >
            <ChevronLeftIcon sx={{ color: "#212121" }} />
          </IconButton>
          <Typography sx={{ color: "#3F3F3F", fontWeight: "bold" }}>
            {format(selectedDate, "dd MMMM yyyy")}
          </Typography>
          <IconButton
            onClick={() =>
              setSelectedDate(
                (prevDate) =>
                  new Date(
                    prevDate.getFullYear(),
                    prevDate.getMonth(),
                    prevDate.getDate() + 7
                  )
              )
            }
          >
            <ChevronRightIcon sx={{ color: "#212121" }} />
          </IconButton>
        </Box> */}
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {days.map((day) => (
            <Box
              key={day.toISOString()}
              sx={{
                border: "1px solid #D3D3D3",
                flexGrow: 1,
                maxWidth: "20rem",
              }}
            >
              <Box
                sx={{
                  height: "3.8125rem",
                  background: "var(--Primary-Primary10, #E9E9E9)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontFamily: "Segoe UI",
                    fontSize: "1.25rem",
                    fontWeight:
                      getDay(day) === getDay(selectedDate) ? 700 : 600,
                    color:
                      getDay(day) === getDay(selectedDate) ? "#007BFF" : "black",
                  }}
                >
                  {format(day, "EEEE")}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontFamily: "Segoe UI",
                    fontSize: "1.5rem",
                    fontWeight:
                      getDay(day) === getDay(selectedDate) ? 700 : 600,
                    color:
                      getDay(day) === getDay(selectedDate) ? "#007BFF" : "black",
                  }}
                >
                  {format(day, "d")}
                </Typography>
              </Box>

              {times.map((time, index) => (
                <Box
                  key={index}
                  sx={{
                    height: "5.5rem",
                    padding: "0.5rem",
                    borderTop: "1px solid #D3D3D3",
                    flexGrow: 1,
                    flexShrink: 0,
                  }}
                >
                  {reservationList.map((data, ind) => {
                    const dataDate = getDate(new Date(data.start_time));
                    const dataMonth = getMonth(new Date(data.start_time));
                    const dataTime = format(new Date(data.start_time), "HH");
                    const chk =
                      dataDate === getDate(day) &&
                      dataMonth === getMonth(day) &&
                      dataTime === time;
                    if (chk) {
                      arr.push(data);
                    }
                    return null;
                  })}
                  {arr.map((data, idx) => {
                    let cpy_arr = arr;
                    if (idx === 0) arr = [];
                    return idx === 0 ? (
                      <BookingCard
                        key={data.id}
                        bookingName={data.name}
                        timeDuration={(
                          (new Date(data.end_time) - new Date(data.start_time)) /
                          (60000 * 60)
                        ).toFixed(2)}
                        id={data.id}
                        reservationList={cpy_arr}
                        marginStart={getMinutes(new Date(data.start_time)) / 10}
                      />
                    ) : null;
                  })}
                  {
                    arr.map((data, idx)=>{
                      let cpy_arr = arr;
                      if(idx===0)
                      arr=[];
                      return ( idx===0 ?
                        <BookingCard
                          key={data.id}
                          bookingName={data.name}
                          timeDuration={
                            ((new Date(data.end_time) - new Date(data.start_time)) /
                          (60000 * 60)).toFixed(2)
                          }
                          id={data.id}
                          reservationList={cpy_arr}
                          marginStart={getMinutes(new Date(data.start_time))/10}
                        /> : null
                      )
                    })
                  }
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
}

export default WeeksData;
