import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { axiosApiService } from '../../../utilities/api/axios';

const MachinesUsedChart = ({ tlMappings }) => {
  const chartRef = useRef(null);
  const machineChartRef = useRef(null);
  const [machineData, setMachineData] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const machineUsage = {};

        const fetchMachineData = async (TL_name) => {
          const response = await axiosApiService.get('/machine_reservation_list/', {
            headers: {
              'TL': TL_name,
            },
          });
          return response.data;
        };

        const isWithinLast7Days = (startTimeString) => {
          const reservationStartTime = new Date(startTimeString);
          const now = new Date();
          const sevenDaysAgo = new Date();
          sevenDaysAgo.setDate(now.getDate() - 7);
          return reservationStartTime >= sevenDaysAgo && reservationStartTime <= now;
        };

        for (const name of Object.values(tlMappings)) {
          const data = await fetchMachineData(name);
          data.forEach(item => {
            if (
              (item.approved === "Approved" || item.approved === "AutoApproved") &&
              isWithinLast7Days(item.start_time)
            ) {
              if (!machineUsage[item.name]) {
                machineUsage[item.name] = 0;
              }
              machineUsage[item.name] += item.duration;
            }
          });
        }

        // Sort machine usage data and take the top 10
        const sortedMachineUsage = Object.entries(machineUsage)
          .sort(([, a], [, b]) => b - a)
          .slice(0, 10);

        const topLabels = sortedMachineUsage.map(([label]) => label);
        const topData = sortedMachineUsage.map(([, data]) => data);

        setLabels(topLabels);
        setMachineData(topData);
      } catch (error) {
        console.error('Error fetching machine usage data:', error);
      }
    }

    fetchData();
  }, [tlMappings]);

  useEffect(() => {
    if (machineChartRef.current) machineChartRef.current.destroy();

    const maxUsage = Math.ceil(Math.max(...machineData) * 1.2); // Extend x-axis by 20%

    const machineCtx = chartRef.current.getContext('2d');
    machineChartRef.current = new Chart(machineCtx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Machine Usage (hours)',
            data: machineData,
            backgroundColor: 'rgba(255, 99, 132, 0.6)',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y', // This makes the bars horizontal
        scales: {
          x: {
            beginAtZero: true,
            max: maxUsage,
            ticks: {
              stepSize: 1, // Ensure the x-axis values are integers
            },
          },
        },
      },
    });

    return () => {
      if (machineChartRef.current) machineChartRef.current.destroy();
    };
  }, [labels, machineData]);

  return (
    <Card sx={{ borderRadius: '24px', backgroundColor: '#f9fafe', borderColor: '#d3d3d3', borderWidth: 1, borderStyle: 'solid', padding: '16px', flex: 1, width: '95%', height: 330, }}>
      <CardContent sx={{ width: '100%' }}>
        <Typography variant="h6" sx={{ fontSize: '25px', fontWeight: '700', color: '#212121', textAlign: 'left', letterSpacing: 0 }}>Most Used Machines</Typography>
        <Typography sx={{ fontSize: '12px', textTransform: 'lowercase', color: '#7a7a7a', marginTop: '12px', fontWeight: '500', textAlign: 'left', letterSpacing: 0 }}>Cumulative across all colleges for the current week</Typography>
        <Box sx={{ height: '220px', width: '90%', marginTop: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <canvas ref={chartRef} style={{ width: '100%' }} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default MachinesUsedChart;
