import React, { useState } from 'react';
import { Box } from '@mui/material';
import GridTutorialCard from './GridTutorialCard';
import ListTutorialCard from './ListTutorialCard';

const Content = ({ data, activeView, searchQuery, setSearch }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTutorialId, setSelectedTutorialId] = useState(null);

    const handleOpenDialog = (id) => {
        setSelectedTutorialId(id);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSearchQueryChange = (query) => {
        setSearch(query);
    };

    const filteredData = data.filter(item => {
        const topic = item.topic?.toLowerCase() || '';
        const description = item.description?.toLowerCase() || '';
        const mentor = item.mentor?.toLowerCase() || '';
        const query = searchQuery.toLowerCase();

        return topic.includes(query) || description.includes(query) || mentor.includes(query);
    });

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box
                sx={{
                    display: activeView === 0 ? 'grid' : 'block',
                    gridTemplateColumns: activeView === 0 ? 'repeat(3, 0.5fr)' : 'none',
                    gap: '20px',
                }}
            >
                {filteredData.map((item) =>
                    activeView === 0 ? (
                        <GridTutorialCard key={item.id} item={item} />
                    ) : (
                        <ListTutorialCard key={item.id} item={item} />
                    )
                )}
            </Box>
            {/* Dialog component can be added here if needed */}
        </Box>
    );
};

export default Content;
