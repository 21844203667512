import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import AdminPasswordAPIs from "../../utilities/api/AdminPasswordAPIs";
import SideBarComponent from "../../components/admin/login/SideBar";
import sideimage from "../../utilities/dummy_assets/commonlogin.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const typographyStyle = {
    color: "#5C5C5C",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "1.5rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.0165rem",
};

const boxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: ".6rem",
    alignSelf: "stretch",
};

const styledTextfield = {
    display: "flex",
    gap: "0.2rem",
    alignSelf: "stretch",
    borderRadius: "0.75rem",
};

const validation_schema = Yup.object({
    new_password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Required"),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
        .required('Required'),
});

const ResetPasswordPage = () => {
    const [passwordReset, setPasswordReset] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const token = new URLSearchParams(location.search).get("token");

    const formik = useFormik({
        initialValues: {
            new_password: "",
            confirm_password: "",
        },
        validationSchema: validation_schema,
        onSubmit: (values) => {
            const data = {
                token: token,
                new_password: values.new_password,
            };
            AdminPasswordAPIs.ResetPassword(data)
                .then((res) => {
                    setPasswordReset(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    });

    return (
        <Box sx={{ display: "flex" }}>
            <SideBarComponent />
            <Box
                sx={{
                    width: "100%",
                    backgroundImage: `url(${sideimage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                {!passwordReset ? (
                    <Box
                        sx={{
                            display: "flex",
                            padding: "2rem",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "1rem",
                            borderRadius: "1rem",
                            background: "#FFF",
                            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                            width: "400px",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#212121",
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontSize: "1.75rem",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                letterSpacing: "-0.02475rem",
                            }}
                        >
                            Reset Your Password
                        </Typography>
                        <Typography
                            sx={{
                                color: "#5C5C5C",
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontSize: "1rem",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "1.5rem",
                                letterSpacing: "-0.0165rem",
                            }}
                        >
                            Please enter your new password below.
                        </Typography>
                        <Box
                            component="form"
                            onSubmit={formik.handleSubmit}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "1rem",
                                width: "100%",
                            }}
                        >
                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    New Password
                                </Typography>
                                <TextField
                                    id="new_password"
                                    name="new_password"
                                    type="password"
                                    value={formik.values.new_password}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.new_password &&
                                        Boolean(formik.errors.new_password)
                                    }
                                    helperText={
                                        formik.touched.new_password &&
                                        formik.errors.new_password
                                    }
                                    sx={styledTextfield}
                                    placeholder="Enter new password"
                                    fullWidth
                                />
                            </Box>
                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    Confirm Password
                                </Typography>
                                <TextField
                                    id="confirm_password"
                                    name="confirm_password"
                                    type="password"
                                    value={formik.values.confirm_password}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.confirm_password &&
                                        Boolean(formik.errors.confirm_password)
                                    }
                                    helperText={
                                        formik.touched.confirm_password &&
                                        formik.errors.confirm_password
                                    }
                                    sx={styledTextfield}
                                    placeholder="Confirm new password"
                                    fullWidth
                                />
                            </Box>
                            <Button
                                type="submit"
                                sx={{
                                    display: "flex",
                                    padding: "0.75rem",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                    alignSelf: "stretch",
                                    borderRadius: "0.75rem",
                                    border: "1px solid #007BFF",
                                    background: "#007BFF",
                                    textTransform: "none",
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "#FFF",
                                        textAlign: "center",
                                        fontFamily: "Inter",
                                        fontSize: "1.25rem",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "normal",
                                        letterSpacing: "-0.01375rem",
                                    }}
                                >
                                    Reset Password
                                </Typography>
                            </Button>
                        </Box>
                        <Typography
                            onClick={() => navigate("/login")}
                            sx={{
                                fontSize: "1rem",
                                letterSpacing: "-0.01375rem",
                                color: "#007BFF",
                                cursor: "pointer",
                                marginTop: "1rem",
                            }}
                        >
                            Back to login
                        </Typography>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            padding: "2rem",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "1rem",
                            borderRadius: "1rem",
                            background: "#FFF",
                            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                            width: "400px",
                        }}
                    >
                        <CheckCircleIcon sx={{ fontSize: 50, color: "rgb(30,110,178)" }} />
                        <Typography
                            sx={{
                                color: "#212121",
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontSize: "1.75rem",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                letterSpacing: "-0.02475rem",
                            }}
                        >
                            Password Reset Successfully
                        </Typography>
                        <Typography
                            sx={{
                                color: "#5C5C5C",
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontSize: "1rem",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "1.5rem",
                                letterSpacing: "-0.0165rem",
                            }}
                        >
                            Your password has been reset successfully. You can now log in with your new password.
                        </Typography>
                        <Button
                            onClick={() => navigate("/login")}
                            sx={{
                                display: "flex",
                                padding: "0.75rem",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "0.5rem",
                                alignSelf: "stretch",
                                borderRadius: "0.75rem",
                                border: "1px solid #007BFF",
                                background: "#FFF",
                                textTransform: "none",
                            }}
                        >
                            <Typography
                                sx={{
                                    color: "#007BFF",
                                    textAlign: "center",
                                    fontFamily: "Inter",
                                    fontSize: "1.25rem",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01375rem",
                                }}
                            >
                                Go to Login
                            </Typography>
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default ResetPasswordPage;



// import React, { useState } from "react";
// import { Box, Button, TextField, Typography } from "@mui/material";
// import { useNavigate, useLocation } from "react-router-dom";
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import AdminPasswordAPIs from "utilities/api/AdminPasswordAPIs";
// import SideBarComponent from "../../components/auth/login/SideBar";
// import sideimage from "../../utilities/dummy_assets/commonlogin.png";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// const typographyStyle = {
//     color: "#5C5C5C",
//     textAlign: "center",
//     fontFamily: "Inter",
//     fontSize: "1.5rem",
//     fontStyle: "normal",
//     fontWeight: 400,
//     lineHeight: "normal",
//     letterSpacing: "-0.0165rem",
// };
// const boxStyle = {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "flex-start",
//     gap: ".6rem",
//     alignSelf: "stretch",
// };
// const styledTextfield = {
//     display: "flex",
//     gap: "0.2rem",
//     alignSelf: "stretch",
//     borderRadius: "0.75rem",
// };

// const validation_schema = Yup.object({
//     new_password: Yup.string()
//         .min(8, "Password must be at least 8 characters")
//         .required("Required"),
//     confirm_password: Yup.string()
//         .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
//         .required('Required'),
// });

// const ResetPasswordPage = () => {
//     const [passwordReset, setPasswordReset] = useState(false);
//     const navigate = useNavigate();
//     const location = useLocation();
//     const token = new URLSearchParams(location.search).get("token");

//     const formik = useFormik({
//         initialValues: {
//             new_password: "",
//             confirm_password: "",
//         },
//         validationSchema: validation_schema,
//         onSubmit: (values) => {
//             const data = {
//                 token: token,
//                 new_password: values.new_password,
//             };
//             AdminPasswordAPIs.ResetPassword(data)
//                 .then((res) => {
//                     setPasswordReset(true);
//                 })
//                 .catch((error) => {
//                     console.log(error);
//                 });
//         },
//     });

//     return (
//         <Box sx={{ display: "flex" }}>
//             <SideBarComponent />
//             <Box
//                 sx={{
//                     width: "100%",
//                     backgroundImage: `url(${sideimage})`,
//                     backgroundSize: "cover",
//                     backgroundRepeat: "no-repeat",
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     height: "100vh",
//                 }}
//             >
//                 {!passwordReset ? (
//                     <Box
//                         sx={{
//                             display: "flex",
//                             padding: "2rem",
//                             flexDirection: "column",
//                             alignItems: "center",
//                             gap: "1rem",
//                             borderRadius: "1rem",
//                             background: "#FFF",
//                             boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
//                             width: "400px",
//                         }}
//                     >
//                         <Typography
//                             sx={{
//                                 color: "#212121",
//                                 textAlign: "center",
//                                 fontFamily: "Inter",
//                                 fontSize: "1.75rem",
//                                 fontStyle: "normal",
//                                 fontWeight: 600,
//                                 lineHeight: "normal",
//                                 letterSpacing: "-0.02475rem",
//                             }}
//                         >
//                             Reset Your Password
//                         </Typography>
//                         <Typography
//                             sx={{
//                                 color: "#5C5C5C",
//                                 textAlign: "center",
//                                 fontFamily: "Inter",
//                                 fontSize: "1rem",
//                                 fontStyle: "normal",
//                                 fontWeight: 400,
//                                 lineHeight: "1.5rem",
//                                 letterSpacing: "-0.0165rem",
//                             }}
//                         >
//                             Please enter your new password below.
//                         </Typography>
//                         <Box
//                             component="form"
//                             onSubmit={formik.handleSubmit}
//                             sx={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                                 alignItems: "center",
//                                 gap: "1rem",
//                                 width: "100%",
//                             }}
//                         >
//                             <Box sx={boxStyle}>
//                                 <Typography sx={typographyStyle}>
//                                     New Password
//                                 </Typography>
//                                 <TextField
//                                     id="new_password"
//                                     name="new_password"
//                                     type="password"
//                                     value={formik.values.new_password}
//                                     onChange={formik.handleChange}
//                                     error={
//                                         formik.touched.new_password &&
//                                         Boolean(formik.errors.new_password)
//                                     }
//                                     helperText={
//                                         formik.touched.new_password &&
//                                         formik.errors.new_password
//                                     }
//                                     sx={styledTextfield}
//                                     placeholder="Enter new password"
//                                     fullWidth
//                                 />
//                             </Box>
//                             <Box sx={boxStyle}>
//                                 <Typography sx={typographyStyle}>
//                                     Confirm Password
//                                 </Typography>
//                                 <TextField
//                                     id="confirm_password"
//                                     name="confirm_password"
//                                     type="password"
//                                     value={formik.values.confirm_password}
//                                     onChange={formik.handleChange}
//                                     error={
//                                         formik.touched.confirm_password &&
//                                         Boolean(formik.errors.confirm_password)
//                                     }
//                                     helperText={
//                                         formik.touched.confirm_password &&
//                                         formik.errors.confirm_password
//                                     }
//                                     sx={styledTextfield}
//                                     placeholder="Confirm new password"
//                                     fullWidth
//                                 />
//                             </Box>
//                             <Button
//                                 type="submit"
//                                 sx={{
//                                     display: "flex",
//                                     padding: "0.75rem",
//                                     justifyContent: "center",
//                                     alignItems: "center",
//                                     gap: "0.5rem",
//                                     alignSelf: "stretch",
//                                     borderRadius: "0.75rem",
//                                     border: "1px solid #007BFF",
//                                     background: "#007BFF",
//                                     textTransform: "none",
//                                 }}
//                             >
//                                 <Typography
//                                     sx={{
//                                         color: "#FFF",
//                                         textAlign: "center",
//                                         fontFamily: "Inter",
//                                         fontSize: "1.25rem",
//                                         fontStyle: "normal",
//                                         fontWeight: 600,
//                                         lineHeight: "normal",
//                                         letterSpacing: "-0.01375rem",
//                                     }}
//                                 >
//                                     Reset Password
//                                 </Typography>
//                             </Button>
//                         </Box>
//                         <Typography
//                             onClick={() => navigate("/login")}
//                             sx={{
//                                 fontSize: "1rem",
//                                 letterSpacing: "-0.01375rem",
//                                 color: "#007BFF",
//                                 cursor: "pointer",
//                                 marginTop: "1rem",
//                             }}
//                         >
//                             Back to login
//                         </Typography>
//                     </Box>
//                 ) : (
//                     <Box
//                         sx={{
//                             display: "flex",
//                             padding: "2rem",
//                             flexDirection: "column",
//                             alignItems: "center",
//                             gap: "1rem",
//                             borderRadius: "1rem",
//                             background: "#FFF",
//                             boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
//                             width: "400px",
//                         }}
//                     >
//                         <CheckCircleIcon sx={{ fontSize: 50, color: "rgb(30,110,178)" }} />
//                         <Typography
//                             sx={{
//                                 color: "#212121",
//                                 textAlign: "center",
//                                 fontFamily: "Inter",
//                                 fontSize: "1.75rem",
//                                 fontStyle: "normal",
//                                 fontWeight: 600,
//                                 lineHeight: "normal",
//                                 letterSpacing: "-0.02475rem",
//                             }}
//                         >
//                             Password Reset Successfully
//                         </Typography>
//                         <Typography
//                             sx={{
//                                 color: "#5C5C5C",
//                                 textAlign: "center",
//                                 fontFamily: "Inter",
//                                 fontSize: "1rem",
//                                 fontStyle: "normal",
//                                 fontWeight: 400,
//                                 lineHeight: "1.5rem",
//                                 letterSpacing: "-0.0165rem",
//                             }}
//                         >
//                             Your password has been reset successfully. You can now log in with your new password.
//                         </Typography>
//                         <Button
//                             onClick={() => navigate("/login")}
//                             sx={{
//                                 display: "flex",
//                                 padding: "0.75rem",
//                                 justifyContent: "center",
//                                 alignItems: "center",
//                                 gap: "0.5rem",
//                                 alignSelf: "stretch",
//                                 borderRadius: "0.75rem",
//                                 border: "1px solid #007BFF",
//                                 background: "#FFF",
//                                 textTransform: "none",
//                             }}
//                         >
//                             <Typography
//                                 sx={{
//                                     color: "#007BFF",
//                                     textAlign: "center",
//                                     fontFamily: "Inter",
//                                     fontSize: "1.25rem",
//                                     fontStyle: "normal",
//                                     fontWeight: 600,
//                                     lineHeight: "normal",
//                                     letterSpacing: "-0.01375rem",
//                                 }}
//                             >
//                                 Go to Login
//                             </Typography>
//                         </Button>
//                     </Box>
//                 )}
//             </Box>
//         </Box>
//     );
// };

// export default ResetPasswordPage;
