import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import TimeAxis from "./TimeAxis";
import {
  getDate,
  getYear,
  format,
  getMinutes,
} from "date-fns";
import {
  Grid,
  Box,
  Typography,
} from "@mui/material";
import BookingCard from "./cardCalender";
import DatePickerDialog from "./popups/datePicker";

const times = [
  "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11",
  "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"
];
let arr = [];

function DaysData({ selectedDate, reservationList, handleMachineSelect, handleProjectSelect }) {
  const [selected, setSelectedDate] = useState(selectedDate);

  const dayDate = getDate(selected);
  const dayYear = getYear(selected);

  const machineNames = useMemo(() => {
    let names = [];
    reservationList.forEach((data) => {
      const dataDate = getDate(new Date(data.start_time));
      if (dayDate === dataDate) {
        names.push(data.name);
      }
    });
    return [...new Set(names)];
  }, [selected, reservationList]);

  useEffect(() => {
    handleMachineSelect(machineNames);
  }, [machineNames]);

  const projectNames = useMemo(() => {
    let projectName = [];
    reservationList.forEach((data) => {
      const dataDate = getDate(new Date(data.start_time));
      if (dayDate === dataDate) {
        projectName.push(data.project_name);
      }
    });
    return [...new Set(projectName)];
  }, [selected, reservationList]);

  useEffect(() => {
    handleProjectSelect(projectNames);
  }, [projectNames]);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  return (
    <Grid container>
      <Grid item>
        <TimeAxis />
      </Grid>

      <Grid item xs>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            key={selected.toISOString()}
            sx={{
              border: "1px solid #D3D3D3",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                height: "3.8125rem",
                background: "var(--Primary-Primary10, #E9E9E9)",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: "var(--Primary-Primary03, #5C5C5C)",
                  fontFamily: "Segoe UI",
                  fontSize: "2rem",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "2rem",
                  paddingTop: "4px",
                  paddingLeft: "8px",
                  paddingBottom: "0px",
                  color: "black",
                }}
              >
                {format(selected, 'LLLL') + " " + dayDate + ", " + dayYear}
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "var(--Primary-Primary03, #5C5C5C)",
                    fontFamily: "Segoe UI",
                    fontSize: "0.85rem",
                    fontStyle: "normal",
                    fontWeight: 600,
                    padding: "3px",
                    paddingLeft: "8px",
                    color: "black",
                  }}
                >
                  {format(selected, "EEEE")}
                </Typography>
              </Box>
            </Box>

            {times.map((time, index) =>
              <Box
                key={index}
                sx={{
                  height: "5.5rem",
                  padding: "0.5rem",
                  borderTop: "1px solid #D3D3D3",
                  flexGrow: 1,
                  flexShrink: 0,
                }}
              >
                {reservationList.map((data, idx) => {
                  const dataDate = getDate(new Date(data.start_time));
                  const dataTime = format(new Date(data.start_time), 'HH');
                  const chk = dayDate === dataDate && dataTime === time;
                  if (chk) {
                    arr.push(data);
                  }
                  return null;
                })}
                {arr.map((data, idx) => {
                  let cpy_arr = arr;
                  if (idx === 0)
                    arr = [];

                  return (idx === 0 ?
                    <BookingCard
                      key={data.id}
                      bookingName={data.name}
                      timeDuration={
                        ((new Date(data.end_time) - new Date(data.start_time)) /
                          (60000 * 60)).toFixed(2)
                      }
                      id={data.id}
                      reservationList={cpy_arr}
                      marginStart={getMinutes(new Date(data.start_time)) / 10}
                    /> : null
                  )
                })}
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid>
        <DatePickerDialog
          open={true}
          selectedDate={selected}
          handleDateChange={handleDateChange} />
      </Grid>
    </Grid>
  );
}

export default DaysData;
