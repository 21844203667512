import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';

const cardStyles = {
  common: {
    padding: '8px',
    alignContent:'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80px',
    width: '100%',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '12px',
    textAlign: 'center',
  },
  variant1: {
    backgroundColor: '#f2f3fc',
    borderColor: '#475890',
  },
  variant2: {
    backgroundColor: '#fff1e4',
    borderColor: '#d28d2b',
  },
  variant3: {
    backgroundColor: '#e6f2ff',
    borderColor: '#007bff',
  },
  variant4: {
    backgroundColor: '#f2f4f2',
    borderColor: '#434e45',
  },
};

const InfoCard = ({ value, description, variant }) => (
  <Grid item xs={12} sm={6} md={4}>
    <Paper
      elevation={3}
      style={{ ...cardStyles.common, ...cardStyles[variant] }}
    >
      <Typography variant="h4" style={{ fontSize: '22px', fontWeight: '700', color: '#212121'}}>
        {value}
      </Typography>
      <Typography
        variant="body1"
        style={{ fontSize: '14px', marginTop: '4px', color: '#5c5c5c', textTransform: 'lowercase'}}
      >
        {description}
      </Typography>
    </Paper>
  </Grid>
);

const InfoCards = ({ stats }) => (
  <Box sx={{ flexGrow: 1, mb: 4 }}>
    {stats.length > 0 ? (
      <Grid container spacing={3}>
        {stats.map((stat, index) => (
          <InfoCard
            key={index}
            value={stat.value}
            description={stat.description}
            variant={`variant${(index % 4) + 1}`}
          />
        ))}
      </Grid>
    ) : (
      <Typography variant="h6" align="center" color="textSecondary" style={{ marginTop: '20px' }}>
        No data is present
      </Typography>
    )}
  </Box>
);

export default InfoCards;
