import { axiosApiService } from "../axios";

const tagMapping = {
  'Free': 0,
  'Issue': 1,
  'Purchase': 2,
  'Exhaustible': 3,
  'NonExhaustible': 4,
  'InLabOnly': 5,
  'HighValue': 6,

}

const InventoryListAPIs = {


  InventoryListGet: async function({refresh_token, access_token}){
    const response = await axiosApiService.get(`/admin_inventory_list/`, {
        // headers:{
        //     'Authorization': `Bearer ${access_token}`,
        //     'Refresh-Token': refresh_token,
        // },
    });
    return response.data;
  },
  
  AddInventory: async function (values, token) {
    try {
      const response = await axiosApiService.post(`/admin_inventory_list/`, values);
      return response;
    } catch (error) {
      console.error('Error adding Equipment:', error);
      // You can choose to return an error response or throw an error
      throw error;
    }
  },
}
export default InventoryListAPIs;
