// src/features/locationSlice.js
import { createSlice } from '@reduxjs/toolkit';

const locationSlice = createSlice({
    name: 'location',
    initialState: { location_list: [] },
    reducers: {
        getLocationSuccess: (state, action) => {
            state.location_list = action.payload;
        },
        getLocationFail: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const { getLocationSuccess, getLocationFail } = locationSlice.actions;
export default locationSlice;
