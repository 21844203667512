// utilities/api/IMS/GlobalWebSocketService.js
class GlobalWebSocketService {
    constructor() {
      this.socket = null;
      this.messageCallback = null;
      this.senderUsername = null;
    }
  
    connect(accessToken, receiverUsername, senderUsername) {
      // IMPORTANT: Do NOT close an existing socket here, 
      // because we want this connection to remain open for the entire session.
      if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
        this.senderUsername = senderUsername;
        const url = `wss://sys.tinkerspace.mis.mapit.ai/ws/chat/${receiverUsername}/?token=${accessToken}`;
        console.log(`Global socket connecting: ${url}`);
  
        this.socket = new WebSocket(url);
  
        this.socket.onopen = () => {
          console.log('Global WebSocket connected');
        };
  
        this.socket.onmessage = (event) => {
          const data = JSON.parse(event.data);
          console.log('Global WebSocket message received:', data);
          if (this.messageCallback) {
            this.messageCallback(data);
          }
        };
  
        this.socket.onclose = (event) => {
          console.log('Global WebSocket closed:', event);
        };
  
        this.socket.onerror = (error) => {
          console.error('Global WebSocket error:', error);
        };
      }
    }
  
    disconnect() {
      // Close only on user logout or app teardown
      if (this.socket) {
        console.log('Global WebSocket disconnected');
        this.socket.close();
        this.socket = null;
        this.senderUsername = null;
      }
    }
  
    onMessage(callback) {
      this.messageCallback = callback;
    }
  }
  
  export default new GlobalWebSocketService();
  