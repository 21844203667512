import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import AddIcon from '@mui/icons-material/Add';
import { setIsNewTut } from '../../../../redux/slices/tutorialEditSlice';
import AddVideo from './AddVideo';
import MachineListAPIs from '../../../../utilities/api/machine/AdminMachineList';
import EquipmentListAPIs from '../../../../utilities/api/equipment/AdminEquipmentList';
import InventoryListAPIs from '../../../../utilities/api/inventory/AdminInventoryList';
import AdminModuleAPIs from '../../../../utilities/api/tutorials/AdminModuleAPIs';

const AddModuleForm = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const [videoComponents, setVideoComponents] = useState([{ id: 0 }]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [videoIds, setVideoIds] = useState([]);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [machines, setMachines] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [inventories, setInventories] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);

  const validationSchema = Yup.object({
    name: Yup.string().required('Module Name is required'),
    description: Yup.string().required('Module Description is required'),
    related_machines: Yup.array().required('Related Machines is required'),
    related_equipments: Yup.array().required('Related Equipments is required'),
    related_inventory: Yup.array().required('Related Inventory is required'),
    image: Yup.mixed().nullable(),  // Optional image field
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      related_machines: [],
      related_equipments: [],
      related_inventory: [],
      image: null,  // Initial value for image
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // Step 1: Send the image as POST request to create the module
      let createdModuleId = null;
      try {
        // Prepare FormData for the image upload
        const formData = new FormData();
        formData.append('image', thumbnail); // Append the image to FormData
        const imageResponse = await AdminModuleAPIs.CreateModulePost(formData);
        createdModuleId = imageResponse.id; // Get the module ID from the response

        console.log("Module created with ID:", createdModuleId);
      } catch (error) {
        console.error('Error during image upload and module creation:', error);
        return; // Stop if image upload fails
      }

      // Step 2: Prepare the module data excluding the image
      const submittedValues = {
        name: values.name,
        description: values.description,
        related_machines: values.related_machines.map(machine => machine.id),
        related_equipments: values.related_equipments.map(equipment => equipment.id),
        related_inventory: values.related_inventory.map(inventory => inventory.id),
        videos: videoIds.map((id, index) => ({ video: id, order: index + 1 })),
      };

      try {
        // Step 3: Send the rest of the module data as PATCH request
        await AdminModuleAPIs.UpdateModule(createdModuleId, submittedValues);
        console.log('Module updated successfully with all data');
        
        // Dispatch state update and reset the form
        dispatch(setIsNewTut(false));
        resetForm();
      } catch (error) {
        console.error('Error updating module with additional data:', error);
      }
    },
  });

  useEffect(() => {
    const { name, description } = formik.values;
    const formFilled = name && description;
    setIsFormFilled(formFilled);
  }, [formik.values]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = 'All data will be lost';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const fetchMachines = async () => {
      try {
        const machines = await MachineListAPIs.MachineListGet();
        setMachines(machines || []);
      } catch (error) {
        console.error('Failed to fetch machines', error);
      }
    };

    const fetchEquipments = async () => {
      try {
        const equipments = await EquipmentListAPIs.EquipmentListGet();
        setEquipments(equipments || []);
      } catch (error) {
        console.error('Failed to fetch equipments', error);
      }
    };

    const fetchInventories = async () => {
      try {
        const inventories = await InventoryListAPIs.InventoryListGet({ refresh_token: user.refresh, access_token: user.access });
        setInventories(inventories || []);
      } catch (error) {
        console.error('Failed to fetch inventories', error);
      }
    };

    fetchMachines();
    fetchEquipments();
    fetchInventories();
  }, [user.refresh, user.access]);

  const handleCancel = () => {
    const userConfirmed = window.confirm('All data will be lost. Are you sure you want to cancel?');
    if (userConfirmed) {
      dispatch(setIsNewTut(false));
    }
  };

  const addVideoComponent = () => {
    setVideoComponents([...videoComponents, { id: videoComponents.length }]);
  };

  const removeVideoComponent = (id) => {
    setVideoComponents(videoComponents.filter((video) => video.id !== id));
  };

  const handleUploadSuccess = (videoId, videoUrl) => {
    setVideoIds([...videoIds, videoId]);
    setVideoUrls([...videoUrls, videoUrl]);
  };

  const handleUploading = (isUploading) => {
    setUploading(isUploading);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setThumbnail(file);
  };

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      encType="multipart/form-data"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '95%',
        margin: '0 auto',
        padding: 2,
        position: 'relative',
      }}
    >
      <Typography variant="h5" sx={{ mb: 1 }}>
        Add Module
      </Typography>

      <Box>
        <Box>
          {videoComponents.map((video) => (
            <AddVideo
              key={video.id}
              onRemove={() => removeVideoComponent(video.id)}
              onUploadSuccess={handleUploadSuccess}
              isFormFilled={isFormFilled}
              handleUploading={handleUploading}
            />
          ))}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button variant="outlined" color="primary" onClick={addVideoComponent}>
            <AddIcon sx={{ marginRight: '2px', padding: '0px' }} />
            Add Video
          </Button>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography>Module Name</Typography>
        <TextField
          label="Module Name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fullWidth
          required
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <Typography>Module Description</Typography>
        <TextField
          label="Module Description"
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fullWidth
          required
          multiline
          rows={4}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
        />
        <Typography>Module Thumbnail</Typography>
        <input accept="image/*" id="thumbnail" type="file" onChange={handleImageChange} />
        {formik.errors.image && <Typography color="error">{formik.errors.image}</Typography>}
        {machines.length > 0 && (
          <>
            <Typography>Related Machines</Typography>
            <Autocomplete
              multiple
              options={machines}
              getOptionLabel={(option) => `${option.name} (ID: ${option.id})`}
              value={formik.values.related_machines}
              onChange={(event, value) => formik.setFieldValue('related_machines', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Related Machines"
                  name="related_machines"
                  error={formik.touched.related_machines && Boolean(formik.errors.related_machines)}
                  helperText={formik.touched.related_machines && formik.errors.related_machines}
                />
              )}
              disableCloseOnSelect
            />
          </>
        )}
        {equipments.length > 0 && (
          <>
            <Typography>Related Equipments</Typography>
            <Autocomplete
              multiple
              options={equipments}
              getOptionLabel={(option) => `${option.name} (ID: ${option.id})`}
              value={formik.values.related_equipments}
              onChange={(event, value) => formik.setFieldValue('related_equipments', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Related Equipments"
                  name="related_equipments"
                  error={formik.touched.related_equipments && Boolean(formik.errors.related_equipments)}
                  helperText={formik.touched.related_equipments && formik.errors.related_equipments}
                />
              )}
              disableCloseOnSelect
            />
          </>
        )}
        {inventories.length > 0 && (
          <>
            <Typography>Related Inventory</Typography>
            <Autocomplete
              multiple
              options={inventories}
              getOptionLabel={(option) => `${option.name} (ID: ${option.id})`}
              value={formik.values.related_inventory}
              onChange={(event, value) => formik.setFieldValue('related_inventory', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Related Inventory"
                  name="related_inventory"
                  error={formik.touched.related_inventory && Boolean(formik.errors.related_inventory)}
                  helperText={formik.touched.related_inventory && formik.errors.related_inventory}
                />
              )}
              disableCloseOnSelect
            />
          </>
        )}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
        <Button variant="outlined" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary" disabled={uploading}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default AddModuleForm;
