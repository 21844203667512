import { axiosApiService } from "../axios";

const ReportApi = {
    ReportListGet: async function () {
        const response = await axiosApiService.get(`/auth/report_user_issue_list/`);
        return response.data;
    },
    ReportListPost: async function (values) {
        const reportTitle = values.reportTitle;
        const reportIssue = values.reportIssue;
        const otherIssue = values.otherIssue;
        const userId = values.userId; // Extract userId from the values

        const TIER_CHOICES = {
            'Bookings': 1,
            'Messaging': 2,
            'Workshops': 3,
            'Records': 4,
            'Login': 5,
            'Issues/Purchases': 6,
            'Web Application': 7,
            'Other': 8,
        };
        
        const tier = TIER_CHOICES[reportTitle];
        const formData = new FormData();
        formData.append("tier", tier);

        console.log("Tier:", tier);
        console.log("Report Issue:", reportIssue);

        if (tier < 8) {
            formData.append(`sub_tier${tier}`, reportIssue);
        } else {
            formData.append("description", reportIssue);
        }

        formData.append("user", userId); // Dynamically append the user ID
        if (otherIssue !== "") {
            formData.append("description", otherIssue);
        }
        
        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }

        const response = await axiosApiService.post(`/auth/report_user_issue_list/`, formData, {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
        return response.data;
    },
};

export default ReportApi;
