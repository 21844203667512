import { axiosApiService } from "../axios";

const AdminInventoryPurchaseAPIs = {
    InventoryPurchaseListGet: async function () {
        try {
            const response = await axiosApiService.get(`/inventory_purchase_list/`);
            const data = response.data;
            return data;
        } catch (error) {
            console.error('Error fetching inventory purchase list:', error.response ? error.response.data : error.message);
            throw error;
        }
    },

    InventoryPurchaseDetailGet: async function (id) {
        try {
            const response = await axiosApiService.get(`/inventory_purchase_detail/${id}/`);
            const data = response.data;
            return data;
        } catch (error) {
            console.error(`Error fetching inventory purchase detail for ID ${id}:`, error.response ? error.response.data : error.message);
            throw error;
        }
    },

    InventoryPurchaseDetailPatch: async function (id, updateData) {
        // try {
        //     const response = await axiosApiService.patch(`/inventory_purchase_detail/${id}/`, updateData);
        //     const data = response.data;
        //     console.log('InventoryPurchaseDetailPatch',data);
        //     return data;
        // } catch (error) {
        //     console.error(`Error updating inventory purchase detail for ID ${id}:`, error.response ? error.response.data : error.message);
        //     throw error;
        // }
        try {
            const response = await axiosApiService.patch(`/inventory_purchase_detail/${id}/`, updateData);
            const data = response.data;
            console.log('InventoryPurchaseDetailPatch',data);
            console.log('updateData',updateData);
            return data;
        } catch (error) {
            console.error(`Error updating detail for ID ${id}:`, error.response ? error.response.data : error.message);
            throw error;
        }
    },

    InventoryPurchaseDetailDelete: async function (id) {
        try {
            const response = await axiosApiService.delete(`/inventory_purchase_detail/${id}/`);
            const data = response.data;
            return data;
        } catch (error) {
            console.error(`Error deleting inventory purchase detail for ID ${id}:`, error.response ? error.response.data : error.message);
            throw error;
        }
    }
};

export default AdminInventoryPurchaseAPIs;
