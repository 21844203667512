import { axiosApiService } from "../axios";

const AdminVideoAPIs = {
    VideoListGet: async function() {
        try {
            const response = await axiosApiService.get(`/training/tutorials/videos/`);
            console.log('Video List:', response);
            return response.data;
        } catch (error) {
            console.error('Error fetching video list:', error.response ? error.response.data : error.message);
            throw error;
        }
    },

    VideoDetailGet: async function(video_id) {
        try {
            const response = await axiosApiService.get(`/training/tutorials/videos/${video_id}/`);
            console.log(`Video ${video_id} Response:`, response);
            return response.data;
        } catch (error) {
            console.error(`Error fetching video ${video_id}:`, error.response ? error.response.data : error.message);
            throw error;
        }
    },

    CreateVideoPost: async function(videoData, token) {
        try {
            console.log('Creating Video:', videoData);
            const response = await axiosApiService.post(`/training/tutorials/videos/`, videoData, {
            });
            console.log('Video Create Response:', response);
            return response.data;
        } catch (error) {
            console.error('Error creating video:', error.response ? error.response.data : error.message);
            throw error;
        }
    },

    AddMCQDataPost: async function(mcqData, video_id, token) {
        if (!token) {
            throw new Error('Access token is missing.');
        }
        try {
            console.log('Adding MCQ Data:', mcqData);
            const response = await axiosApiService.post(`training/tutorials/videos/${video_id}/add_mcqs/`, mcqData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('MCQ Data Response:', response);
            return response.data;
        } catch (error) {
            console.error('Error Adding MCQ Data:', error.response ? error.response.data : error.message);
            throw error;
        }
    },

    UpdateVideo: async function(video_id, videoData) {
        try {
            console.log('Updating Video:', video_id, videoData);
            const response = await axiosApiService.patch(`/training/tutorials/videos/${video_id}/`, videoData);
            console.log(`Video ${video_id} Update Response:`, response);
            return response.data;
        } catch (error) {
            console.error(`Error updating video ${video_id}:`, error.response ? error.response.data : error.message);
            throw error;
        }
    },
};

export default AdminVideoAPIs;
