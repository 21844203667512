import React, { useState, useRef, useEffect } from "react";
import { Card, CardContent, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles"; // Use useTheme to access the theme
import reportData from "../../utilities/api/report/reportData";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  TextField,
  Button,
  MenuItem,
  Box,
  Typography,
  FormControl,
  Select,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import ReportApi from "../../utilities/api/report/reportApi";
import { useSelector } from "react-redux";

const typographyStyle = {
  color: "#333",
  textAlign: "center",
  fontFamily: "'Roboto', sans-serif",
  fontSize: "1.2rem",
  fontWeight: 700,
  lineHeight: "1.5",
  letterSpacing: "0.02rem",
};

const boxStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: ".6rem",
  alignSelf: "stretch",
  marginBottom: "1rem",
};
const RotateButton = styled(Button)(({ theme }) => ({
  position: "fixed",
  right: "-3.5%",
  transform: "rotate(90deg)",
  transformOrigin: "center center",
  zIndex: 1000,
  borderRadius: "0 0 10px 10px",
  backgroundColor: "#1976d2",
  color: "#fff",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
  },
}));

const ReportCard = styled(Card)({
  position: "fixed",
  top: "50%",
  right: "40%",
  transform: "translateY(-50%)",
  width: "400px",
  zIndex: 1000,
  borderRadius: "10px",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
});

const CloseButton = styled(IconButton)(({ theme }) => ({
  float: "right",
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  minWidth: 120,
  "& .MuiInputLabel-root": {
    color: "#666",
    fontWeight: 500,
  },
  "& .MuiInputBase-root": {
    borderRadius: 8,
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    transition: "box-shadow 0.3s ease-in-out",
    "&:hover": {
      boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
    },
  },
  "& .MuiSelect-select": {
    padding: theme.spacing(1.5),
  },
}));


const ContactInfo = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(2),
  backgroundColor: "#f5f5f5",
  borderRadius: "8px",
  textAlign: "center",
  "& a": {
    textDecoration: "none",
    // color: theme.palette.primary.main,
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const ReportButton = () => {
  const theme = useTheme(); 
  const [isCardOpen, setIsCardOpen] = useState(false);
  const [topOffset, setTopOffset] = useState(250);
  const buttonRef = useRef(null);
  const isDragging = useRef(false);
  const dragStarted = useRef(false);

  const { user } = useSelector((state) => state.user.user);

  const handleButtonClick = () => {
    setIsCardOpen(true);
  };

  const handleCloseCard = () => {
    setIsCardOpen(false);
  };

  const startDrag = (e) => {
    e.preventDefault();
    isDragging.current = true;
    dragStarted.current = false;
    document.body.style.userSelect = "none";
  };

  const stopDrag = () => {
    if (isDragging.current) {
      isDragging.current = false;
      dragStarted.current = true;
    }
    document.body.style.userSelect = "";
  };

  const onDrag = (e) => {
    if (isDragging.current) {
      const newTopOffset = e.clientY - buttonRef.current.offsetHeight / 2;
      const maxTopOffset = window.innerHeight - buttonRef.current.offsetHeight - 40;
      const minTopOffset = 40;
      if (newTopOffset >= minTopOffset && newTopOffset <= maxTopOffset) {
        setTopOffset(newTopOffset);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("mousemove", onDrag);
    window.addEventListener("mouseup", stopDrag);
    return () => {
      window.removeEventListener("mousemove", onDrag);
      window.removeEventListener("mouseup", stopDrag);
    };
  }, []);

  const validationSchema = Yup.object().shape({
    reportTitle: Yup.string().required("Report Title is required"),
    reportIssue: Yup.string().required("Report Issue is required"),
    otherIssue: Yup.string(),
  });

  useEffect(() => {
    formik.resetForm();
  }, [isCardOpen]);

  const formik = useFormik({
    initialValues: {
      reportTitle: "",
      reportIssue: "",
      otherIssue: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Submitting values", values);

      ReportApi.ReportListPost({ ...values, userId: user.id })
        .then((result) => {
          console.log(result);
          alert("You have registered successfully!");
          handleCloseCard();
        })
        .catch((error) => {
          console.log(error);
          alert(`${error.response.data.error}`);
        });
    },
  });

  return (
    <div>
      <RotateButton
        ref={buttonRef}
        variant="contained"
        onClick={handleButtonClick}
        onMouseDown={startDrag}
        sx={{ top: `${topOffset}px` }}
      >
        Report Issue
      </RotateButton>

      {isCardOpen && (
        <ReportCard>
          <CardContent>
            <CloseButton onClick={handleCloseCard}>
              <CloseIcon />
            </CloseButton>
            <Typography variant="h6" gutterBottom>
              Report an Issue
            </Typography>

            <form onSubmit={formik.handleSubmit}>
              <Box sx={boxStyle}>
                <Typography sx={typographyStyle}>Title</Typography>
                <StyledFormControl fullWidth>
                  <InputLabel id="report-title-label">Report Title</InputLabel>
                  <Select
                    labelId="report-title"
                    id="reportTitle"
                    name="reportTitle"
                    value={formik.values.reportTitle}
                    label="Report Title"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.reportTitle &&
                      Boolean(formik.errors.reportTitle)
                    }
                  >
                    {reportData.map((option, index) => (
                      <MenuItem key={index} value={option.issueTitle}>
                        {option.issueTitle}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.reportTitle && formik.errors.reportTitle && (
                    <FormHelperText error>
                      {formik.errors.reportTitle}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Box>

              <Box sx={boxStyle}>
                <Typography sx={typographyStyle}>Issue</Typography>
                <StyledFormControl fullWidth>
                  {formik.values.reportTitle !== "Other" ? (
                    <>
                      <InputLabel id="report-issue-label">
                        Report Issue
                      </InputLabel>

                      <Select
                        labelId="report-issue"
                        id="reportIssue"
                        name="reportIssue"
                        value={formik.values.reportIssue}
                        label="Report Issue"
                        onChange={formik.handleChange}
                        error={
                          formik.touched.reportIssue &&
                          Boolean(formik.errors.reportIssue)
                        }
                      >
                        {reportData
                          .filter(
                            (option) =>
                              option.issueTitle === formik.values.reportTitle
                          )
                          .flatMap((filteredOption) => filteredOption.issueInfo)
                          .map((issue, index) => (
                            <MenuItem key={index} value={issue}>
                              {issue}
                            </MenuItem>
                          ))}
                      </Select>
                    </>
                  ) : (
                    <TextField
                      labelId="report-issue"
                      id="reportIssue"
                      name="reportIssue"
                      value={formik.values.reportIssue}
                      label="Report Issue"
                      onChange={formik.handleChange}
                      error={
                        formik.touched.reportIssue &&
                        Boolean(formik.errors.reportIssue)
                      }
                    />
                  )}

                  {formik.touched.reportIssue && formik.errors.reportIssue && (
                    <FormHelperText error>
                      {formik.errors.reportIssue}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Box>

              {formik.values.reportIssue === "Other" && (
                <Box sx={boxStyle}>
                  <Typography sx={typographyStyle}>
                    Specify Other Issue
                  </Typography>
                  <StyledFormControl fullWidth>
                    <TextField
                      id="otherIssue"
                      name="otherIssue"
                      value={formik.values.otherIssue}
                      label="Other Issue"
                      onChange={formik.handleChange}
                    />
                  </StyledFormControl>
                </Box>
              )}

              <Box sx={{ textAlign: "center", mt: 2 }}>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Box>
            </form>

            {/* Contact Info Section */}
            <ContactInfo>
              <Typography variant="subtitle1">Contact Us</Typography>
              <Typography sx={{
                gap:4
              }}>
                Email:{" "}
                <a href="mailto:team@mapit.ai">
                  team@mapit.ai
                </a>
                {"  |  "}
                Phone:{" "}
                <a href="tel:+919967405516">
                  9967405516
                </a>
              </Typography>
              {/* <Typography>
                Phone:{" "}
                <a href="tel:+919967405516">
                  9967405516
                </a>
              </Typography> */}
            </ContactInfo>
          </CardContent>
        </ReportCard>
      )}
    </div>
  );
};

export default ReportButton;
