import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
// import PlacesAPI from 'utilities/api/placeApi';
import PlacesAPI from '../../../utilities/api/placeApi';
import FormField from './formfield';


const validationSchema = Yup.object().shape({
  studentName: Yup.string().required('Student name is required'),
  date: Yup.string().required('Date is required'),
  timeInterval: Yup.string().required('Interval is required'),
  machine: Yup.string().required('Machine name is required'),
  projectTitle: Yup.string().required('Project title is required'),
  purpose: Yup.string().required('Purpose is required'),
});

const AddReservationDialog = ({ open, onClose }) => {
  const [openDialog, setOpenDialog] = useState(open);

  useEffect(() => {
    console.log("Add reservation hit!");
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Add New Reservation</DialogTitle>
      <Formik
        initialValues={{
          studentName: '',
          date: '',
          timeInterval: '',
          machine: '',
          projectTitle: '',
          purpose: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log('42', values);
          const response = PlacesAPI.ReservationListPost(values);
          console.log(response);
          setSubmitting(false);
          setOpenDialog(false); // Close dialog after submission
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', padding: '5px' }}>
                    <PersonAddAltIcon sx={{ padding: '10px', marginRight: '4%' }} />
                    <FormField name="studentName" label="Name of Student" fieldType="textfield" />
                  </Box>
                  <Box sx={{ display: 'flex', padding: '5px' }}>
                    <CalendarTodayOutlinedIcon sx={{ padding: '10px', marginRight: '4%' }} />
                    <FormField name="date" label="Enter Date" fieldType="date" sx={{ borderRadius: '5px' }} />
                  </Box>
                  <Box sx={{ display: 'flex', padding: '5px' }}>
                    <AccessTimeOutlinedIcon sx={{ padding: '10px', marginRight: '4%' }} />
                    <FormField name="timeInterval" label="Time Interval" fieldType="timeInterval" />
                  </Box>
                  <Box sx={{ display: 'flex', padding: '5px' }}>
                    <StorefrontOutlinedIcon sx={{ padding: '10px', marginRight: '4%' }} />
                    <FormField name="machine" label="Enter Machine Name" fieldType="textfield" />
                  </Box>
                  <Box sx={{ display: 'flex', padding: '5px' }}>
                    <EditOutlinedIcon sx={{ padding: '10px', marginRight: '4%' }} />
                    <FormField name="projectTitle" label="Add Project Title" fieldType="textfield" />
                  </Box>
                  <Box sx={{ display: 'flex', padding: '5px' }}>
                    <NotesOutlinedIcon sx={{ padding: '10px', marginRight: '4%' }} />
                    <FormField name="purpose" label="Add Purpose" fieldType="textfield" sx={{ width: '100%', borderRadius: '10px' }} />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onClose}
                color="primary"
                variant="outlined"
                sx={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                  borderRadius: '12px',
                  borderWidth: '1px',
                  borderColor: 'action.active',
                  '&:hover': {
                    borderWidth: '1px',
                    borderColor: 'action.active',
                  },
                  mr: 1,
                }}
              >
                Discard
              </Button>
              <Button
                onClick={onClose}
                color="primary"
                variant="outlined"
                sx={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                  borderRadius: '12px',
                  borderWidth: '1px',
                  borderColor: 'action.active',
                  '&:hover': {
                    borderWidth: '1px',
                    borderColor: 'action.active',
                  },
                  mr: 1,
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disableElevation
                sx={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                  color: 'common.white',
                  backgroundColor: 'primary.main',
                  borderRadius: '12px',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                }}
                disabled={isSubmitting}
              >
                Add
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddReservationDialog;
