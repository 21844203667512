import React, { useState } from 'react';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import EditWorkshopForm from './EditWorkshopForm';
import CreateWorkshopForm from './CreateWorkshopForm';
import AttendanceTable from './AttendanceTable';

// Reusable component for the tab panel content
const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
    style={{ width: '100%' }}
  >
    {value === index && (
      <Box sx={{ padding: '0px 24px' }}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

// Styles for the tabs, changing based on whether the tab is selected
const tabStyles = (isSelected) => ({
  borderBottom: isSelected ? '2px solid #3434f5' : '2px solid #ccc',
  color: isSelected ? '#000' : '#777',
  textTransform: 'none',
  minWidth: 'unset',
  flexGrow: 1,
});

// Main component that displays the top bar with tabs for workshop details
const WorkshopDetailTopBar = () => {
  const [value, setValue] = useState(0);
  const isNewWorkshop = useSelector((state) => state.editMode.isNewWorkshop);

  // Event handler for changing tabs
  const handleChange = (event, newValue) => setValue(newValue);

  return (
    <div style={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        sx={{ backgroundColor: '#fff', padding: '0px 24px' }}
      >
        <Tab 
          label={isNewWorkshop ? "Create Workshop" : "Edit/View Workshop"} 
          sx={tabStyles(value === 0)} 
        />
        <Tab 
          label="Attendance List" 
          sx={tabStyles(value === 1)} 
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        {isNewWorkshop ? <CreateWorkshopForm /> : <EditWorkshopForm />}
      </TabPanel>
      
      <TabPanel value={value} index={1}>
        {isNewWorkshop ? (
          <Typography sx={{marginTop:'2rem', fontSize:'2rem'}}>Create Workshop First</Typography>
        ) : (
          <AttendanceTable />
        )}
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        <AttendanceTable />
      </TabPanel> */}
    </div>
  );
};

export default WorkshopDetailTopBar;