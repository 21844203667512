import { axiosApiService } from "../axios";
// import axios from "axios"; // Import axios library

const AdminGetUserAPIs = {
    

    UserDetailsGet: async function (query) {
        
        const response = await axiosApiService.get(`/training/search_user/?q=${query}`);
        // const response = await axios.get(`https://staging.misadmin.mapit.ai/api/training/search_user/?q=${query}`);
        console.log((response));
        return response.data
    },

   
}
export default AdminGetUserAPIs;