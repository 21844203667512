
import { axiosApiService } from "../axios";

const InventoryCategoryAPIs = {
    

    CategoryListGet: async function () {
        
        const response = await axiosApiService.get(`/admin_inventory_category_list/`);
        return response.data
       
    },
    CategoryListPost: async function (category, token) {
        const formData = new FormData();
        formData.append("name", category)
        const response = await axiosApiService.post(`/admin_inventory_category_list/`, formData, {
            headers: { 
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": `Bearer ${token}` // Include the token in the headers
              },
        });
        return response.data
       
    },

   
}
export default InventoryCategoryAPIs;
  
