// src/features/groupSlice.js
import { createSlice } from '@reduxjs/toolkit';

const groupSlice = createSlice({
    name: 'group',
    initialState: { group_list: [] },
    reducers: {
        getGroupSuccess: (state, action) => {
            state.group_list = action.payload;
        },
        getGroupFail: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const { getGroupSuccess, getGroupFail } = groupSlice.actions;
export default groupSlice;
