// ChatCard.js
import React from 'react';
import { Box, Typography, Avatar, IconButton, Badge } from '@mui/material';
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'; // Changed to InfoOutlinedIcon
import { useSelector } from 'react-redux';

const ChatCard = ({ userName, message, date, sender, onClick, unreadCount = 0 }) => { // Added unreadCount prop
    const { user } = useSelector(state => state.user.user);
    const formattedDate = new Date(date).toLocaleString("en-GB", {
        dateStyle: "short",
        timeStyle: "short",
    });

    const isSenderYou = sender === user.username;

    return (
        <Box
            onClick={onClick}
            role="button"
            tabIndex={0}
            // onKeyPress={(e) => { if (e.key === 'Enter') onClick(); }}
            sx={{
                alignItems: 'center',
                display: 'flex',
                padding: '10px 12px', // Adjusted padding
                backgroundColor: '#fff', // White background
                // borderRadius: '8px', // Rounded corners
                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)', // Softer shadow
                maxWidth: '100%',
                height: '70px', // Increased height for better spacing
                overflow: 'hidden',
                cursor: 'pointer',
                borderBottom: '1px solid #e0e0e0', // Light gray border at bottom
                // marginBottom: '6px', // Slightly more margin between cards
            }}
        >
            <Avatar sx={{ marginRight: '12px' }} /> {/* Increased Avatar margin */}
            <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                <Typography
                    variant="body1"
                    fontWeight="bold" // Bold user name
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {userName}
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {isSenderYou ? 'You: ' : ''}{message}
                </Typography>
            </Box>
            <Box sx={{ textAlign: 'right', alignSelf: 'flex-start', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}> {/* Aligned date and icon to end */}
                <Typography variant="caption" color="textSecondary">
                    {formattedDate}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton size="small" sx={{ marginLeft: '5px' }}>
                        <InfoOutlinedIcon fontSize="small" /> {/* Changed to InfoOutlinedIcon */}
                    </IconButton>
                    {unreadCount > 0 && ( // Conditionally render badge if unreadCount > 0
                        <Badge badgeContent={unreadCount} color="primary" sx={{ marginLeft: '6px', marginRight: '-8px' }}/>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default React.memo(ChatCard);