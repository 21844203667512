import React from 'react';
import { TextField, Box, InputAdornment, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useDispatch } from 'react-redux';
import { setIsNewWorkshop } from '../../../redux/slices/editModeSlice'; // Update with the correct path

const SearchToolbar = ({ searchQuery, setSearchQuery }) => {
  const dispatch = useDispatch();

  const handleCreateWorkshopClick = () => {
    dispatch(setIsNewWorkshop(true));
  };

  return (
    <Box sx={{ 
      padding: '0px', 
      marginBottom: '10px', 
      display: 'flex', 
      alignItems: 'center',
      justifyContent: 'space-between', // Align items and space between
    }}>
      <TextField
        // label={searchQuery ? undefined : 'Search'}
        variant="outlined"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        margin="normal"
        InputProps={{
          style: {
            backgroundColor: 'rgb(244,244,244)',
            borderRadius: '20px', // Applying border radius to the input
            height: '2.5rem',
            paddingLeft: '10px', // Add padding to the left of the input
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{
          width: '20%',
        }}
      />
      <Button
        variant="contained"
        sx={{
          height: '2.5rem',
          borderRadius: '10px',
          backgroundColor: 'rgb(63,63,63)',
        }}
        onClick={handleCreateWorkshopClick} // Add the onClick handler
      >
        <AddCircleOutlineIcon sx={{ marginRight: '5px' }} />
        Create Workshop
      </Button>
    </Box>
  );
};

export default SearchToolbar;