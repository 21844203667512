import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import Sidebar from './Sidebar';
import ChatWindow from './ChatWindow';
import { useLocation } from 'react-router-dom';
import AdminMessagingAPIs from '../../utilities/api/IMS/AdminMessagingAPIs';
import { useSelector, useDispatch } from 'react-redux';

// -- Import your ChatWebSocketService:
import ChatWebSocketService from '../../utilities/api/IMS/ChatWebSocketService';
import { v4 as uuidv4 } from 'uuid';

// -- Import actions from messagesSlice:
import {
  addMessage,
  clearUnreadCount,
  setCurrentThreadId,
  setActiveChatUser,
} from '../../redux/slices/messagesSlice';

const MessagingPage = () => {
  const { user, access } = useSelector((state) => state.user.user);

  // 1) Pull in the dispatch function
  const dispatch = useDispatch();

  // Local conversation states
  const [conversations, setConversations] = useState([]);
  const [messagesByConversation, setMessagesByConversation] = useState({});
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  // For storing last location
  const location = useLocation();
  const [lastLocation, setLastLocation] = useState('/');

  // For avoiding duplicate inbound messages
  const [lastSentMessageId, setLastSentMessageId] = useState(null);
  const [lastSentMessageText, setLastSentMessageText] = useState('');

  // On mount, capture where we came from, fetch threads
  useEffect(() => {
    if (location.state?.from) {
      setLastLocation(location.state.from);
    }
  }, [location.state]);

  useEffect(() => {
    getThreads();
  }, []);

  /**
   * Whenever the user selects a conversation:
   * 1) Connect via ChatWebSocketService
   * 2) Set Redux currentThreadId and activeChatUser
   * 3) Clear unread messages for that thread
   */
  useEffect(() => {
    if (selectedConversation !== null) {
      const conversationDetails = conversations.find(
        (conv) => conv.id === selectedConversation
      );
      if (conversationDetails) {
        // Connect with the existing approach: (token, receiver, sender)
        ChatWebSocketService.connect(
          access,
          conversationDetails.userName,
          user.username
        );
        ChatWebSocketService.onMessage(handleIncomingMessage);

        // Also dispatch actions so Redux knows which thread is active
        dispatch(setActiveChatUser(conversationDetails.userName));
        dispatch(setCurrentThreadId(selectedConversation));
        dispatch(clearUnreadCount(selectedConversation));
      }
    }

    // Cleanup on unmount or conversation change
    return () => {
      ChatWebSocketService.disconnect();
    };
  }, [selectedConversation, access, conversations, user.username, dispatch]);

  // ----------------------------------------------------------
  //  Fetch the threads from AdminMessagingAPIs
  // ----------------------------------------------------------
  const getThreads = async () => {
    try {
      const threads = await AdminMessagingAPIs.GetThreadList();
      const filteredThreads = threads.filter((thread) =>
        thread.messages.some(
          (message) =>
            message.sender === user.username ||
            (message.receivers && message.receivers.includes(user.username))
        )
      );

      // Convert to local "conversation" objects
      const convs = filteredThreads.map((thread) => {
        let otherUser = null;
        if (thread.messages && thread.messages.length > 0) {
          const message = thread.messages[0];
          if (message.sender === user.username && message.receivers) {
            otherUser = message.receivers.find(
              (receiver) => receiver !== user.username
            );
          } else if (message.sender !== user.username) {
            otherUser = message.sender;
          }
        }
        const latestMessage = thread.latest_message;
        return {
          id: thread.id,
          userName: otherUser || 'Unknown',
          message: latestMessage?.content || 'No message',
          date: latestMessage?.timestamp
            ? new Date(latestMessage.timestamp).toLocaleString()
            : 'No date',
          avatar: 'path_to_avatar',
        };
      });
      setConversations(convs);

      // Build local mapping of messages
      const messagesByConv = {};
      filteredThreads.forEach((thread) => {
        messagesByConv[thread.id] = thread.messages.map((message) => ({
          id: message.id,
          text: message.content || 'No content',
          date: message.timestamp
            ? new Date(message.timestamp).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })
            : 'No time',
          sender: message.sender,
          isCurrentUser: message.sender === user.username,
        }));
      });
      setMessagesByConversation(messagesByConv);

    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  // ----------------------------------------------------------
  //  Handle Inbound Message from ChatWebSocketService
  // ----------------------------------------------------------
  const handleIncomingMessage = useCallback(
    (data) => {
      console.log('Received incoming message:', data);

      // If message text matches the last text we sent, skip duplication logic
      if (data.message === lastSentMessageText) {
        console.log('Duplicate message received, ignoring:', data);
        return;
      }

      // Build local message
      const incomingMessage = {
        id: data.id || uuidv4(),
        text: data.message,
        date: data.timestamp
          ? new Date(data.timestamp).toLocaleString()
          : new Date().toLocaleString(),
        sender: data.sender,
        isCurrentUser: data.sender === user.username,
      };

      // 1) If the thread_id from the server matches the open conversation, add to local
      //    otherwise, you could handle storing to a different thread, etc.
      const threadId = selectedConversation || data.thread_id;

      // Add to local state
      setMessagesByConversation((prev) => ({
        ...prev,
        [threadId]: [...(prev[threadId] || []), incomingMessage],
      }));

      // 2) Update the conversation preview
      updateLastMessage(threadId, data.message, incomingMessage.date);

      // 3) Dispatch addMessage to Redux for global unread count
      //    Make sure to add a `threadId` field so the slice knows which thread it belongs to
      dispatch(
        addMessage({
          id: incomingMessage.id,
          text: incomingMessage.text,
          date: incomingMessage.date,
          sender: incomingMessage.sender,
          threadId: threadId,
          read: false, // We'll mark it read if threadId is active
        })
      );

      // If this conversation is currently open, we can also clear unread immediately
      // so it doesn't show as unread in the Redux store:
      if (threadId === selectedConversation) {
        dispatch(clearUnreadCount(threadId));
      }
    },
    [
      selectedConversation,
      lastSentMessageText,
      user.username,
      dispatch,
    ]
  );

  // ----------------------------------------------------------
  //  When user clicks a conversation in the Sidebar
  // ----------------------------------------------------------
  const handleConversationSelect = (id) => {
    setSelectedConversation(id);
    setSelectedUser(null);
    // We'll do the Redux dispatch in the useEffect above
  };

  // ----------------------------------------------------------
  //  When user selects a new user (no existing thread)
  // ----------------------------------------------------------
  const handleStartNewConversation = (userObj) => {
    setSelectedUser(userObj);
    setSelectedConversation(null);
  };

  // ----------------------------------------------------------
  //  Sending a message (from ChatWindow -> MessageInput)
  // ----------------------------------------------------------
  const addMessageToConversation = async (message) => {
    try {
      let threadId = selectedConversation;
      // If there's no existing conversation, create a new thread
      if (!threadId && selectedUser) {
        const newThread = await createThread(selectedUser);
        threadId = newThread.id;
        setSelectedConversation(threadId);
        setSelectedUser(null);

        // Also set in Redux
        dispatch(setActiveChatUser(selectedUser.username));
        dispatch(setCurrentThreadId(threadId));
        dispatch(clearUnreadCount(threadId));
      }

      if (threadId) {
        const messageId = uuidv4();
        setLastSentMessageId(messageId);
        setLastSentMessageText(message);

        const newMessage = {
          id: messageId,
          text: message,
          date: new Date().toLocaleString(),
          sender: user.username,
          isCurrentUser: true,
        };

        // Update local
        setMessagesByConversation((prev) => ({
          ...prev,
          [threadId]: [...(prev[threadId] || []), newMessage],
        }));

        // Send through WebSocket
        ChatWebSocketService.sendMessage(newMessage.text, threadId);

        // Update preview
        updateLastMessage(threadId, newMessage.text, newMessage.date);

        // Also add to Redux
        dispatch(
          addMessage({
            id: messageId,
            text: message,
            date: newMessage.date,
            sender: user.username,
            threadId: threadId,
            read: true, // It's immediately "read" for me
          })
        );
      }
    } catch (error) {
      console.error('Error adding message to conversation:', error);
    }
  };

  // ----------------------------------------------------------
  //  Create a new thread for a brand-new conversation
  // ----------------------------------------------------------
  const createThread = async (selectedUser) => {
    try {
      const threadData = {
        participants: [selectedUser.username],
        messages: [],
      };
      const newThread = await AdminMessagingAPIs.CreateThread(threadData);

      const newConversation = {
        id: newThread.id,
        userName: selectedUser.username,
        message: '',
        date: new Date().toLocaleString(),
        avatar: 'path_to_avatar',
      };

      setConversations((prevConvs) => [newConversation, ...prevConvs]);
      setMessagesByConversation((prevMessages) => ({
        ...prevMessages,
        [newThread.id]: [],
      }));

      return newThread;
    } catch (error) {
      console.error('Error creating new thread:', error);
      throw error;
    }
  };

  // ----------------------------------------------------------
  //  Update the conversation preview in local state
  // ----------------------------------------------------------
  const updateLastMessage = (id, message, time) => {
    setConversations((prev) => {
      const updatedConvs = prev.map((conv) =>
        conv.id === id ? { ...conv, message, date: time } : conv
      );
      // Move updated conversation to the top
      const updatedConv = updatedConvs.find((conv) => conv.id === id);
      const remainingConvs = updatedConvs.filter((conv) => conv.id !== id);
      return [updatedConv, ...remainingConvs];
    });
  };

  // Pick the conversation data for the ChatWindow
  const selectedConversationDetails = conversations.find(
    (conv) => conv.id === selectedConversation
  );

  return (
    <Box sx={{ display: 'flex', height: '92vh' }}>
      <Sidebar
        conversations={conversations}
        onSelectConversation={handleConversationSelect}
        lastLocation={lastLocation}
        onStartNewConversation={handleStartNewConversation}
      />
      {selectedConversationDetails ? (
        <ChatWindow
          messages={messagesByConversation[selectedConversation]}
          currentUser={user}
          conversationUser={selectedConversationDetails}
          onNewMessage={addMessageToConversation}
          conversationId={selectedConversation}
        />
      ) : selectedUser ? (
        <ChatWindow
          messages={[]}
          currentUser={user}
          conversationUser={{
            userName: selectedUser.username,
            avatar: 'path_to_avatar',
          }}
          onNewMessage={addMessageToConversation}
          conversationId={null}
        />
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'gray',
          }}
        >
          <Typography variant="h6">
            Select a conversation or start a new one
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default MessagingPage;
