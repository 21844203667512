import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import AddModuleForm from './AddModuleForm';
import EditModuleForm from './EditModuleForm'; // Import the EditModuleForm component

// Reusable component for the tab panel content
const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
    style={{ width: '100%' }}
  >
    {value === index && (
      <Box sx={{ padding: '0px 24px' }}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

// Styles for the tabs, changing based on whether the tab is selected
const tabStyles = (isSelected) => ({
  borderBottom: isSelected ? '2px solid #3434f5' : '2px solid #ccc',
  color: isSelected ? '#000' : '#777',
  textTransform: 'none',
  minWidth: 'unset',
  flexGrow: 1,
});

// Main component that displays the toolbar with tabs for adding a new module and viewing statistics
const TutorialDetailTopBar = () => {
  const [value, setValue] = useState(0);
  const isNewTut = useSelector((state) => state.tutorialEdit.isNewTut); // Access isNewTut state
  const tutEditMode = useSelector((state) => state.tutorialEdit.tutEditMode); // Access tutEditMode state

  // Event handler for changing tabs
  const handleChange = (event, newValue) => setValue(newValue);

  return (
    <div style={{ width: '100%' , mt:'1rem'}}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        sx={{ backgroundColor: '#fff', padding: '0px 24px' }}
      >
        <Tab 
          label="Add New Module" 
          sx={tabStyles(value === 0)} 
        />
        <Tab 
          label="Statistics" 
          sx={tabStyles(value === 1)} 
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        {/* // Render AddModuleForm if isNewTut is true */}
        {isNewTut && <AddModuleForm />} 
        
        {/* // Render EditModuleForm if tutEditMode is true */}
        {tutEditMode && <EditModuleForm />} 
      </TabPanel>
      
      <TabPanel value={value} index={1}>
        {/* <StatisticsView /> */}
      </TabPanel>
    </div>
  );
};

export default TutorialDetailTopBar;
