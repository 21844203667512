import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import AdminModuleAPIs from '../../../utilities/api/tutorials/AdminModuleAPIs';
import TopBarComponent from './Topbar';
import TutorialContent from './TutorialContent';
import nodatafound from '../../../utilities/dummy_assets/nodatafound.jpg';

const TutorialPage = () => {
  const user = useSelector(state => state.user.user);
  const [activeView, setActiveView] = useState(0);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchModules = async () => {
      try {
        const response = await AdminModuleAPIs.ModuleListGet();
        setData(response.reverse());
      } catch (error) {
        console.error('Error fetching module list:', error);
      }
    };

    fetchModules();
  }, [user]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box>
          <TopBarComponent setSearch={setSearch} activeView={activeView} setActiveView={setActiveView} />
          {data.length > 0 ? (
            <TutorialContent data={data} searchQuery={search} activeView={activeView} setSearch={setSearch} />
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <Box textAlign="center">
                <img src={nodatafound} alt="No data found" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
                <Typography variant="h6" color="textSecondary" sx={{ mt: 2 }}>
                  No data available
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TutorialPage;
