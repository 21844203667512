import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { axiosApiService } from '../../../utilities/api/axios';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const ActivityOfUsersChart = ({ tlMappings }) => {
  const chartRef = useRef(null);
  const activityChartRef = useRef(null);
  const [activeUsers, setActiveUsers] = useState(0);
  const [inactiveUsers, setInactiveUsers] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        let activeCount = 0;
        let inactiveCount = 0;

        const fetchUserData = async (TL_name) => {
          const response = await axiosApiService.get('/auth/users/', {
            headers: {
              'TL': TL_name,
            },
          });
          return response.data;
        };

        for (const name of Object.values(tlMappings)) {
          const data = await fetchUserData(name);
          data.forEach(item => {
            if (item.is_active) {
              activeCount++;
            } else {
              inactiveCount++;
            }
          });
        }

        setActiveUsers(activeCount);
        setInactiveUsers(inactiveCount);
      } catch (error) {
        console.error('Error fetching user activity data:', error);
      }
    }

    fetchData();
  }, [tlMappings]);

  useEffect(() => {
    if (activityChartRef.current) activityChartRef.current.destroy();

    const hasData = activeUsers > 0 || inactiveUsers > 0;

    if (hasData) {
      const activityCtx = chartRef.current.getContext('2d');
      activityChartRef.current = new Chart(activityCtx, {
        type: 'doughnut',
        data: {
          labels: ['Active', 'Inactive'],
          datasets: [
            {
              data: [activeUsers, inactiveUsers],
              backgroundColor: ['#FF6384', '#36A2EB'],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }

    return () => {
      if (activityChartRef.current) activityChartRef.current.destroy();
    };
  }, [activeUsers, inactiveUsers]);

  return (
    <Card sx={{ borderRadius: '24px', backgroundColor: '#f9fafe', borderColor: '#d3d3d3', borderWidth: '1px', borderStyle: 'solid', padding: '16px', width: '90%', height: '336px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CardContent>
        <Typography variant="h6" sx={{ fontSize: '22px', fontWeight: '700', color: '#212121', textAlign: 'center', letterSpacing: 0 }}>Activity of Users</Typography>
        {activeUsers > 0 || inactiveUsers > 0 ? (
          <Box sx={{ height: '250px', marginTop: '4px' }}>
            <canvas ref={chartRef} style={{ width: '100%' }} />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="250px" width="100%">
            <img src={nodatafoundImage} alt="No Data Found" style={{ width: '200px', marginBottom: '16px' }} />
            <Typography variant='h5' color="textSecondary">
              No data available
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default ActivityOfUsersChart;
