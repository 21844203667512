import React, { useState } from 'react';
import { Box, Typography, IconButton, TextField, Checkbox, ListItemText } from '@mui/material';
import { Search, MoreVert, Minimize } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import ConversationList from './ConversationList';
import AdminGetUserAPIs from '../../utilities/api/workshop/AdminGetUser';

const Sidebar = ({ conversations, onSelectConversation, lastLocation, onStartNewConversation }) => {
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleMinimizeClick = () => {
    navigate(lastLocation);
  };

  const handleInputChange = async (event, newInputValue) => {
    setSearchQuery(newInputValue);

    if (newInputValue.length > 0) {
      const users = await AdminGetUserAPIs.UserDetailsGet(newInputValue);
      setUserSuggestions(users.map((user) => ({
        id: user.id,
        name: user.name,
        email: user.email,
        username: user.username,
      })));
    } else {
      setUserSuggestions([]);
    }
  };

  const handleUserSelect = (event, selectedUser) => {
    if (selectedUser) {
      const existingConversation = conversations.find(
        (conv) => conv.userName === selectedUser.username
      );
      if (existingConversation) {
        onSelectConversation(existingConversation.id);
      } else {
        onStartNewConversation(selectedUser);
      }
    }
  };

  return (
    <Box
      sx={{
        width: '25%',
        backgroundColor: '#f5f5f5',
        borderRight: '1px solid #ddd',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">Messages</Typography>
        <Box>
          <IconButton onClick={handleMinimizeClick}>
            <Minimize />
          </IconButton>
          <IconButton>
            <MoreVert />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ padding: '10px' }}>
        <Autocomplete
          freeSolo
          options={userSuggestions}
          getOptionLabel={(option) => `${option.name} (${option.email})`}
          onInputChange={handleInputChange}
          onChange={handleUserSelect}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search"
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <Search sx={{ marginRight: '5px' }} />
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <Checkbox icon={<span />} checkedIcon={<span />} checked={false} />
              <ListItemText primary={`${option.name} (${option.email})`} />
            </li>
          )}
        />
      </Box>

      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {conversations.map((conv) => (
          <ConversationList
            key={conv.id}
            userName={conv.userName}
            message={conv.message}
            date={conv.date}
            avatar={conv.avatar}
            onClick={() => onSelectConversation(conv.id)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Sidebar;
