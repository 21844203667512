import React, { useState, useEffect } from 'react';
import { Paper, TableContainer, Box, Typography } from '@mui/material';
import TableHeaders from './TableHeaders';
import TableListRow from './TableListRow';
import CustomPagination from '../../../components/Inventory/Pagination';
import SearchToolbar from './SearchToolbar'; // Import the new SearchToolbar component
import AdminWorkshopAPIs from '../../../utilities/api/workshop/AdminWorkshopAPIs';
import { useSelector } from 'react-redux';
import nodatafound from '../../../utilities/dummy_assets/nodatafound.jpg';

const WorkshopContent = () => {
  const user = useSelector(state => state.user.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Set items per page to 5 for demonstration
  const [searchQuery, setSearchQuery] = useState(''); // State to manage search query
  const [workshopData, setWorkshopData] = useState([]);

  useEffect(() => {
    // Function to fetch workshop data from API
    const fetchWorkshopData = async () => {
      try {
        const data = await AdminWorkshopAPIs.WorkshopListGet();
        setWorkshopData(data);
      } catch (error) {
        console.error('Error fetching workshop data:', error);
      }
    };

    fetchWorkshopData();
  }, [user]);

  // Filter data based on search query across all parameters
  const filteredData = workshopData.filter(item => 
    Object.values(item).some(value => 
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  // Calculate total number of pages
  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Get current items in reverse order
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.reverse().slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Handle workshop deletion
  const handleDeleteWorkshop = (deletedWorkshopId) => {
    setWorkshopData(prevWorkshopData => prevWorkshopData.filter(workshop => workshop.id !== deletedWorkshopId));
  };

  return (
    <Box sx={{ width: '100%' }}>
      {/* Add search toolbar */}
      <SearchToolbar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      {totalItems > 0 ? (
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            border: '0.5px solid rgba(181, 181, 181, 1)',
            background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #B5B5B5, #B5B5B5)',
            boxShadow: 'none',
            maxWidth: '100%',
            borderRadius: '8px',
          }}
        >
          <TableHeaders />
          {currentItems.map((item, index) => (
            <TableListRow key={index} item={item} onDelete={handleDeleteWorkshop} />
          ))}
          <CustomPagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
          />
        </TableContainer>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
          <Box textAlign="center">
            <img src={nodatafound} alt="No data found" style={{ maxWidth: '100%', maxHeight: '50vh' }} />
            <Typography variant="h6" color="textSecondary" sx={{ mt: 2 }}>
              No data available
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default WorkshopContent;
