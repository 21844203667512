// src/redux/slices/messagesSlice.js

import { createSlice } from '@reduxjs/toolkit';

const messagesSlice = createSlice({
  name: 'messages',
  initialState: {
    messages: [],          // Array to hold all messages
    unreadCount: 0,        // Total number of unread messages
    lastReadMessageId: {}, // Object to track last read message per thread (if needed)
    currentThreadId: null, // Currently active chat thread ID
    activeChatUser: null,  // Username of the active chat user (if needed)
  },
  reducers: {
    addMessage: (state, action) => {
      const message = action.payload;
      console.log('addMessage called with:', message);

      // 1) Push message into the global array
      state.messages.push(message);

      // 2) If the message is from the currently logged-in user, do NOT increment unread
      //    (We assume you can figure out "my" username or store it in Redux. For now, skip if you want.)
      // if (message.sender === state.myUsername) return;

      // 3) If user is currently in that same thread => do NOT count as unread
      if (message.threadId === state.currentThreadId || message.sender === state.activeChatUser) {
        console.log(`Message is in the active thread #${state.currentThreadId} with user %${state.activeChatUser}, not incrementing unread.`);
        // Mark it read if you want
        message.read = true;
        return;
      }

      // 4) Otherwise, increment unreadCount
      state.unreadCount += 1;
      console.log('Unread count incremented:', state.unreadCount);
    },

    clearUnreadCount: (state, action) => {
      // Mark all messages in thread as read, then recalc unread
      const threadId = action.payload;
      console.log('Clearing unread count for threadId:', threadId);

      // Mark these messages as read
      state.messages.forEach(msg => {
        if (msg.threadId === threadId || msg.sender === state.activeChatUser) {
          msg.read = true;
        }
      });

      // Recalculate how many total messages are still unread
      state.unreadCount = state.messages.filter(m => !m.read).length;
      console.log('Unread count after clearing:', state.unreadCount);
    },

    setCurrentThreadId: (state, action) => {
      const newThreadId = action.payload;
      state.currentThreadId = newThreadId;
      console.log('setCurrentThreadId =>', newThreadId);
    },

    setActiveChatUser: (state, action) => {
      const activeChatUser = action.payload;
      console.log('Setting active chat user:', activeChatUser);
      state.activeChatUser = activeChatUser;
    },

    clearMessages: (state) => {
      console.log('Clearing all messages and resetting state');
      state.messages = [];
      state.unreadCount = 0;
      state.lastReadMessageId = {};
      state.currentThreadId = null;
      state.activeChatUser = null;
    },
  },
});

export const {
  addMessage,
  clearUnreadCount,
  setCurrentThreadId,
  setActiveChatUser,
  clearMessages
} = messagesSlice.actions;

export default messagesSlice;
