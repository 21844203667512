
  


import { axiosApiService } from "../axios";

const InventoryLocationAPIs = {
    

    LocationListGet: async function () {
        
        const response = await axiosApiService.get(`/admin_location_list/`);
        return response.data
       
    },
    LocationListPost: async function (location, token) {
        const formData = new FormData();
        formData.append("name", location)
        const response = await axiosApiService.post(`/admin_location_list/`, formData, {
            headers: { 
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": `Bearer ${token}` // Include the token in the headers
              },
        });
        return response.data
       
    },
    
    

   
}
export default InventoryLocationAPIs;
  

