import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, CircularProgress, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import StarBorderIcon from '@mui/icons-material/StarBorder'; // Placeholder icon
import SettingsIcon from '@mui/icons-material/Settings'; // Placeholder icon
import WifiIcon from '@mui/icons-material/Wifi'; // Placeholder icon
import LoopIcon from '@mui/icons-material/Loop'; // Placeholder icon
import { useState, useEffect } from 'react';
import Delete from './Popups/Delete';
import EditInventoryDialog from './Popups/EditInventory';
import RemoveCircleOutlineSharpIcon from '@mui/icons-material/RemoveCircleOutlineSharp';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddStock from './Popups/AddStock';
import RemoveStock from './Popups/RemoveStock';
import { useSelector } from 'react-redux';
import InventoryListAPIs from '../../utilities/api/inventory/AdminInventoryList';
import InventoryDetailAPIs from '../../utilities/api/inventory/AdminInventoryDetail';
import nodatafound from '../../utilities/dummy_assets/nodatafound.jpg';
import { ImageBaseURL } from "../../utilities/api/axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const tagIcons = {
    "star": <StarBorderIcon />,
    "gear": <SettingsIcon />,
    "wifi": <WifiIcon />,
    "loop": <LoopIcon />,
};

const InventoryTable = ({ searchQuery, category, group, tag, update, setUpdate, flag, setFlag }) => {
    const user = useSelector(state => state.user.user);
    const token = user.access;

    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true); // New loading state
    const [page, setPage] = useState(0);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const [isEditInventoryOpen, setEditInventoryOpen] = useState(false);
    const [openAddStockPopup, setopenAddStockPopup] = useState(false);
    const [openRemoveStockPopup, setopenRemoveStockPopup] = useState(false);

    useEffect(() => {
        setLoading(true); // Set loading to true when fetching data
        const response = InventoryListAPIs.InventoryListGet(token);
        response.then((result) => {
            console.log(result);
            setTableData(result);
        }).catch((error) => {
            console.log("Error fetching data:", error);
            toast.error("Error fetching inventory data");
        }).finally(() => {
            setLoading(false); // Set loading to false once data is fetched
        });
    }, [update, user]);   

    const rows = tableData.map((item, index) => ({ ...item, index }));

    const handleEditInventory = (row) => {
        setCurrentRow(row);
        setEditInventoryOpen(true);
    };

    const handleOpenDeletePopup = (row) => {
        setCurrentRow(row);
        setOpenDeletePopup(true);
    };

    const handleOpenAddStockPopup = (row) => {
        setCurrentRow(row);
        setopenAddStockPopup(true);
    };

    const handleOpenRemoveStockPopup = (row) => {
        setCurrentRow(row);
        setopenRemoveStockPopup(true);
    };

    const handleCloseDeletePopup = () => {
        setOpenDeletePopup(false);
    };

    const handleCloseAddStockPopup = () => {
        setopenAddStockPopup(false);
    };

    const handleCloseRemoveStockPopup = () => {
        setopenRemoveStockPopup(false);
    };

    const handleDelete = () => {
        console.log('Delete row:', currentRow);
        const response = InventoryDetailAPIs.InventoryDetailDelete(currentRow.id, token);
        response.then(() => {
            setUpdate(prev => !prev);
            toast.success("Item successfully deleted");
        }).catch((error) => {
            console.log("Error while deleting:", error);
            toast.error("Error deleting item");
        });
    
        handleCloseDeletePopup();
    }; 

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const filteredRows = rows.filter((row) => {
        const lowercasedSearchQuery = searchQuery.toLowerCase();
        const lowercasedCategory = category.map((cat) => cat.toLowerCase());
        const lowercasedTags = tag.map((tg) => tg.toLowerCase());
        const lowercasedGroup = group.map((grp) => grp.toLowerCase());

        const matchesSearchQuery =
            !lowercasedSearchQuery ||
            (row.name && row.name.toLowerCase().includes(lowercasedSearchQuery)) ||
            (row.group && row.group.toLowerCase().includes(lowercasedSearchQuery)) ||
            (row.description && row.description.toLowerCase().includes(lowercasedSearchQuery)) ||
            [row.tag_1, row.tag_2, row.tag_3, row.tag_4].some((tag) =>
                tag && tag.toLowerCase().includes(lowercasedSearchQuery)
            ) ||
            (row.availability && row.availability.toLowerCase().includes(lowercasedSearchQuery)) ||
            (row.category && row.category.toLowerCase().includes(lowercasedSearchQuery)) ||
            (row.upc && row.upc.includes(lowercasedSearchQuery)) ||
            (row.stock_available && row.stock_available.toString().includes(lowercasedSearchQuery)) ||
            (row.stock_unit && row.stock_unit.toLowerCase().includes(lowercasedSearchQuery));

        const matchesCategory =
            !lowercasedCategory.length || (row.category && lowercasedCategory.includes(row.category.toLowerCase()));

        const matchesGroup =
            !lowercasedGroup.length || (row.group && lowercasedGroup.includes(row.group.toLowerCase()));

        const matchesTags =
            !lowercasedTags.length ||
            [row.tag_1, row.tag_2, row.tag_3, row.tag_4].some((tag) =>
                tag && lowercasedTags.includes(tag.toLowerCase())
            );

        return matchesSearchQuery && matchesCategory && matchesGroup && matchesTags;
    });

    const columns = [
        {
            field: 'itemDetails',
            headerName: 'Item Details',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'header-style',
            renderCell: (params) => {
                const imageUrl = params.row && params.row.image ? `${ImageBaseURL}${params.row.image}` : nodatafound;
                const text = params.row && params.row.name ? params.row.name : 'No Text';
                const subtext = params.row && params.row.upc ? params.row.upc : 'No Subtext';
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'center' }}>
                        <img src={imageUrl} alt={params.row.name} style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="subtitle2" sx={{ color: '#434343' }}>{text}</Typography>
                            <Typography variant="body3" sx={{ color: 'blue' }}>{subtext}</Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'group',
            headerName: 'Group',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'header-style',
            renderCell: (params) => (
                <Typography sx={{ color: '#434343', textAlign: 'center', width: '100%' }}>
                    {params.row.group}
                </Typography>
            )
        },
        {
            field: 'category',
            headerName: 'Category',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'header-style',
            renderCell: (params) => (
                <Typography sx={{ color: '#434343', textAlign: 'center', width: '100%' }}>
                    {params.row.category}
                </Typography>
            )
        },
        {
            field: 'tag',
            headerName: 'Tags',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'header-style',
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'center', whiteSpace: 'normal', textAlign: 'center'}}>
                    {params.row.tag_1} {params.row.tag_2} {params.row.tag_3} {params.row.tag_4}
                </Box>
            ),
        },
        {
            field: 'stock_available',
            headerName: 'Stock',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'header-style',
            renderCell: (params) => (
                <Typography sx={{ color: '#434343', textAlign: 'center', width: '100%' }}>
                    {params.row.stock_available} {params.row.stock_unit !== 'number' ? params.row.stock_unit : ''}
                </Typography>
            ),
        },
        {
            field: 'editstock',
            headerName: 'Edit stock',
            sortable: false,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'header-style',
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <IconButton aria-label="add" onClick={() => handleOpenAddStockPopup(params.row)}>
                        <AddCircleOutlineOutlinedIcon sx={{ color: 'green' }} />
                    </IconButton>
                    <IconButton aria-label="remove" onClick={() => handleOpenRemoveStockPopup(params.row)}>
                        <RemoveCircleOutlineSharpIcon sx={{ color: '#C90505' }} />
                    </IconButton>
                </Box>
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'header-style',
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <IconButton aria-label="edit" onClick={() => handleEditInventory(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleOpenDeletePopup(params.row)}>
                        <DeleteOutlineSharpIcon sx={{ color: '#C90505' }} />
                    </IconButton>
                </Box>
            ),
        },
    ];

    return (
        <Box sx={{ height: '60%', width: '97.5%', marginLeft: '1rem' }}>
            {loading ? (
                <Box sx={{ display: 'flex', marginTop: '100px', padding: '32px 24px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '21px' }}>          
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>                
                    <Typography sx={{ color: '#273F77', textAlign: 'center', fontFamily: 'Inter', fontSize: 26, fontWeight: 500, lineHeight: '150%', letterSpacing: '-0.352px' }}>
                        Loading...
                    </Typography>
                </Box>
            ) : filteredRows.length > 0 ? (
                <DataGrid
                    rows={filteredRows}
                    columns={columns}
                    pageSize={8}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPageOptions={[3]}
                    pagination
                    paginationMode="server" // Change to 'client' if handling pagination client-side
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnMenu
                    disableDensitySelector
                    disableExport
                    disableMultipleColumnsSorting
                    sortingOrder={[]}
                    disableColumnSort
                    sx={{
                        '& .header-style': {
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                        },
                    }}
                />
            ) : (
                <Box sx={{ textAlign: 'center', padding: 4 }}>
                    <img src={nodatafound} alt="No data found" style={{ width: '350px', marginBottom: '16px' }} />
                    <Typography variant="h6">No Data Found</Typography>
                </Box>
            )}
            <AddStock currentRow={currentRow} open={openAddStockPopup} onClose={handleCloseAddStockPopup} update={update} setUpdate={setUpdate} />
            <RemoveStock currentRow={currentRow} open={openRemoveStockPopup} onClose={handleCloseRemoveStockPopup} update={update} setUpdate={setUpdate} />
            <Delete currentRow={currentRow} open={openDeletePopup} onClose={handleCloseDeletePopup} onConfirm={handleDelete} />
            <EditInventoryDialog currentRow={currentRow} open={isEditInventoryOpen} onClose={() => setEditInventoryOpen(false)} update={update} setUpdate={setUpdate} flag={flag} setFlag={setFlag} />
            <ToastContainer /> 
        </Box>
    );
};

export default InventoryTable;
