import React, { useState } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Box, Checkbox, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AdminWorkshopAttendees from '../../../../utilities/api/workshop/AdminWorkshopAttendees';
import { useSelector } from "react-redux";

const AttendRows = ({ item, onDelete }) => {
    const user = useSelector(state => state.user.user);
    const token = user.access;
    const [presentChecked, setPresentChecked] = useState(item.is_present);
    const [absentChecked, setAbsentChecked] = useState(!item.is_present);

    const updateAttendeeStatus = async (isPresent) => {
        try {
            const values = {
                workshop: item.workshop,
                user: item.id,
                is_present: isPresent,
            };
            await AdminWorkshopAttendees.UpdateAttendeePatch(values, token);
            console.log('Attendee updated successfully');
        } catch (error) {
            console.error('Error updating attendee:', error);
        }
    };

    const handlePresentChange = (event) => {
        const isChecked = event.target.checked;
        setPresentChecked(isChecked);
        setAbsentChecked(!isChecked);
        updateAttendeeStatus(isChecked);
    };

    const handleAbsentChange = (event) => {
        const isChecked = event.target.checked;
        setAbsentChecked(isChecked);
        setPresentChecked(!isChecked);
        updateAttendeeStatus(!isChecked);
    };

    const handleDelete = async () => {
        try {
            const values = {
                workshop: item.workshop,
                user: item.id,
            };
            await AdminWorkshopAttendees.AttendeeUserDelete(values, token);
            console.log('Attendee deleted successfully');
            onDelete(item.id); // Notify parent component about deletion
        } catch (error) {
            console.error('Error deleting attendee:', error);
        }
    };

    return (
        <Box sx={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Card sx={{ display: 'flex', alignItems: 'center', bgcolor: 'white', marginBottom: '8px', width: '100%', height: '100%' }}>
                <Typography
                    sx={{
                        textAlign: 'center',
                        color: '#898989',
                        fontWeight: '400',
                        fontSize: '1rem',
                        padding: '0.875rem 0.75rem',
                        width: '35%',
                        wordWrap: 'break-word',
                        overflow: 'hidden',
                        whiteSpace: 'pre-wrap',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        maxHeight: '2rem',
                    }}
                >
                    {item.name}
                </Typography>
                <Checkbox
                    checked={presentChecked}
                    onChange={handlePresentChange}
                    sx={{
                        padding: '0.875rem 0.75rem',
                        color: '#898989',
                        width: '30%',
                        textAlign: 'center',
                        borderRadius: '0px',
                    }}
                />
                <Checkbox
                    checked={absentChecked}
                    onChange={handleAbsentChange}
                    sx={{
                        padding: '0.875rem 0.75rem',
                        color: '#898989',
                        width: '30%',
                        textAlign: 'center',
                        borderRadius: '0px',
                    }}
                />
                <IconButton
                    onClick={handleDelete}
                    sx={{
                        padding: '0.875rem 0.75rem',
                        width: '8%',
                        textAlign: 'center',
                        borderRadius: '0px',
                    }}
                >
                    <DeleteIcon sx={{ color: '#898989' }} />
                </IconButton>
            </Card>
        </Box>
    );
};

export default AttendRows;