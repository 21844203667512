import React, { useEffect, useRef } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import Chart from 'chart.js/auto';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const MostIssuedItemsChart = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const handleResize = debounce(() => {
      if (chartInstance.current) {
        try {
          chartInstance.current.resize();
        } catch (error) {
          console.error("ResizeObserver error:", error);
        }
      }
    }, 100);

    const resizeObserver = new ResizeObserver(handleResize);

    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (data && data.data.length > 0) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const maxValue = Math.max(...data.data);
      const suggestedMax = Math.ceil(maxValue * 1.2);

      chartInstance.current = new Chart(chartRef.current, {
        type: 'bar',
        data: {
          labels: data.labels,
          datasets: [{
            label: 'Number of items issued',
            data: data.data,
            backgroundColor: 'rgba(255,165,0,0.7)',
          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Items',
              },
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Number of Items Issued',
              },
              ticks: {
                stepSize: 1, // Ensure the scale uses integers
              },
              suggestedMax: suggestedMax, // Set the maximum value of the axis
            },
          },
        },
      });
    }
  }, [data]);

  return (
    <Paper elevation={3} style={{ padding: '16px', borderRadius: '16px', backgroundColor: '#f9fafe', border: '1px solid #d3d3d3', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <Typography variant="h6" style={{ fontSize: '24px', fontWeight: '700', color: '#212121' }}>Most Issued Items</Typography>
        <Typography style={{ marginLeft: '8px', fontSize: '12px', color: '#7a7a7a', fontWeight: '500', textTransform: 'lowercase' }}>in the current month</Typography>
      </Box>
      {data && data.data.length > 0 ? (
        <Box style={{ width: '100%', height: '280px', position: 'relative' }}>
          <canvas ref={chartRef} style={{ width: '100%', height: '100%' }} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="280px" width="100%">
          <img src={nodatafoundImage} alt="No Data Found" style={{ width: '200px', marginBottom: '16px' }} />
          <Typography variant='h5' color="textSecondary">
            No data available
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default MostIssuedItemsChart;
