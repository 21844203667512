import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardMedia, CardContent, Typography, Box, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { setTutEditMode, setTutCurrentItem } from '../../../redux/slices/tutorialEditSlice'; // Correct path
import {ImageBaseURL} from "../../../utilities/api/axios";

const ListTutorialCard = ({ item }) => {
  const dispatch = useDispatch();

  // const baseURL = 'https://staging.misadmin.mapit.ai/';

  const cardStyles = {
    cardContainer: {
      display: 'flex',
      width: '100%',
      margin: '10px',
      background: 'var(--s-secondary-50, #ffffff30)',
      border: '1px solid var(--s-secondary-400, #edf1ff)',
      borderRadius: '1rem',
      alignItems: 'center',
      position: 'relative', // Added to position the edit button
    },
    media: {
      width: '120px',
      margin: '10px',
      marginRight: '5%',
      borderRadius: '.5rem',
    },
    cardContent: {
      color: '#777777',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: '100%',
      padding: '10px',
    },
    topic: {
      fontFamily: 'Inter',
      fontSize: '20px',
      fontWeight: '600',
      lineHeight: '1',
      // marginBottom: '5px',
    },
    description: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '1.5',
      marginBottom: '5px',
    },
    mentor: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '1.5',
      color: '#777777',
    },
    editButton: {
      position: 'absolute',
      top: 8,
      right: 8,
      padding: '8px', // Adjusted padding
      color: 'white',
      backgroundColor: 'transparent',
      minWidth: '0px',
      boxShadow: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
    },

  };

  return (
    <Card sx={cardStyles.cardContainer}>
      <CardMedia
        component="img"
        sx={cardStyles.media}
        image={`${ImageBaseURL}${item.image}`}
        alt={item.name}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <CardContent sx={cardStyles.cardContent}>
          <Typography variant="h6" component="div" sx={cardStyles.topic}>
            {item.name}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={cardStyles.description}>
          {/* <Typography variant="h6" component="div" sx={cardStyles.topic}> */}
            {item.topic}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={cardStyles.description}>
            {item.description}
          </Typography>
          <Typography variant="subtitle2" color="text.primary" sx={cardStyles.mentor}>
            Mentor: {item.mentor}
          </Typography>
        </CardContent>
      </Box>
      <Button
        variant="contained"
        sx={cardStyles.editButton}
        startIcon={<EditIcon sx={{color:'blue', padding:'4px', border: '1px solid black', borderRadius: '50%',}}/>}
        onClick={() => {
          console.log('Edit button clicked');
          dispatch(setTutEditMode(true));
          dispatch(setTutCurrentItem(item)); // Set tutCurrentItem to item
        }}
      >
        {/* Edit */}
      </Button>
    </Card>
  );
};

export default ListTutorialCard;
