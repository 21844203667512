import React from 'react';
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  Typography,
  Container,
  Stack
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setEditMode, setHasReturned, setIsNewWorkshop } from '../../../../redux/slices/editModeSlice'; // Adjust path as needed
import { useFormik } from 'formik';
import * as yup from 'yup';
import AdminCreateWorkshopAPIs from '../../../../utilities/api/workshop/AdminCreateWorkshop';

// Custom validation method to ensure to_date is after from_date
const validationSchema = yup.object({
  title: yup.string('Enter workshop name').required('Workshop name is required'),
  description: yup.string('Enter description').required('Description is required'),
  location: yup.string('Enter location').required('Location is required'),
  from_date: yup.date('Select a valid date').required('From date is required'),
  to_date: yup.date('Select a valid date').required('To date is required')
    .min(yup.ref('from_date'), 'To date cannot be before from date'),
  from_time: yup.string('Select a valid time').required('From time is required'),
  to_time: yup.string('Select a valid time').required('To time is required'),
  eligible_years: yup.array().min(1, 'Select at least one year').required('Select years is required'),
  eligible_branches: yup.array().min(1, 'Select at least one branch').required('Select branches is required')
});

const CreateWorkshopForm = () => {
  const user = useSelector(state => state.user.user);
  const token = user.access;

  const dispatch = useDispatch();
  const editMode = useSelector((state) => state.editMode.editMode);
  const isNewWorkshop = useSelector((state) => state.editMode.isNewWorkshop);

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      location: '',
      from_date: '',
      to_date: '',
      from_time: '',
      to_time: '',
      eligible_years: [],
      eligible_branches: []
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const adjustedValues = {
          ...values,
          from_time: `${values.from_time}:00`,
          to_time: `${values.to_time}:00`
        };
        console.log('Workshop Submitted Values', adjustedValues);
        const response = await AdminCreateWorkshopAPIs.CreateWorkshopPost(adjustedValues, token);
        console.log('Workshop created successfully:', response);
        handleSaveDialog();
      } catch (error) {
        console.error('Failed to create workshop:', error);
      }
    },
  });

  const handleSaveDialog = () => {
    dispatch(setEditMode(false));
    dispatch(setHasReturned(true));
    dispatch(setIsNewWorkshop(false));
  };

  const handleCancel = () => {
    formik.resetForm();
    dispatch(setEditMode(false));
    dispatch(setHasReturned(true));
    dispatch(setIsNewWorkshop(false));
  };

  const branchOptions = [
    ['CSE', 'Computer Science and Engineering'],
    ['ECE', 'Electronics and Communication Engineering'],
    ['ME', 'Mechanical Engineering'],
    ['CE', 'Civil Engineering'],
    ['EE', 'Electrical Engineering'],
    ['CHE', 'Chemical Engineering'],
    ['MME', 'Metallurgical and Materials Engineering'],
    ['BT', 'Biotechnology'],
    ['CL', 'Chemistry'],
    ['MA', 'Mathematics'],
    ['PH', 'Physics'],
    ['HSS', 'Humanities and Social Sciences'],
    ['Other', 'Other']
  ];

  const yearOptions = [1, 2, 3, 4, 5];

  const handleSelectAllYears = (checked) => {
    formik.setFieldValue('eligible_years', checked ? yearOptions : []);
  };

  const handleSelectAllBranches = (checked) => {
    formik.setFieldValue('eligible_branches', checked ? branchOptions.map(branch => branch[0]) : []);
  };

  // Determine if all years or branches are selected
  const isAllYearsSelected = formik.values.eligible_years.length === yearOptions.length;
  const isAllBranchesSelected = formik.values.eligible_branches.length === branchOptions.length

  return (
    <Container>
      <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 4 }}>
        <Stack spacing={2}>
          <Box>
            <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
              Workshop Name
            </Typography>
            <TextField
              fullWidth
              label="Workshop Name"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              required
            />
          </Box>
          <Box>
            <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
              Description
            </Typography>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
              multiline
              rows={2}
              required
            />
          </Box>
          <Box>
            <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
              Location
            </Typography>
            <TextField
              fullWidth
              label="Location"
              name="location"
              value={formik.values.location}
              onChange={formik.handleChange}
              error={formik.touched.location && Boolean(formik.errors.location)}
              helperText={formik.touched.location && formik.errors.location}
              required
            />
          </Box>
          <Stack direction="row" spacing={2}>
            {/* Year selection box */}
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
                Year
              </Typography>
              <FormControl fullWidth>
                <InputLabel>Select Year</InputLabel>
                <Select
                  multiple
                  value={formik.values.eligible_years}
                  name="eligible_years"
                  onChange={(event) => {
                    const value = event.target.value;
                    if (value.includes('all')) {
                      handleSelectAllYears(!isAllYearsSelected);
                    } else {
                      formik.setFieldValue('eligible_years', typeof value === 'string' ? value.split(',') : value);
                    }
                  }}
                  renderValue={(selected) => Array.isArray(selected) ? selected.join(', ') : ''}
                  error={formik.touched.eligible_years && Boolean(formik.errors.eligible_years)}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={isAllYearsSelected}
                      indeterminate={formik.values.eligible_years.length > 0 && formik.values.eligible_years.length < yearOptions.length}
                    />
                    <ListItemText primary="All" />
                  </MenuItem>
                  {yearOptions.map((year) => (
                    <MenuItem key={year} value={year}>
                      <Checkbox checked={formik.values.eligible_years.includes(year)} />
                      <ListItemText primary={year} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formik.touched.eligible_years && formik.errors.eligible_years && (
                <Typography color="error">{formik.errors.eligible_years}</Typography>
              )}
            </Box>

            {/* Branch selection box */}
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
                Branch
              </Typography>
              <FormControl fullWidth>
                <InputLabel>Select Branch</InputLabel>
                <Select
                  multiple
                  value={formik.values.eligible_branches}
                  name="eligible_branches"
                  onChange={(event) => {
                    const value = event.target.value;
                    if (value.includes('all')) {
                      handleSelectAllBranches(!isAllBranchesSelected);
                    } else {
                      formik.setFieldValue('eligible_branches', typeof value === 'string' ? value.split(',') : value);
                    }
                  }}
                  renderValue={(selected) => Array.isArray(selected) ? selected.join(', ') : ''}
                  error={formik.touched.eligible_branches && Boolean(formik.errors.eligible_branches)}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={isAllBranchesSelected}
                      indeterminate={formik.values.eligible_branches.length > 0 && formik.values.eligible_branches.length < branchOptions.length}
                    />
                    <ListItemText primary="All" />
                  </MenuItem>
                  {branchOptions.map((branch) => (
                    <MenuItem key={branch[0]} value={branch[0]}>
                      <Checkbox checked={formik.values.eligible_branches.includes(branch[0])} />
                      <ListItemText primary={branch[1]} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formik.touched.eligible_branches && formik.errors.eligible_branches && (
                <Typography color="error">{formik.errors.eligible_branches}</Typography>
              )}
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
                From Date
              </Typography>
              <TextField
                fullWidth
                label="From Date"
                type="date"
                name="from_date"
                value={formik.values.from_date}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                error={formik.touched.from_date && Boolean(formik.errors.from_date)}
                helperText={formik.touched.from_date && formik.errors.from_date}
                required
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
                To Date
              </Typography>
              <TextField
                fullWidth
                label="To Date"
                type="date"
                name="to_date"
                value={formik.values.to_date}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                error={formik.touched.to_date && Boolean(formik.errors.to_date)}
                helperText={formik.touched.to_date && formik.errors.to_date}
                required
              />
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
                From Time
              </Typography>
              <TextField
                fullWidth
                label="From Time"
                type="time"
                name="from_time"
                value={formik.values.from_time}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                error={formik.touched.from_time && Boolean(formik.errors.from_time)}
                helperText={formik.touched.from_time && formik.errors.from_time}
                required
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
                To Time
              </Typography>
              <TextField
                fullWidth
                label="To Time"
                type="time"
                name="to_time"
                value={formik.values.to_time}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                error={formik.touched.to_time && Boolean(formik.errors.to_time)}
                helperText={formik.touched.to_time && formik.errors.to_time}
                required
              />
            </Box>
          </Stack>
          <Box display="flex" justifyContent="flex-end">
            <Button variant="outlined" color="secondary" onClick={handleCancel} sx={{ ml: 2 }}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" sx={{ ml: 2 }}>
              Save
            </Button>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};

export default CreateWorkshopForm;



// import React from 'react';
// import {
//   TextField,
//   Button,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Box,
//   Typography,
//   Container,
//   Stack
// } from '@mui/material';
// import { useSelector, useDispatch } from 'react-redux';
// import { setEditMode, setHasReturned, setIsNewWorkshop } from '../../../../redux/slices/editModeSlice'; // Adjust path as needed
// import { useFormik } from 'formik';
// import * as yup from 'yup';
// import AdminCreateWorkshopAPIs from '../../../../utilities/api/workshop/AdminCreateWorkshop';

// // Custom validation method to ensure to_date is after from_date
// const validationSchema = yup.object({
//   title: yup.string('Enter workshop name').required('Workshop name is required'),
//   description: yup.string('Enter description').required('Description is required'),
//   location: yup.string('Enter location').required('Location is required'),
//   from_date: yup.date('Select a valid date').required('From date is required'),
//   to_date: yup.date('Select a valid date').required('To date is required')
//     .min(yup.ref('from_date'), 'To date cannot be before from date'),
//   from_time: yup.string('Select a valid time').required('From time is required'),
//   to_time: yup.string('Select a valid time').required('To time is required'),
//   eligible_years: yup.array().min(1, 'Select at least one year').required('Select years is required'),
//   eligible_branches: yup.array().min(1, 'Select at least one branch').required('Select branches is required')
// });

// const CreateWorkshopForm = () => {
//   const user = useSelector(state => state.user.user);
//   const token = user.access;

//   const dispatch = useDispatch();
//   const editMode = useSelector((state) => state.editMode.editMode);
//   const isNewWorkshop = useSelector((state) => state.editMode.isNewWorkshop);

//   const formik = useFormik({
//     initialValues: {
//       title: '',
//       description: '',
//       location: '',
//       from_date: '',
//       to_date: '',
//       from_time: '',
//       to_time: '',
//       eligible_years: [],
//       eligible_branches: []
//     },
//     validationSchema: validationSchema,
//     onSubmit: async (values) => {
//       try {
//         const adjustedValues = {
//           ...values,
//           from_time: `${values.from_time}:00`,
//           to_time: `${values.to_time}:00`
//         };
//         console.log('Workshop Submitted Values', adjustedValues);
//         const response = await AdminCreateWorkshopAPIs.CreateWorkshopPost(adjustedValues, token);
//         console.log('Workshop created successfully:', response);
//         handleSaveDialog();
//       } catch (error) {
//         console.error('Failed to create workshop:', error);
//       }
//     },
//   });

//   const handleSaveDialog = () => {
//     dispatch(setEditMode(false));
//     dispatch(setHasReturned(true));
//     dispatch(setIsNewWorkshop(false));
//   };

//   const handleCancel = () => {
//     formik.resetForm();
//     dispatch(setEditMode(false));
//     dispatch(setHasReturned(true));
//     dispatch(setIsNewWorkshop(false));
//   };

//   const branchOptions = [
//     ['CSE', 'Computer Science and Engineering'],
//     ['ECE', 'Electronics and Communication Engineering'],
//     ['ME', 'Mechanical Engineering'],
//     ['CE', 'Civil Engineering'],
//     ['EE', 'Electrical Engineering'],
//     ['CHE', 'Chemical Engineering'],
//     ['MME', 'Metallurgical and Materials Engineering'],
//     ['BT', 'Biotechnology'],
//     ['CL', 'Chemistry'],
//     ['MA', 'Mathematics'],
//     ['PH', 'Physics'],
//     ['HSS', 'Humanities and Social Sciences'],
//     ['Other', 'Other']
//   ];

//   return (
//     <Container>
//       <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 4 }}>
//         <Stack spacing={2}>
//           <Box>
//             <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
//               Workshop Name
//             </Typography>
//             <TextField
//               fullWidth
//               label="Workshop Name"
//               name="title"
//               value={formik.values.title}
//               onChange={formik.handleChange}
//               error={formik.touched.title && Boolean(formik.errors.title)}
//               helperText={formik.touched.title && formik.errors.title}
//               required
//             />
//           </Box>
//           <Box>
//             <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
//               Description
//             </Typography>
//             <TextField
//               fullWidth
//               label="Description"
//               name="description"
//               value={formik.values.description}
//               onChange={formik.handleChange}
//               error={formik.touched.description && Boolean(formik.errors.description)}
//               helperText={formik.touched.description && formik.errors.description}
//               multiline
//               rows={2}
//               required
//             />
//           </Box>
//           <Box>
//             <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
//               Location
//             </Typography>
//             <TextField
//               fullWidth
//               label="Location"
//               name="location"
//               value={formik.values.location}
//               onChange={formik.handleChange}
//               error={formik.touched.location && Boolean(formik.errors.location)}
//               helperText={formik.touched.location && formik.errors.location}
//               required
//             />
//           </Box>
//           <Stack direction="row" spacing={2}>
//             <Box sx={{ flex: 1 }}>
//               <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
//                 Year
//               </Typography>
//               <FormControl fullWidth>
//                 <InputLabel>Select Year</InputLabel>
//                 <Select
//                   multiple
//                   value={formik.values.eligible_years}
//                   name="eligible_years"
//                   onChange={formik.handleChange}
//                   renderValue={(selected) => Array.isArray(selected) ? selected.join(', ') : ''}
//                   error={formik.touched.eligible_years && Boolean(formik.errors.eligible_years)}
//                 >
//                   {[1, 2, 3, 4, 5].map((year) => (
//                     <MenuItem key={year} value={year}>
//                       {year}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//               {formik.touched.eligible_years && formik.errors.eligible_years && (
//                 <Typography color="error">{formik.errors.eligible_years}</Typography>
//               )}
//             </Box>
//             <Box sx={{ flex: 1 }}>
//               <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
//                 Branch
//               </Typography>
//               <FormControl fullWidth>
//                 <InputLabel>Select Branch</InputLabel>
//                 <Select
//                   multiple
//                   value={formik.values.eligible_branches}
//                   name="eligible_branches"
//                   onChange={(event) => {
//                     // Ensure the value is always an array
//                     const value = event.target.value;
//                     formik.setFieldValue('eligible_branches', typeof value === 'string' ? value.split(',') : value);
//                   }}
//                   renderValue={(selected) => Array.isArray(selected) ? selected.join(', ') : ''}
//                   error={formik.touched.eligible_branches && Boolean(formik.errors.eligible_branches)}
//                 >
//                   {branchOptions.map((branch) => (
//                     <MenuItem key={branch[0]} value={branch[0]}>
//                       {branch[1]}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//               {formik.touched.eligible_branches && formik.errors.eligible_branches && (
//                 <Typography color="error">{formik.errors.eligible_branches}</Typography>
//               )}
//             </Box>
//           </Stack>
//           <Stack direction="row" spacing={2}>
//             <Box sx={{ flex: 1 }}>
//               <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
//                 From Date
//               </Typography>
//               <TextField
//                 fullWidth
//                 label="From Date"
//                 type="date"
//                 name="from_date"
//                 value={formik.values.from_date}
//                 onChange={formik.handleChange}
//                 InputLabelProps={{ shrink: true }}
//                 error={formik.touched.from_date && Boolean(formik.errors.from_date)}
//                 helperText={formik.touched.from_date && formik.errors.from_date}
//                 required
//               />
//             </Box>
//             <Box sx={{ flex: 1 }}>
//               <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
//                 To Date
//               </Typography>
//               <TextField
//                 fullWidth
//                 label="To Date"
//                 type="date"
//                 name="to_date"
//                 value={formik.values.to_date}
//                 onChange={formik.handleChange}
//                 InputLabelProps={{ shrink: true }}
//                 error={formik.touched.to_date && Boolean(formik.errors.to_date)}
//                 helperText={formik.touched.to_date && formik.errors.to_date}
//                 required
//               />
//             </Box>
//           </Stack>
//           <Stack direction="row" spacing={2}>
//             <Box sx={{ flex: 1 }}>
//               <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
//                 From Time
//               </Typography>
//               <TextField
//                 fullWidth
//                 label="From Time"
//                 type="time"
//                 name="from_time"
//                 value={formik.values.from_time}
//                 onChange={formik.handleChange}
//                 InputLabelProps={{ shrink: true }}
//                 error={formik.touched.from_time && Boolean(formik.errors.from_time)}
//                 helperText={formik.touched.from_time && formik.errors.from_time}
//                 required
//               />
//             </Box>
//             <Box sx={{ flex: 1 }}>
//               <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
//                 To Time
//               </Typography>
//               <TextField
//                 fullWidth
//                 label="To Time"
//                 type="time"
//                 name="to_time"
//                 value={formik.values.to_time}
//                 onChange={formik.handleChange}
//                 InputLabelProps={{ shrink: true }}
//                 error={formik.touched.to_time && Boolean(formik.errors.to_time)}
//                 helperText={formik.touched.to_time && formik.errors.to_time}
//                 required
//               />
//             </Box>
//           </Stack>
//           <Box display="flex" justifyContent="flex-end">
//             <Button variant="outlined" color="secondary" onClick={handleCancel} sx={{ ml: 2 }}>
//               Cancel
//             </Button>
//             <Button type="submit" variant="contained" color="primary" sx={{ ml: 2 }}>
//               Save
//             </Button>
//           </Box>
//         </Stack>
//       </Box>
//     </Container>
//   );
// };

// export default CreateWorkshopForm;