import React, { useEffect, useRef } from 'react';
import { Paper, Box, Typography } from '@mui/material';
import Chart from 'chart.js/auto';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const CostVsUseChart = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const handleResize = debounce(() => {
      if (chartInstance.current) {
        try {
          chartInstance.current.resize();
        } catch (error) {
          console.error("ResizeObserver error:", error);
        }
      }
    }, 100);

    const resizeObserver = new ResizeObserver(handleResize);

    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const maxX = Math.max(...data.map(d => d.x));
      const maxY = Math.max(...data.map(d => d.y));

      const colors = [
        'rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)', 'rgba(255, 206, 86, 0.6)',
        'rgba(75, 192, 192, 0.6)', 'rgba(153, 102, 255, 0.6)', 'rgba(255, 159, 64, 0.6)'
      ];

      const datasets = data.map((d, index) => ({
        label: d.name,
        data: [{ x: d.x, y: d.y }],
        backgroundColor: colors[index % colors.length],
        radius: 8, // Increase the size of the markers
      }));

      chartInstance.current = new Chart(chartRef.current, {
        type: 'scatter',
        data: {
          datasets: datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              type: 'linear',
              position: 'bottom',
              title: {
                display: true,
                text: 'Cost of Machine',
              },
              min: 0,
              max: Math.max(1, Math.ceil(maxX * 1.2)),
            },
            y: {
              title: {
                display: true,
                text: 'Usage (Number of Hours)',
              },
              min: 0,
              max: Math.max(1, Math.ceil(maxY * 1.2)),
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  return `${context.dataset.label}: (${context.raw.x}, ${context.raw.y})`;
                },
              },
            },
          },
        },
      });
    }
  }, [data]);

  return (
    <Paper elevation={3} style={{ padding: '8px', borderRadius: '16px', backgroundColor: '#f9fafe', border: '1px solid #d3d3d3', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <Typography variant="h6" style={{ fontSize: '24px', fontWeight: '700', color: '#000' }}>Cost v/s Use of machines</Typography>
        <Typography style={{ marginLeft: '8px', fontSize: '12px', color: '#7a7a7a', fontWeight: '500' }}>For the current month</Typography>
      </Box>
      {data.length > 0 ? (
        <Box style={{ width: '100%', height: '300px', position: 'relative' }}>
          <canvas ref={chartRef} style={{ width: '100%', height: '100%' }} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="300px" width="100%">
          <img src={nodatafoundImage} alt="No Data Found" style={{ width: '200px', marginBottom: '16px' }} />
          <Typography variant='h5' color="textSecondary">
            No data available
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default CostVsUseChart;
