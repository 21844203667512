import React, { useEffect, useState } from 'react';
import { Box, Grid, Container, Button } from '@mui/material';
import { CSVLink } from 'react-csv'; // Import CSVLink
import TinkerLabListAPIs from '../../utilities/api/TinkersLab/TinkerLabListAPIs';
import UserCard from './OrgDashboardGraphs/UserCard';
import ProjectsChart from './OrgDashboardGraphs/ProjectsChart';
import IssuesChart from './OrgDashboardGraphs/IssuesChart';
import CostVSIssueChart from './OrgDashboardGraphs/CostVSIssueChart';
import IssuesResolvedChart from './OrgDashboardGraphs/IssuesResolvedChart';
import MachinesUsedChart from './OrgDashboardGraphs/MachinesUsedChart';
import AdditionalStatsCard from './OrgDashboardGraphs/AdditionalStatsCard';
import BookingHoursChart from './OrgDashboardGraphs/BookingHoursChart';
import ActivityOfUsersChart from './OrgDashboardGraphs/ActivityOfUsersChart';
import MostIssuedItemsChart from './OrgDashboardGraphs/MostIssuedItemsChart';
import TLStatsTable from './OrgDashboardGraphs/TLStatsTable';

const OrgDashboard = () => {
  const [tlMappings, setTlMappings] = useState({});
  const [tlStats, setTlStats] = useState([]); // State to hold TLStats data
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await TinkerLabListAPIs.TinkerLabsListGet();
        const labelMapping = {};
        data.forEach(item => {
          if (item.college) {
            labelMapping[item.id] = item.name;
          }
        });
        setTlMappings(labelMapping);
      } catch (error) {
        console.error('Error fetching TL mappings:', error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    setIsLoading(tlStats.length === 0); // Set loading state to true if tlStats is empty
  }, [tlStats]);

  // CSV headers for the export
  const csvHeaders = [
    { label: 'Name of Item', key: 'name' },
    { label: 'No. of Users', key: 'totalUsers' },
    { label: 'Admin Users', key: 'totalAdminUsers' },
    { label: 'Inventory Issues', key: 'totalInventoryIssues' },
    { label: 'Inventory Purchases', key: 'totalPurchased' },
    { label: 'Machine Bookings (Hrs)', key: 'totalBooked' },
    { label: 'Inventory Items Available', key: 'totalAvailable' },
    { label: 'Issues Reported', key: 'totalIssues' },
    { label: 'Issues Solved', key: 'totalSolvedIssues' },
    { label: 'Projects Undergoing', key: 'totalProjects' },
  ];

  return (
    <Container sx={{ marginTop: '1rem' }}>
      <Box>
        {/* Export Button */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
          <CSVLink
            data={tlStats}
            headers={csvHeaders}
            filename="tl_stats.csv"
            style={{ textDecoration: 'none' }}
          >
            <Button variant="contained" color="primary" disabled={isLoading}>
              {isLoading ? 'Loading...' : 'Export to CSV'}
            </Button>
          </CSVLink>
        </Box>

        <UserCard tlMappings={tlMappings} />
        <Grid container spacing={2} sx={{ mb: '20px' }}>
          <Grid item xs={12} md={6}>
            <ProjectsChart tlMappings={tlMappings} />
          </Grid>
          <Grid item xs={12} md={6}>
            <IssuesChart tlMappings={tlMappings} />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: '20px' }}>
          <Grid item xs={12} md={12}>
            <CostVSIssueChart tlMappings={tlMappings} />
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ mb: '20px' }}>
          <Grid item xs={12} md={6}>
            <IssuesResolvedChart tlMappings={tlMappings} />
          </Grid>
          <Grid item xs={12} md={6} sx={{ ml: '-20px' }}>
            <MachinesUsedChart tlMappings={tlMappings} />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: '20px' }}>
          <Grid item xs={12}>
            <AdditionalStatsCard tlMappings={tlMappings} />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: '20px' }}>
          <Grid item xs={12} md={4}>
            <BookingHoursChart tlMappings={tlMappings} />
          </Grid>
          <Grid item xs={12} md={4}>
            <ActivityOfUsersChart tlMappings={tlMappings} />
          </Grid>
          <Grid item xs={12} md={4}>
            <MostIssuedItemsChart tlMappings={tlMappings} />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: '20px' }}>
          <Grid item xs={12}>
            {/* Pass callback to update TLStats in the parent */}
            <TLStatsTable tlMappings={tlMappings} onStatsLoad={setTlStats} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default OrgDashboard;
