// // SWITCHED TO STACK AS GRID WAS LEADING TO ALIGNMENT PROBLEMS


import React from 'react';
import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  Typography,
  Stack
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setEditMode, setHasReturned } from '../../../../redux/slices/editModeSlice'; // Adjust path as needed
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AdminWorkshopAPIs from '../../../../utilities/api/workshop/AdminWorkshopAPIs';

const EditWorkshopForm = () => {
  const dispatch = useDispatch();
  const editMode = useSelector((state) => state.editMode.editMode);
  const item = useSelector((state) => state.editMode.currentItem);
  const user = useSelector(state => state.user.user);
  const token = user.access;

  const yearOptions = [1, 2, 3, 4, 5];
  const branchOptions = [
    ['CSE', 'Computer Science and Engineering'],
    ['ECE', 'Electronics and Communication Engineering'],
    ['ME', 'Mechanical Engineering'],
    ['CE', 'Civil Engineering'],
    ['EE', 'Electrical Engineering'],
    ['CHE', 'Chemical Engineering'],
    ['MME', 'Metallurgical and Materials Engineering'],
    ['BT', 'Biotechnology'],
    ['CL', 'Chemistry'],
    ['MA', 'Mathematics'],
    ['PH', 'Physics'],
    ['HSS', 'Humanities and Social Sciences'],
    ['Other', 'Other']
  ];

  const initialValues = {
    title: item ? item.title : '',
    description: item ? item.description : '',
    eligible_years: item ? item.eligible_years.filter(year => year !== "") : [],
    eligible_branches: item ? item.eligible_branches.filter(branch => branch !== "") : [],
    from_date: item ? item.from_date : '',
    from_time: item ? item.from_time : '',
    to_date: item ? item.to_date : '',
    to_time: item ? item.to_time : '',
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Workshop Name is required'),
    description: Yup.string().required('Description is required'),
    eligible_years: Yup.array().min(1, 'At least one year must be selected'),
    eligible_branches: Yup.array().min(1, 'At least one branch must be selected'),
    from_date: Yup.date().required('From Date is required'),
    from_time: Yup.string().required('From Time is required'),
    to_date: Yup.date().required('To Date is required'),
    to_time: Yup.string().required('To Time is required'),
  });

  const handleSaveDialog = () => {
    dispatch(setEditMode(false)); // Dispatch action to set edit mode to false
    dispatch(setHasReturned(true)); // Dispatch action to set hasReturned to true
  };

  const handleCancelDialog = () => {
    dispatch(setEditMode(false)); // Dispatch action to set edit mode to false
    dispatch(setHasReturned(true)); // Dispatch action to set hasReturned to true
  };

  const isWorkshopCompleted = (to_date, to_time) => {
    const endDateTime = new Date(`${to_date}T${to_time}`);
    const currentDateTime = new Date();
    return currentDateTime > endDateTime;
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (isWorkshopCompleted(values.to_date, values.to_time)) {
        handleSaveDialog();
      } else {
        try {
          await AdminWorkshopAPIs.WorkshopDetailPatch(item.id, values, token);
          handleSaveDialog();
        } catch (error) {
          console.error('Failed to patch workshop:', error);
        }
      }
    },
  });

  const isCompleted = isWorkshopCompleted(item.to_date, item.to_time);

  const handleSelectAllYears = (checked) => {
    formik.setFieldValue('eligible_years', checked ? yearOptions : []);
  };

  const handleSelectAllBranches = (checked) => {
    formik.setFieldValue('eligible_branches', checked ? branchOptions.map(branch => branch[0]) : []);
  };

  const isAllYearsSelected = formik.values.eligible_years.length === yearOptions.length;
  const isAllBranchesSelected = formik.values.eligible_branches.length === branchOptions.length;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ mt: 1 }}>
        <Stack spacing={2}>
          <Box>
            <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
              Workshop Name
            </Typography>
            <TextField
              fullWidth
              label="Workshop Name"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              required
              disabled={isCompleted}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
          </Box>
          <Box>
            <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
              Description
            </Typography>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              multiline
              rows={4}
              required
              disabled={isCompleted}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
          </Box>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
                Year
              </Typography>
              <FormControl fullWidth sx={{ minWidth: 120 }}>
                <Select
                  multiple
                  value={formik.values.eligible_years}
                  name="eligible_years"
                  onChange={(event) => {
                    const value = event.target.value;
                    if (value.includes('all')) {
                      handleSelectAllYears(!isAllYearsSelected);
                    } else {
                      formik.setFieldValue("eligible_years", typeof value === 'string' ? value.split(',') : value);
                    }
                  }}
                  disabled={isCompleted}
                  error={formik.touched.eligible_years && Boolean(formik.errors.eligible_years)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={isAllYearsSelected}
                      indeterminate={formik.values.eligible_years.length > 0 && formik.values.eligible_years.length < yearOptions.length}
                    />
                    <ListItemText primary="All" />
                  </MenuItem>
                  {yearOptions.map((year) => (
                    <MenuItem key={year} value={year}>
                      <Checkbox checked={formik.values.eligible_years.includes(year)} />
                      <ListItemText primary={year} />
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.eligible_years && formik.errors.eligible_years && (
                  <Typography color="error">{formik.errors.eligible_years}</Typography>
                )}
              </FormControl>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
                Branch
              </Typography>
              <FormControl fullWidth sx={{ minWidth: 120 }}>
                <Select
                  multiple
                  value={formik.values.eligible_branches}
                  name="eligible_branches"
                  onChange={(event) => {
                    const value = event.target.value;
                    if (value.includes('all')) {
                      handleSelectAllBranches(!isAllBranchesSelected);
                    } else {
                      formik.setFieldValue("eligible_branches", typeof value === 'string' ? value.split(',') : value);
                    }
                  }}
                  disabled={isCompleted}
                  error={formik.touched.eligible_branches && Boolean(formik.errors.eligible_branches)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={isAllBranchesSelected}
                      indeterminate={formik.values.eligible_branches.length > 0 && formik.values.eligible_branches.length < branchOptions.length}
                    />
                    <ListItemText primary="All" />
                  </MenuItem>
                  {branchOptions.map((branch) => (
                    <MenuItem key={branch[0]} value={branch[0]}>
                      <Checkbox checked={formik.values.eligible_branches.includes(branch[0])} />
                      <ListItemText primary={branch[1]} />
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.eligible_branches && formik.errors.eligible_branches && (
                  <Typography color="error">{formik.errors.eligible_branches}</Typography>
                )}
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
                From Date
              </Typography>
              <TextField
                fullWidth
                label="From Date"
                type="date"
                name="from_date"
                value={formik.values.from_date}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                required
                disabled={isCompleted}
                error={formik.touched.from_date && Boolean(formik.errors.from_date)}
                helperText={formik.touched.from_date && formik.errors.from_date}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
                To Date
              </Typography>
              <TextField
                fullWidth
                label="To Date"
                type="date"
                name="to_date"
                value={formik.values.to_date}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                required
                disabled={isCompleted}
                error={formik.touched.to_date && Boolean(formik.errors.to_date)}
                helperText={formik.touched.to_date && formik.errors.to_date}
              />
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
                From Time
              </Typography>
              <TextField
                fullWidth
                label="From Time"
                type="time"
                name="from_time"
                value={formik.values.from_time}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                required
                disabled={isCompleted}
                error={formik.touched.from_time && Boolean(formik.errors.from_time)}
                helperText={formik.touched.from_time && formik.errors.from_time}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
                To Time
              </Typography>
              <TextField
                fullWidth
                label="To Time"
                type="time"
                name="to_time"
                value={formik.values.to_time}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                required
                disabled={isCompleted}
                error={formik.touched.to_time && Boolean(formik.errors.to_time)}
                helperText={formik.touched.to_time && formik.errors.to_time}
              />
            </Box>
          </Stack>
          <Box display="flex" justifyContent="flex-end">
            <Button type="button" variant="contained" color="primary" onClick={handleCancelDialog} disabled={isCompleted}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" sx={{ ml: 2 }}>
              Save
            </Button>
          </Box>
        </Stack>
      </Box>
    </form>
  );
};

export default EditWorkshopForm;



// // SWITCHED TO STACKAS GRID WAL LEADING TO ALIGNMENT PROBLEMS

// import React from 'react';
// import {
//   TextField,
//   Button,
//   FormControl,
//   Select,
//   MenuItem,
//   Box,
//   Typography,
//   Stack
// } from '@mui/material';
// import { useSelector, useDispatch } from 'react-redux';
// import { setEditMode, setHasReturned } from '../../../../redux/slices/editModeSlice'; // Adjust path as needed
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import AdminWorkshopAPIs from '../../../../utilities/api/workshop/AdminWorkshopAPIs';

// const EditWorkshopForm = () => {
//   const dispatch = useDispatch();
//   const editMode = useSelector((state) => state.editMode.editMode);
//   const item = useSelector((state) => state.editMode.currentItem);
//   const user = useSelector(state => state.user.user);
//   const token = user.access;

//   const yearOptions = [1, 2, 3, 4, 5];
//   const branchOptions = [
//     ['CSE', 'Computer Science and Engineering'],
//     ['ECE', 'Electronics and Communication Engineering'],
//     ['ME', 'Mechanical Engineering'],
//     ['CE', 'Civil Engineering'],
//     ['EE', 'Electrical Engineering'],
//     ['CHE', 'Chemical Engineering'],
//     ['MME', 'Metallurgical and Materials Engineering'],
//     ['BT', 'Biotechnology'],
//     ['CL', 'Chemistry'],
//     ['MA', 'Mathematics'],
//     ['PH', 'Physics'],
//     ['HSS', 'Humanities and Social Sciences'],
//     ['Other', 'Other']
//   ];

//   const initialValues = {
//     title: item ? item.title : '',
//     description: item ? item.description : '',
//     eligible_years: item ? item.eligible_years.filter(year => year !== "") : [],
//     eligible_branches: item ? item.eligible_branches.filter(branch => branch !== "") : [],
//     from_date: item ? item.from_date : '',
//     from_time: item ? item.from_time : '',
//     to_date: item ? item.to_date : '',
//     to_time: item ? item.to_time : '',
//   };

//   const validationSchema = Yup.object().shape({
//     title: Yup.string().required('Workshop Name is required'),
//     description: Yup.string().required('Description is required'),
//     eligible_years: Yup.array().min(1, 'At least one year must be selected'),
//     eligible_branches: Yup.array().min(1, 'At least one branch must be selected'),
//     from_date: Yup.date().required('From Date is required'),
//     from_time: Yup.string().required('From Time is required'),
//     to_date: Yup.date().required('To Date is required'),
//     to_time: Yup.string().required('To Time is required'),
//   });

//   const handleSaveDialog = () => {
//     dispatch(setEditMode(false)); // Dispatch action to set edit mode to false
//     dispatch(setHasReturned(true)); // Dispatch action to set hasReturned to true
//   };

//   const handleCancelDialog = () => {
//     dispatch(setEditMode(false)); // Dispatch action to set edit mode to false
//     dispatch(setHasReturned(true)); // Dispatch action to set hasReturned to true
//   };

//   const isWorkshopCompleted = (to_date, to_time) => {
//     const endDateTime = new Date(`${to_date}T${to_time}`);
//     const currentDateTime = new Date();
//     return currentDateTime > endDateTime;
//   };

//   const formik = useFormik({
//     initialValues,
//     validationSchema,
//     onSubmit: async (values) => {
//       if (isWorkshopCompleted(values.to_date, values.to_time)) {
//         handleSaveDialog();
//       } else {
//         try {
//           await AdminWorkshopAPIs.WorkshopDetailPatch(item.id, values, token);
//           handleSaveDialog();
//         } catch (error) {
//           console.error('Failed to patch workshop:', error);
//         }
//       }
//     },
//   });

//   const isCompleted = isWorkshopCompleted(item.to_date, item.to_time);

//   return (
//     <form onSubmit={formik.handleSubmit}>
//       <Box sx={{ mt: 1 }}>
//         <Stack spacing={2}>
//           <Box>
//             <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
//               Workshop Name
//             </Typography>
//             <TextField
//               fullWidth
//               label="Workshop Name"
//               name="title"
//               value={formik.values.title}
//               onChange={formik.handleChange}
//               required
//               disabled={isCompleted}
//               error={formik.touched.title && Boolean(formik.errors.title)}
//               helperText={formik.touched.title && formik.errors.title}
//             />
//           </Box>
//           <Box>
//             <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
//               Description
//             </Typography>
//             <TextField
//               fullWidth
//               label="Description"
//               name="description"
//               value={formik.values.description}
//               onChange={formik.handleChange}
//               multiline
//               rows={4}
//               required
//               disabled={isCompleted}
//               error={formik.touched.description && Boolean(formik.errors.description)}
//               helperText={formik.touched.description && formik.errors.description}
//             />
//           </Box>
//           <Stack direction="row" spacing={2}>
//             <Box sx={{ flex: 1 }}>
//               <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
//                 Year
//               </Typography>
//               <FormControl fullWidth sx={{ minWidth: 120 }}>
//                 <Select
//                   multiple
//                   value={formik.values.eligible_years}
//                   name="eligible_years"
//                   onChange={(event) => {
//                     formik.setFieldValue("eligible_years", event.target.value);
//                   }}
//                   disabled={isCompleted}
//                   error={formik.touched.eligible_years && Boolean(formik.errors.eligible_years)}
//                   renderValue={(selected) => selected.join(', ')}
//                 >
//                   {yearOptions.map((year) => (
//                     <MenuItem key={year} value={year}>
//                       {year}
//                     </MenuItem>
//                   ))}
//                 </Select>
//                 {formik.touched.eligible_years && formik.errors.eligible_years && (
//                   <Typography color="error">{formik.errors.eligible_years}</Typography>
//                 )}
//               </FormControl>
//             </Box>
//             <Box sx={{ flex: 1 }}>
//               <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
//                 Branch
//               </Typography>
//               <FormControl fullWidth sx={{ minWidth: 120 }}>
//                 <Select
//                   multiple
//                   value={formik.values.eligible_branches}
//                   name="eligible_branches"
//                   onChange={(event) => {
//                     formik.setFieldValue("eligible_branches", event.target.value);
//                   }}
//                   disabled={isCompleted}
//                   error={formik.touched.eligible_branches && Boolean(formik.errors.eligible_branches)}
//                   renderValue={(selected) => selected.join(', ')}
//                 >
//                   {branchOptions.map((branch) => (
//                     <MenuItem key={branch[0]} value={branch[0]}>
//                       {branch[1]}
//                     </MenuItem>
//                   ))}
//                 </Select>
//                 {formik.touched.eligible_branches && formik.errors.eligible_branches && (
//                   <Typography color="error">{formik.errors.eligible_branches}</Typography>
//                 )}
//               </FormControl>
//             </Box>
//           </Stack>
//           <Stack direction="row" spacing={2}>
//             <Box sx={{ flex: 1 }}>
//               <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
//                 From Date
//               </Typography>
//               <TextField
//                 fullWidth
//                 label="From Date"
//                 type="date"
//                 name="from_date"
//                 value={formik.values.from_date}
//                 onChange={formik.handleChange}
//                 InputLabelProps={{ shrink: true }}
//                 required
//                 disabled={isCompleted}
//                 error={formik.touched.from_date && Boolean(formik.errors.from_date)}
//                 helperText={formik.touched.from_date && formik.errors.from_date}
//               />
//             </Box>
//             <Box sx={{ flex: 1 }}>
//               <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
//                 To Date
//               </Typography>
//               <TextField
//                 fullWidth
//                 label="To Date"
//                 type="date"
//                 name="to_date"
//                 value={formik.values.to_date}
//                 onChange={formik.handleChange}
//                 InputLabelProps={{ shrink: true }}
//                 required
//                 disabled={isCompleted}
//                 error={formik.touched.to_date && Boolean(formik.errors.to_date)}
//                 helperText={formik.touched.to_date && formik.errors.to_date}
//               />
//             </Box>
//           </Stack>
//           <Stack direction="row" spacing={2}>
//             <Box sx={{ flex: 1 }}>
//               <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
//                 From Time
//               </Typography>
//               <TextField
//                 fullWidth
//                 label="From Time"
//                 type="time"
//                 name="from_time"
//                 value={formik.values.from_time}
//                 onChange={formik.handleChange}
//                 InputLabelProps={{ shrink: true }}
//                 required
//                 disabled={isCompleted}
//                 error={formik.touched.from_time && Boolean(formik.errors.from_time)}
//                 helperText={formik.touched.from_time && formik.errors.from_time}
//               />
//             </Box>
//             <Box sx={{ flex: 1 }}>
//               <Typography sx={{ paddingBottom: '8px', fontWeight: '500' }}>
//                 To Time
//               </Typography>
//               <TextField
//                 fullWidth
//                 label="To Time"
//                 type="time"
//                 name="to_time"
//                 value={formik.values.to_time}
//                 onChange={formik.handleChange}
//                 InputLabelProps={{ shrink: true }}
//                 required
//                 disabled={isCompleted}
//                 error={formik.touched.to_time && Boolean(formik.errors.to_time)}
//                 helperText={formik.touched.to_time && formik.errors.to_time}
//               />
//             </Box>
//           </Stack>
//           <Box display="flex" justifyContent="flex-end">
//             <Button type="button" variant="contained" color="primary" onClick={handleCancelDialog} disabled={isCompleted}>
//               Cancel
//             </Button>
//             <Button type="submit" variant="contained" color="primary" sx={{ ml: 2 }}>
//               Save
//             </Button>
//           </Box>
//         </Stack>
//       </Box>
//     </form>
//   );
// };

// export default EditWorkshopForm;