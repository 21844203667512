import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, Grid } from '@mui/material';
import { axiosApiService } from '../../../utilities/api/axios';

const StatisticCard = ({ value, label, description, bgColor, borderColor }) => (
  <Card sx={{ ml:'-16px', width:'90%', backgroundColor: bgColor, borderColor: borderColor, borderWidth: '1px', borderStyle: 'solid', borderRadius: '12px', textAlign: 'center', padding: '16px', boxShadow: 'none' }}>
    <CardContent>
      <Typography variant="h4" sx={{ fontSize: 24, fontWeight: 700, color: '#212121' }}>{value}</Typography>
      <Typography variant="body1" sx={{ fontSize: 14, fontWeight: 400, color: '#5c5c5c', marginTop: '4px' }}>{label}</Typography>
      <Typography variant="caption" sx={{ fontSize: 12, color: '#7a7a7a', marginTop: '4px' }}>{description}</Typography>
    </CardContent>
  </Card>
);

const AdditionalStatsCard = ({ tlMappings }) => {
  const [inventoryPurchased, setInventoryPurchased] = useState(0);
  const [machinesBooked, setMachinesBooked] = useState(0);
  const [uniqueItemsAvailable, setUniqueItemsAvailable] = useState(0);
  const [previousInventoryPurchased, setPreviousInventoryPurchased] = useState(0);
  const [previousMachinesBooked, setPreviousMachinesBooked] = useState(0);
  const [previousUniqueItemsAvailable, setPreviousUniqueItemsAvailable] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        const tlData = await Promise.all(
          Object.entries(tlMappings).map(async ([tlId, tlName]) => {
            const [purchaseResponse, reservationResponse, inventoryResponse] = await Promise.all([
              axiosApiService.get('/inventory_purchase_list/', {
                headers: {
                  'TL': tlName,
                },
              }),
              axiosApiService.get('/machine_reservation_list/', {
                headers: {
                  'TL': tlName,
                },
              }),
              axiosApiService.get('/admin_inventory_list/', {
                headers: {
                  'TL': tlName,
                },
              })
            ]);

            const totalPurchased = purchaseResponse.data.length;
            const totalBooked = reservationResponse.data.length;
            const uniqueAvailable = inventoryResponse.data.filter(item => item.stock_available > 0).length;

            return {
              totalPurchased,
              totalBooked,
              uniqueAvailable
            };
          })
        );

        const totalPurchased = tlData.reduce((acc, curr) => acc + curr.totalPurchased, 0);
        const totalBooked = tlData.reduce((acc, curr) => acc + curr.totalBooked, 0);
        const totalUniqueAvailable = tlData.reduce((acc, curr) => acc + curr.uniqueAvailable, 0);

        setInventoryPurchased(totalPurchased);
        setMachinesBooked(totalBooked);
        setUniqueItemsAvailable(totalUniqueAvailable);

        // Fetch previous period data similarly
        const previousTlData = await Promise.all(
          Object.entries(tlMappings).map(async ([tlId, tlName]) => {
            const [previousPurchaseResponse, previousReservationResponse, previousInventoryResponse] = await Promise.all([
              axiosApiService.get('/inventory_purchase_list/?period=previous', {
                headers: {
                  'TL': tlName,
                },
              }),
              axiosApiService.get('/machine_reservation_list/?period=previous', {
                headers: {
                  'TL': tlName,
                },
              }),
              axiosApiService.get('/admin_inventory_list/?period=previous', {
                headers: {
                  'TL': tlName,
                },
              })
            ]);

            const previousTotalPurchased = previousPurchaseResponse.data.length;
            const previousTotalBooked = previousReservationResponse.data.length;
            const previousUniqueAvailable = previousInventoryResponse.data.filter(item => item.stock_available > 0).length;

            return {
              previousTotalPurchased,
              previousTotalBooked,
              previousUniqueAvailable
            };
          })
        );

        const previousTotalPurchased = previousTlData.reduce((acc, curr) => acc + curr.previousTotalPurchased, 0);
        const previousTotalBooked = previousTlData.reduce((acc, curr) => acc + curr.previousTotalBooked, 0);
        const previousTotalUniqueAvailable = previousTlData.reduce((acc, curr) => acc + curr.previousUniqueAvailable, 0);

        setPreviousInventoryPurchased(previousTotalPurchased);
        setPreviousMachinesBooked(previousTotalBooked);
        setPreviousUniqueItemsAvailable(previousTotalUniqueAvailable);
      } catch (error) {
        console.error('Error fetching additional stats data:', error);
      }
    }

    fetchData();
  }, [tlMappings]);

  const calculatePercentageChange = (current, previous) => {
    if (previous === 0) return 'N/A';
    const change = ((current - previous) / previous) * 100;
    return `${change.toFixed(2)}% ${change > 0 ? 'increase' : 'decrease'} from last period`;
  };

  return (
    <Card sx={{ boxShadow: 'none' ,borderRadius: '24px', padding: '16px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CardContent sx={{width:'100%'}}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <StatisticCard
              value={inventoryPurchased}
              label="Inventory Purchased"
              description={calculatePercentageChange(inventoryPurchased, previousInventoryPurchased)}
              bgColor="#ffe6e6"
              borderColor="#f9c1c1"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <StatisticCard
              value={machinesBooked}
              label="Machines Booked"
              description={calculatePercentageChange(machinesBooked, previousMachinesBooked)}
              bgColor="#fff5e6"
              borderColor="#fdd5b3"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <StatisticCard
              value={uniqueItemsAvailable}
              label="Inventory Items Available"
              description={calculatePercentageChange(uniqueItemsAvailable, previousUniqueItemsAvailable)}
              bgColor="#e6f2ff"
              borderColor="#b3d8ff"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AdditionalStatsCard;
