import React from "react";
import { Tabs, Tab, Box, TextField, useMediaQuery, useTheme, InputAdornment, Button } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import { useDispatch } from "react-redux";
import { setIsNewTut } from '../../../redux/slices/tutorialEditSlice'; // Correct path

const CustomTab = ({ icon }) => (
  <Box sx={{ margin: '5px 0px' }}>
    {icon}
  </Box>
);

const TopBarComponent = ({ setSearch, activeView, setActiveView }) => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleViewChange = (event, newValue) => {
    setActiveView(newValue);
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: isMobile ? 1 : 0,
      width: '100%',
      marginBottom: '0.5rem',
      boxSizing: 'border-box',
    }}>
      <TextField
        fullWidth
        className='searchBar'
        placeholder="Search Tutorials.."
        id="outlined-start-adornment"
        sx={{ 
          width: '25ch', 
          border: '1px solid rgb(39,63,119)', 
          color: 'rgb(39,63,119)', 
          borderRadius: '10px'
        }}
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          style: {
            height: '2.5rem',
          },
          startAdornment: <InputAdornment position="start" sx={{ border: 'none' }}><SearchIcon /></InputAdornment>,
        }}
      />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button 
          variant="contained" 
          onClick={() => {
            console.log('Add Module button Clicked');
            dispatch(setIsNewTut(true));
          }}
          sx={{ 
            marginLeft: '10px', 
            height: '2rem', 
            backgroundColor: 'rgb(39,63,119)', 
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgb(29,53,109)',
            }
          }}
        >
          Add Module
        </Button>
        <Tabs
          indicatorColor="none"
          orientation="horizontal"
          value={activeView}
          onChange={handleViewChange}
          sx={{
            '& .MuiTab-root': {
              padding: '0px',
              marginInline: '2px',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              textTransform: 'none',
              minWidth: '0px',
              fontSize: '2rem',
            },
            '& .MuiTabs-flexContainer': {
              flexDirection: 'row',
            },
          }}
        >
          <Tab
            label={
              <CustomTab
                icon={
                  <GridViewOutlinedIcon
                    sx={{
                      marginLeft: '20px',
                      color: activeView === 0 ? 'white' : 'rgb(39,63,119)',
                      background: activeView === 0 ? 'rgb(39,63,119)' : 'rgba(122,122,122,0.4)',
                      padding: '3px',
                      borderRadius: '8px',
                    }}
                  />
                }
              />
            }
            value={0}
          />
          <Tab
            label={
              <CustomTab
                icon={
                  <ViewListOutlinedIcon
                    sx={{
                      color: activeView === 0 ? 'rgb(39,63,119)' : 'white',
                      background: activeView === 0 ? 'rgba(122,122,122,0.4)' : 'rgb(39,63,119)',
                      padding: '3px',
                      borderRadius: '8px',
                    }}
                  />
                }
              />
            }
            value={1}
          />
        </Tabs>
      </Box>
    </Box>
  );
};

export default TopBarComponent;
