import React from 'react';
import { Box, Typography } from '@mui/material';

const ChatMessage = ({ message, time, isCurrentUser }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: isCurrentUser ? 'flex-end' : 'flex-start',
                marginBottom: '10px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: isCurrentUser ? '#dcf8c6' : '#fff',
                    borderRadius: '10px',
                    padding: '10px',
                    maxWidth: '60%',
                    boxShadow: '0px 1px 2px rgba(0,0,0,0.1)',
                }}
            >
                <Typography variant="body1">{message}</Typography>
            </Box>
            <Typography variant="caption" color="textSecondary" sx={{ marginTop: '2px' }}>
                {time}
            </Typography>
        </Box>
    );
};

export default ChatMessage;
