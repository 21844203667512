import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, CircularProgress, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import IconButton from '@mui/material/IconButton';
import nodatafound from '../../utilities/dummy_assets/nodatafound.jpg'; // Adjust path as needed
import MachineListAPIs from '../../utilities/api/machine/AdminMachineList';
import MachineDetailAPIs from '../../utilities/api/machine/AdminMachineDetail';
import { useSelector } from 'react-redux';
import EditMachineDialog from './Popups/EditMachine';
import { ImageBaseURL } from "../../utilities/api/axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for toast notifications
import DatePickerDialog from './Popups/DatePicker';
import Delete from './Popups/Delete';

const MachineTable = ({ searchQuery, category, update, setUpdate, flag, setFlag }) => {
    const user = useSelector(state => state.user.user);
    const token = user.access;

    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true); // New loading state
    const [page, setPage] = useState(0);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const [isEditInventoryOpen, setEditInventoryOpen] = useState(false);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        setLoading(true); // Set loading to true when fetching data
        const response = MachineListAPIs.MachineListGet();
        response.then((result) => {
            setTableData(result);
        }).catch((error) => {
            console.error("Error fetching data:", error);
            toast.error("Error fetching data");
        }).finally(() => {
            setLoading(false); // Set loading to false once data is fetched
        });
    }, [update, user]);

    const rows = tableData.map((item, index) => ({ ...item, index: index }));

    const handleEditInventory = (row) => {
        setCurrentRow(row);
        setEditInventoryOpen(true);
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleCloseDatePicker = () => {
        setIsDatePickerOpen(false);
    };

    const handleDateChange = (newDate) => {
        setDate(newDate);
    };

    const handleOpenDeletePopup = (row) => {
        setCurrentRow(row);
        setOpenDeletePopup(true);
    };

    const handleCloseDeletePopup = () => {
        setOpenDeletePopup(false);
    };

    const handleDelete = () => {
        MachineDetailAPIs.MachineDetailDelete(currentRow.id, token).then(() => {
            setUpdate(prev => !prev);
            toast.success("Item successfully deleted");
        }).catch((error) => {
            console.error("Error deleting data:", error);
            toast.error("Error deleting item");
        });
        handleCloseDeletePopup();
    };

    const filteredRows = rows.filter((row) => {
        const lowercasedCategory = category.map(cat => cat.toLowerCase());
        const matchesCategory = !lowercasedCategory.length || lowercasedCategory.includes(row.category?.toLowerCase());
        const matchesSearchQuery = !searchQuery ||
            (row.name?.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (row.group?.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (row.description?.toLowerCase().includes(searchQuery.toLowerCase())) ||
            [row.tag_1, row.tag_2, row.tag_3, row.tag_4].some(tag => tag?.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (row.availability?.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (row.category?.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (row.upc?.includes(searchQuery.toLowerCase())) ||
            (row.instances?.toString().includes(searchQuery.toLowerCase()));
        return matchesCategory && matchesSearchQuery;
    });

    const columns = [
        {
            field: 'itemDetails',
            headerName: 'Item Details',
            flex: 1, 
            headerAlign: 'center',
            headerClassName: 'header-style',
            renderCell: (params) => {
                const imageUrl = params.row?.image ? `${ImageBaseURL}${params.row.image}` : nodatafound;
                const text = params.row?.name || 'No Text';
                const subtext = params.row?.upc || 'No Subtext';

                return (
                    <Box sx={{ ml: '10%', display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'center' }}>
                        <img src={imageUrl} alt={text} style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                        <Box>
                            <Typography variant="subtitle2" sx={{ color: '#434343' }}>{text}</Typography>
                            <Typography variant="body2" sx={{ color: '#434343' }}>{subtext}</Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'category',
            headerName: 'Category',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'header-style',
            renderCell: (params) => (
                <Typography sx={{ color: '#434343', textAlign: 'center', width: '100%' }}>
                    {params.row.category}
                </Typography>
            )
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'header-style',
            renderCell: (params) => (
                <Typography sx={{ color: '#434343', textAlign: 'center', width: '100%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {params.row.description}
                </Typography>
            )
        },
        {
            field: 'availability',
            headerName: 'Availability',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'header-style',
            renderCell: (params) => (
                <Typography sx={{ color: '#434343', textAlign: 'center', width: '100%' }}>
                    {params.row.availability}
                </Typography>
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            width: 150,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'header-style',
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <IconButton aria-label="edit" onClick={() => handleEditInventory(params.row)}>
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleOpenDeletePopup(params.row)}>
                        <DeleteOutlineSharpIcon fontSize="small" sx={{ color: '#C90505' }} />
                    </IconButton>
                </Box>
            ),
        },
    ];

    return (
        <Box sx={{ height: '60%', width: '97.5%', marginLeft: '1rem' }}>
            {loading ? (
                <Box sx={{ display: 'flex', marginTop: '100px', padding: '32px 24px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '21px' }}>
                    {/* <Box component="img" src={nodatafound} sx={{ width: 211.889, height: 196.824, flexShrink: 0 }} /> */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                    <Typography sx={{ color: '#273F77', textAlign: 'center', fontFamily: 'Inter', fontSize: 26, fontWeight: 500, lineHeight: '150%', letterSpacing: '-0.352px' }}>
                        Loading...
                    </Typography>
                </Box>
            ) : filteredRows.length === 0 ? (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
                    <img src={nodatafound} alt="No Data Found" style={{ width: '350px', marginBottom: '8px' }} />
                    <Typography variant="body2" color="textSecondary">
                        No data available
                    </Typography>
                </Box>
            ) : (
                <DataGrid
                    rows={filteredRows}
                    columns={columns}
                    pageSize={7}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPageOptions={[3]}
                    pagination
                    paginationMode="server"
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnMenu
                    disableDensitySelector
                    disableExport
                    disableMultipleColumnsSorting
                    sortingOrder={[]}
                    disableColumnSort
                    sx={{
                        '& .header-style': {
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                        },
                    }}
                />
            )}
            <Delete open={openDeletePopup} onClose={handleCloseDeletePopup} onConfirm={handleDelete} />
            <EditMachineDialog currentRow={currentRow} open={isEditInventoryOpen} onClose={() => setEditInventoryOpen(false)} update={update} setUpdate={setUpdate} flag={flag} setFlag={setFlag} />
            <DatePickerDialog
                open={isDatePickerOpen}
                selectedDate={date}
                handleDateChange={handleDateChange}
                handleClose={handleCloseDatePicker}
            />
            <ToastContainer /> {/* Add ToastContainer here */}
        </Box>
    );
};

export default MachineTable;
