import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardMedia, CardContent, Typography, Box, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { setTutEditMode, setTutCurrentItem } from '../../../redux/slices/tutorialEditSlice'; // Correct path
import {ImageBaseURL} from "../../../utilities/api/axios";

const styles = {
  card: {
    position: 'relative',
  },
  mediaContainer: {
    position: 'relative',
  },
  cardMedia: {
    width: '100%',
  },
  textContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: '50%',
    background: 'linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0))',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    padding: '10px',
    boxSizing: 'border-box',
  },
  textVisible: {
    opacity: 1,
  },
  description: {},
  mentor: {},
};

const GridTutorialCard = ({ item }) => {
  const dispatch = useDispatch();

  // const ImageBaseURL = 'https://staging.misadmin.mapit.ai/';

  return (
    <Card sx={styles.card}>
      <Box
        sx={styles.mediaContainer}
        onMouseEnter={(e) => {
          e.currentTarget.querySelector('.textContainer').style.opacity = 1;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.querySelector('.textContainer').style.opacity = 0;
        }}
      >
        <CardMedia
          component="img"
          height="200"
          image={`${ImageBaseURL}${item.image}`}
          alt={item.name}
          sx={styles.cardMedia}
        />
        <Box className="textContainer" sx={styles.textContainer}>
          {/* <Typography variant="body2" color="inherit" sx={styles.description}>
            {item.name}
          </Typography> */}
          <Typography variant="body2" color="inherit" sx={styles.description}>
            {item.description}
          </Typography>
          <Typography variant="subtitle2" color="inherit" sx={styles.mentor}>
            Mentor: {item.mentor}
          </Typography>
        </Box>
        <Button
          variant="contained"
          sx={{
            position: 'absolute',
            minWidth: '0px',
            top: 8,
            right: 0,
            color: 'white',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
          }}
          startIcon={<EditIcon />}
          onClick={() => {
            console.log('Edit button clicked'); // Add console log here
            dispatch(setTutEditMode(true));
            dispatch(setTutCurrentItem(item)); // Set tutCurrentItem to item
          }}
        >
          Edit
        </Button>
      </Box>
      <CardContent>
        <Typography variant="h6" component="div">
          {item.name}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default GridTutorialCard;


// import React from 'react';
// import { useDispatch } from 'react-redux';
// import { Card, CardMedia, CardContent, Typography, Box, Button } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
// import { setTutEditMode } from '../../../redux/slices/tutorialEditSlice'; // Correct path

// const styles = {
//   card: {
//     position: 'relative',
//   },
//   mediaContainer: {
//     position: 'relative',
//   },
//   cardMedia: {
//     width: '100%',
//   },
//   textContainer: {
//     position: 'absolute',
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: '50%',
//     background: 'linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0))',
//     color: 'white',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'flex-end',
//     alignItems: 'flex-start',
//     opacity: 0,
//     transition: 'opacity 0.3s ease-in-out',
//     padding: '10px',
//     boxSizing: 'border-box',
//   },
//   textVisible: {
//     opacity: 1,
//   },
//   description: {},
//   mentor: {},
// };

// const GridTutorialCard = ({ item }) => {
//   const dispatch = useDispatch();

//   return (
//     <Card sx={styles.card}>
//       <Box
//         sx={styles.mediaContainer}
//         onMouseEnter={(e) => {
//           e.currentTarget.querySelector('.textContainer').style.opacity = 1;
//         }}
//         onMouseLeave={(e) => {
//           e.currentTarget.querySelector('.textContainer').style.opacity = 0;
//         }}
//       >
//         <CardMedia
//           component="img"
//           height="200"
//           image={item.image}
//           alt={item.topic}
//           sx={styles.cardMedia}
//         />
//         <Box className="textContainer" sx={styles.textContainer}>
//           <Typography variant="body2" color="inherit" sx={styles.description}>
//             {item.description}
//           </Typography>
//           <Typography variant="subtitle2" color="inherit" sx={styles.mentor}>
//             Mentor: {item.mentor}
//           </Typography>
//         </Box>
//         <Button
//           variant="contained"
//           sx={{
//             position: 'absolute',
//             minWidth: '0px',
//             top: 8,
//             right: 0,
//             color: 'white',
//             backgroundColor: 'transparent',
//             boxShadow: 'none',
//             '&:hover': {
//               backgroundColor: 'rgba(0, 0, 0, 0.1)',
//             },
//           }}
//           startIcon={<EditIcon />}
//           onClick={() => {
//             console.log('Edit button clicked'); // Add console log here
//             dispatch(setTutEditMode(true));
//           }}
//         >
//           Edit
//         </Button>
//       </Box>
//       <CardContent>
//         <Typography variant="h6" component="div">
//           {item.topic}
//         </Typography>
//       </CardContent>
//     </Card>
//   );
// };

// export default GridTutorialCard;