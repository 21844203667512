import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { axiosApiService } from '../../../utilities/api/axios';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const CostVSIssueChart = ({ tlMappings }) => {
  const chartRef = useRef(null);
  const bubbleChartRef = useRef(null);
  const [bubbleData, setBubbleData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const tlData = await Promise.all(
          Object.entries(tlMappings).map(async ([tlId, tlName]) => {
            const [usageResponse, costResponse] = await Promise.all([
              axiosApiService.get('/machine_reservation_list/', {
                headers: {
                  'TL': tlName,
                },
              }),
              axiosApiService.get('/admin_machine_list/', {
                headers: {
                  'TL': tlName,
                },
              }),
            ]);

            const totalHours = usageResponse.data.reduce((acc, curr) => acc + curr.duration, 0);
            const totalCost = costResponse.data.reduce((acc, curr) => acc + parseFloat(curr.purchase_cost), 0);

            return {
              tlId,
              tlName,
              totalHours,
              totalCost,
              backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.6)`
            };
          })
        );

        const totalHoursAllTLs = tlData.reduce((acc, curr) => acc + curr.totalHours, 0);

        const combinedData = tlData.map(tl => ({
          label: tl.tlName,
          x: tl.totalCost,
          y: tl.totalHours,
          r: (tl.totalHours / totalHoursAllTLs) * 100, // Dynamic radius
          backgroundColor: tl.backgroundColor
        }));

        setBubbleData(combinedData);
      } catch (error) {
        console.error('Error fetching bubble chart data:', error);
      }
    }

    fetchData();
  }, [tlMappings]);

  useEffect(() => {
    if (bubbleChartRef.current) bubbleChartRef.current.destroy();

    const hasData = bubbleData.length > 0 && bubbleData.some(item => item.x > 0 || item.y > 0);

    if (hasData) {
      const maxCost = Math.max(...bubbleData.map(data => data.x)) * 1.5;
      const maxHours = Math.max(...bubbleData.map(data => data.y)) * 1.5;

      const bubbleCtx = chartRef.current.getContext('2d');

      bubbleChartRef.current = new Chart(bubbleCtx, {
        type: 'bubble',
        data: {
          datasets: bubbleData.map(item => ({
            label: item.label,
            data: [{
              x: item.x,
              y: item.y,
              r: item.r
            }],
            backgroundColor: item.backgroundColor
          })),
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              beginAtZero: true,
              max: maxCost,
              title: {
                display: true,
                text: 'Total Cost of Machines',
              },
            },
            y: {
              beginAtZero: true,
              max: maxHours,
              title: {
                display: true,
                text: 'Number of Hours',
              },
            },
          },
        },
      });
    }

    return () => {
      if (bubbleChartRef.current) bubbleChartRef.current.destroy();
    };
  }, [bubbleData]);

  return (
    <Card sx={{ borderRadius: '24px', backgroundColor: '#f9fafe', borderColor: '#d3d3d3', borderWidth: 1, borderStyle: 'solid', height: '351px', width: '95%', padding: '16px', flex: 1 }}>
      <CardContent sx={{ width: '100%' }}>
        <Typography variant="h6" sx={{ fontSize: '20px', fontWeight: '700', color: '#212121', textAlign: 'center', letterSpacing: 0 }}>COST V/S USE</Typography>
        {bubbleData.length > 0 && bubbleData.some(item => item.x > 0 || item.y > 0) ? (
          <Box sx={{ height: '320px', width: '100%', marginTop: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <canvas ref={chartRef} style={{ width: '100%' }} />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="300px" width="100%">
            <img src={nodatafoundImage} alt="No Data Found" style={{ width: '200px', marginBottom: '16px' }} />
            <Typography variant='h5' color="textSecondary">
              No data available
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default CostVSIssueChart;
