import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Avatar, IconButton, Checkbox, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useState, useEffect } from 'react';
import AdminUserList from '../../../utilities/api/user/AdminUserList';
import { useSelector } from 'react-redux';
import { ImageBaseURL } from "../../../utilities/api/axios";
import { CSVLink } from 'react-csv'; // For exporting CSV

const positionLabels = {
  0: 'TL student management body',
  1: 'Manager (other technical clubs)',
  2: 'TL Manager',
  3: 'TL Technician',
  4: 'Other',
};

const columns = [
  {
    field: 'name',
    headerName: 'User Name',
    flex: 1,
    renderCell: (params) => (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Avatar src={`${ImageBaseURL}${params.row.image}`} alt={params.row.name} />
        <div>
          <div>{params.value}</div>
          <div style={{ color: 'gray', fontSize: '0.875rem' }}>{params.row.email}</div>
        </div>
      </Box>
    ),
  },
  { field: 'role', headerName: 'Role', flex: 0.5 },
  { 
    field: 'isAdmin', 
    headerName: 'Admin', 
    flex: 0.5,
    renderCell: (params) => (
      <Checkbox checked={params.value} disabled />
    ),
  },
  { 
    field: 'isStudent', 
    headerName: 'Student', 
    flex: 0.5,
    renderCell: (params) => (
      <Checkbox checked={params.value} disabled />
    ),
  },
  { field: 'group', headerName: 'Group Name', flex: 0.5 },
  {
    field: 'actions',
    headerName: 'Action',
    flex: 0.5,
    renderCell: (params) => (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <IconButton onClick={() => console.log('Delete', params.id)}>
          <DeleteIcon color="error" />
        </IconButton>
        <IconButton onClick={() => console.log('Edit', params.id)}>
          <EditIcon color="primary" />
        </IconButton>
      </Box>
    ),
  },
];

const User = ({ searchQuery }) => { // Receive searchQuery as prop
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const user = useSelector(state => state.user.user);
  const currentUserCollegeName = user?.user?.TL_name;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await AdminUserList.AllUserListGet();
        const formattedData = data
        .filter(user => user.TL_name === currentUserCollegeName)
        .map(user => {
          let position;
          if (!user.is_admin && user.is_student) {
            position = 'Student';
          } else {
            const basePosition = positionLabels[user.position] || 'Other';
            position = basePosition === 'Other' && user.other_position
              ? `${basePosition} (${user.other_position})`
              : basePosition;
          }

          return {
            id: user.id, // Use unique ID from API
            username: user.username, // For search purposes
            name: user.name || user.username, // Display name
            email: user.email,
            role: user.other_position || position || 'N/A',
            group: user.TL_name,
            image: user.profile_pic,
            isAdmin: user.is_admin,
            isStudent: user.is_student,
            current_year: user.current_year || 'N/A',
            degree: user.degree || 'N/A',
            department: user.department || 'N/A',
            graduation_year: user.graduation_year || 'N/A',
            position, // Use the updated position logic
          };
        });
        setRows(formattedData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [user, currentUserCollegeName]);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredRows(rows); // No filter applied
    } else {
      const lowerCaseQuery = searchQuery.toLowerCase();
      setFilteredRows(
        rows.filter(
          row => 
            (row.name && row.name.toLowerCase().includes(lowerCaseQuery)) ||
            (row.username && row.username.toLowerCase().includes(lowerCaseQuery)) ||
            (row.email && row.email.toLowerCase().includes(lowerCaseQuery))
        )
      );
    }
  }, [searchQuery, rows]);

  // Generate CSV Data
  const csvHeaders = [
    { label: "User Name", key: "name" },
    { label: "Username", key: "username" },
    { label: "Email", key: "email" },
    { label: "Role", key: "role" },
    { label: "Group Name", key: "group" },
    { label: "Admin", key: "isAdmin" },
    { label: "Student", key: "isStudent" },
    { label: "Position", key: "position" },
    { label: "Degree", key: "degree" },
    { label: "Department", key: "department" },
    { label: "Graduation Year", key: "graduation_year" },
    { label: "Current Year", key: "current_year" },
  ];

  const csvData = filteredRows.map(row => ({
    name: row.name,
    username: row.username,
    email: row.email,
    role: row.role,
    group: row.group,
    isAdmin: row.isAdmin ? "Yes" : "No",
    isStudent: row.isStudent ? "Yes" : "No",
    position: row.position,
    degree: row.degree,
    department: row.department,
    graduation_year: row.graduation_year,
    current_year: row.current_year,
  }));

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename={`${currentUserCollegeName}_user_data.csv`}
          style={{
            textDecoration: 'none',
          }}
        >
          <Button variant="contained" color="primary">
            Export to CSV
          </Button>
        </CSVLink>
      </Box>
      <DataGrid
        rows={filteredRows} // Use filteredRows instead of rows
        columns={columns}
        pageSize={10} // Adjusted page size
        rowsPerPageOptions={[5, 10, 20]} // More pagination options
        checkboxSelection
      />
    </Box>
  );
};

export default User;
