import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import MachineListAPIs from "../../../utilities/api/machine/AdminMachineList";
import InventoryCategoryAPIs from "../../../utilities/api/category/AdminInventoryCategoryList";
import InventoryLocationAPIs from "../../../utilities/api/location/AdminLocationList";
import AddMachineDialog from "./AddMachine";

// Mapping for availability statuses
const availabilityMapping = {
  Moderate: 2,
  "Very Low": 0,
  Low: 1,
  High: 3,
  Always: 4,
};

// Mapping for status statuses
const statusMapping = {
  Operational: 0,
  "Under Maintenance": 1,
  "Out of Service": 2,
};

// Mapping for tags (if applicable)
const tagMapping = {
  Free: 0,
  Issue: 1,
  Purchase: 2,
  Exhaustible: 3,
  NonExhaustible: 4,
  InLabOnly: 5,
  HighValue: 6,
};

// Define the schema fields including the new parameters
const schemaFields = [
  "availability",
  "status",
  "category",
  "location",
  "name",
  "upc",
  "manufacturer",
  "description",
  "availability1",
  "status1",
  "instances",
  "supervised",
  "purchase_cost",
  // "purchase_date",           // Added Field
  // "warranty_expiration",     // Added Field
];

// Descriptions for each schema field
const schemaDescription = [
  "availability: The availability status of the machine",
  "status: The operational status",
  "category: The category of the machine",
  "location: The location of the machine",
  "name: The name of the machine",
  "upc: The UPC code",
  "manufacturer: The manufacturer of the machine",
  "description: A description of the machine",
  "availability1: Additional availability status",
  "status1: Additional operational status",
  "instances: Number of instances",
  "supervised: Whether it is supervised or not",
  "purchase_cost: The purchase cost",
  // "purchase_date: The purchase date",
  // "warranty_expiration: The warranty expiration date",
];

const ImportMachine = ({ token, isOpen, onClose }) => {
  const [csvData, setCsvData] = useState(null);
  const [mapping, setMapping] = useState({});
  const [mappingDialogOpen, setMappingDialogOpen] = useState(false);
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [errorEntries, setErrorEntries] = useState([]);
  const [successfulImports, setSuccessfulImports] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [newCategories, setNewCategories] = useState([]);
  const [newLocations, setNewLocations] = useState([]);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [currentDataCount, setCurrentDataCount] = useState(0);

  // Fetch existing categories and locations on component mount
  useEffect(() => {
    const fetchCategoriesAndLocations = async () => {
      try {
        const [categoriesResponse, locationsResponse] = await Promise.all([
          InventoryCategoryAPIs.CategoryListGet(),
          InventoryLocationAPIs.LocationListGet(),
        ]);
        setCategories(categoriesResponse.map((category) => category.name));
        setLocations(locationsResponse.map((location) => location.name));
      } catch (error) {
        console.error("Error fetching categories or locations:", error);
      }
    };

    fetchCategoriesAndLocations();
  }, []);

  // Handle the loaded CSV data
  const handleFileLoaded = (data) => {
    const headers = data[0].map(header => header.trim()); // Trim headers to remove extra spaces
    const rows = data.slice(1).filter(row => row.length > 1); // Filter out empty rows
    const initialMapping = {};

    schemaFields.forEach((field) => {
      initialMapping[field] = headers.includes(field) ? field : "";
    });

    setCsvData({ headers, rows });
    setMapping(initialMapping);
    setMappingDialogOpen(true);
    setTotalEntries(rows.length);
    onClose();
  };

  // Handle file input change
  const handleFileChange = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      const csvData = reader.result;
      // Using a CSV parsing library like PapaParse would be more robust
      handleFileLoaded(csvData.split("\n").map((row) => row.split(",")));
    };
    reader.readAsText(file);
  };

  // Handle file drop event
  const handleDrop = useCallback((event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileChange(file);
    }
  }, []);

  // Allow drop by preventing default behavior
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Trigger file input click
  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  // Process and import the inventory data
  const updateInventory = async (mapping) => {
    if (!csvData || Object.keys(mapping).length === 0) return;

    const { headers, rows } = csvData;
    const errors = [];
    let successfulImportsCount = 0;
    let newCategoriesList = [];
    let newLocationsList = [];

    setIsLoading(true); // Start loading
    // setLoadingMessage('Validating and processing inventory...');
    // setLoadingMessage(`Validating and processing ${mapping.name}`);
    setCurrentDataCount(0); // Reset progress

    for (let row of rows) {
      const entry = {};
      let tags = [];
      
      setCurrentDataCount(prevCount => prevCount + 1); // Increment the number of processed rows

      for (let i = 0; i < headers.length; i++) {
        const csvField = headers[i].trim();
        const schemaField = Object.keys(mapping).find(
          (key) => mapping[key] === csvField
        );
        let value = row[i].trim();

        // Map availability if applicable
        if (
          schemaField &&
          schemaField === "availability" &&
          availabilityMapping[value] !== undefined
        ) {
          value = availabilityMapping[value];
        }

        // Handle tags if applicable
        if (schemaField && schemaField.startsWith("tag") && tagMapping[value] !== undefined) {
          tags.push(value);
          value = tagMapping[value];
        }

        // Handle availability1 and status1
        if (schemaField === "availability1" && availabilityMapping[value] !== undefined) {
          value = availabilityMapping[value];
        }

        if (schemaField === "status1" && statusMapping[value] !== undefined) {
          value = statusMapping[value];
        }

        if (schemaField) {
          entry[schemaField] = value;
        }
      }

      // Handle Tags (if applicable)
      if (tags.length > 0) {
        entry.tags = [...new Set(tags)]; // Remove duplicate tags
      }

      // Validate Tags
      const uniqueTags = entry.tags || [];
      const validSingleTags = ["Free", "Issue", "Purchase"];
      const exclusiveTags = ["Exhaustible", "NonExhaustible"];

      const singleTagCount = uniqueTags.filter(tag => validSingleTags.includes(tag)).length;
      const hasBothExclusiveTags = exclusiveTags.every(tag => uniqueTags.includes(tag));

      if (singleTagCount > 1) {
        errors.push({ entry, error: `Only one of 'Free', 'Issue', or 'Purchase' is allowed in the tags. Found: ${uniqueTags.join(', ')}` });
        continue;
      }

      if (hasBothExclusiveTags) {
        errors.push({ entry, error: `'Exhaustible' and 'NonExhaustible' cannot coexist in the tags. Found: ${uniqueTags.join(', ')}` });
        continue;
      }

      // Handle Category
      if (!categories.includes(entry.category)) {
        try {
          await InventoryCategoryAPIs.CategoryListPost(entry.category, token);
          setCategories((prevCategories) => [
            ...prevCategories,
            entry.category,
          ]);
          newCategoriesList.push(entry.category);
        } catch (error) {
          if (error.response?.status === 400 || error.response?.status === 409) {
            // Ignore error if category already exists
            console.log(`Category '${entry.category}' already exists, ignoring error.`);
          } else {
            errors.push({
              entry,
              error: `Failed to add category: ${entry.category}. ${error.message}`,
            });
            continue; // Skip to the next entry if a different error occurs
          }
        }
      }

      // Handle Location
      if (entry.location === "") {
        // Skip the location check if the field is blank
        entry.location = null;
      } else if (!locations.includes(entry.location)) {
        try {
          await InventoryLocationAPIs.LocationListPost(entry.location, token);
          setLocations((prevLocations) => [...prevLocations, entry.location]);
          newLocationsList.push(entry.location);
        } catch (error) {
          if (error.response?.status === 400 || error.response?.status === 409) {
            // Ignore error if location already exists
            console.log(`Location '${entry.location}' already exists, ignoring error.`);
          } else {
            errors.push({
              entry,
              error: `Failed to add location: ${entry.location}. ${error.message}`,
            });
            continue; // Skip to the next entry if a different error occurs
          }
        }
      }

      // Handle Purchase Date and Warranty Expiration
      if (entry.purchase_date) {
        const parsedDate = Date.parse(entry.purchase_date);
        if (isNaN(parsedDate)) {
          errors.push({ entry, error: `Invalid purchase_date format: ${entry.purchase_date}` });
          continue;
        } else {
          // Format date as YYYY-MM-DD
          const date = new Date(parsedDate);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          entry.purchase_date = `${year}-${month}-${day}`;
        }
      }

      if (entry.warranty_expiration) {
        const parsedDate = Date.parse(entry.warranty_expiration);
        if (isNaN(parsedDate)) {
          errors.push({ entry, error: `Invalid warranty_expiration format: ${entry.warranty_expiration}` });
          continue;
        } else {
          // Format date as YYYY-MM-DD
          const date = new Date(parsedDate);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          entry.warranty_expiration = `${year}-${month}-${day}`;
        }
      }

      setLoadingMessage(`Validating and processing ${entry.name}`);
      // Add Machine Inventory
      try {
        await MachineListAPIs.AddInventory(entry, token);
        successfulImportsCount++;
      } catch (error) {
        if (error.response?.status === 400 && error.response?.data?.name?.includes("This field may not be blank.")) {
          // Ignore the error if location field is blank
          console.log(`Skipping entry due to blank location field: ${JSON.stringify(entry)}`);
        } else {
          errors.push({ entry, error: error.response?.data || error.message });
        }
      }
    }

    setErrorEntries(errors);
    setMappingDialogOpen(false);
    setResultDialogOpen(true);
    setSuccessfulImports(successfulImportsCount);
    setNewCategories(newCategoriesList);
    setNewLocations(newLocationsList);

    setIsLoading(false); // End loading
  };

  // Handle closing all dialogs
  const handleClose = () => {
    onClose();
    setMappingDialogOpen(false);
    setResultDialogOpen(false);
  };

  // Handle opening and closing the info dialog
  const handleInfoClose = () => {
    setInfoDialogOpen(false);
  };

  const handleInfoOpen = () => {
    setInfoDialogOpen(true);
  };

  return (
    <>
      {/* Main Import Dialog */}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        sx={{ "& .MuiDialog-paper": { borderRadius: "24px" } }}
      >
        <DialogContent>
          <IconButton
            aria-label="info"
            onClick={handleInfoOpen}
            sx={{
              position: "absolute",
              right: 20,
              top: 16,
              zIndex: '1000',
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <InfoIcon />
          </IconButton>
          <div onDrop={handleDrop} onDragOver={handleDragOver}>
            <input
              type="file"
              accept=".csv"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(event) => {
                const file = event.target.files[0];
                if (file) {
                  handleFileChange(file);
                }
              }}
            />
            {isOpen && (
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                  cursor: "pointer",
                  border: "2px dashed #ccc",
                  borderRadius: "12px",
                  padding: "20px",
                  textAlign: "center",
                }}
                onClick={handleIconClick}
              >
                <CreateNewFolderIcon sx={{ fontSize: 80, color: "#ccc" }} />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Click to select a file to import
                </Typography>
                <Typography variant="body2">
                  Or drag and drop it here
                </Typography>
              </Box>
            )}
          </div>
        </DialogContent>
      </Dialog>

      {/* Loading Dialog - Shown during processing */}
      <Dialog
        open={isLoading}
        onClose={() => {}}
        maxWidth="sm"
        fullWidth
        sx={{ "& .MuiDialog-paper": { borderRadius: "24px", padding: "20px" } }}
      >
        <DialogTitle sx={{ fontSize: "24px", fontWeight: "bold" }}>Processing...</DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress sx={{ mb: 2 }} />
            <Typography variant="body1" sx={{ color: '#898989' }}>
              {loadingMessage}
            </Typography>
            <Typography variant="body2" sx={{ color: '#898989' }}>
              {currentDataCount} / {csvData?.rows.length} processed.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>


      {/* Mapping Dialog */}
      <MappingDialog
        open={mappingDialogOpen}
        headers={csvData ? csvData.headers : []}
        schemaFields={schemaFields}
        mapping={mapping}
        onClose={() => setMappingDialogOpen(false)}
        onSave={(newMapping) => {
          setMapping(newMapping);
          updateInventory(newMapping);
        }}
        onInfoOpen={handleInfoOpen}
      />

      {/* Result Dialog */}
      <ResultDialog
        open={resultDialogOpen}
        errors={errorEntries}
        onClose={handleClose}
        successfulImports={successfulImports}
        totalEntries={totalEntries}
        newCategories={newCategories}
        newLocations={newLocations}
        onInfoOpen={handleInfoOpen}
      />

      {/* Information Dialog */}
      <InfoDialog
        open={infoDialogOpen}
        onClose={handleInfoClose}
        schemaDescription={schemaDescription}
      />
    </>
  );
};

// MappingDialog Component for field mapping UI
const MappingDialog = ({
  open,
  headers,
  schemaFields,
  mapping,
  onClose,
  onSave,
  onInfoOpen,
}) => {
  const [localMapping, setLocalMapping] = useState(mapping);
  const [usedFields, setUsedFields] = useState([]);

  useEffect(() => {
    setLocalMapping(mapping);
    setUsedFields(Object.values(mapping).filter((field) => field !== ""));
  }, [mapping]);

  const handleMappingChange = (schemaField, csvField) => {
    const newMapping = { ...localMapping, [schemaField]: csvField };
    setLocalMapping(newMapping);
    setUsedFields(Object.values(newMapping).filter((field) => field !== ""));
  };

  const handleSave = () => {
    onSave(localMapping);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{ "& .MuiDialog-paper": { borderRadius: "24px", background: '#f1f6fd' } }}
    >
      <DialogTitle sx={{ fontSize: '32px' }}>
        Column Mapping
        <IconButton
          aria-label="info"
          onClick={onInfoOpen}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <InfoIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Select the MIS field to import, then set how you would like these
          converted to fields in CSV.
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#9E9E9E" }}>
                <TableCell>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ color: "#FFFFFF" }}
                  >
                    MIS Field
                  </Typography>
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ color: "#FFFFFF" }}
                  >
                    CSV Field
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schemaFields.map((field) => (
                <TableRow key={field} sx={{ backgroundColor: "#FFFFFF" }}>
                  <TableCell>
                    <Typography variant="body1" sx={{ color: "#898989" }}>
                      {field}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1">→</Typography>
                  </TableCell>
                  <TableCell>
                    <Select
                      fullWidth
                      value={localMapping[field] || ""}
                      onChange={(e) =>
                        handleMappingChange(field, e.target.value)
                      }
                      sx={{ color: "#898989" }}
                    >
                      <MenuItem value="">
                        <em>Don't map this field</em>
                      </MenuItem>
                      {headers.map((header) => (
                        <MenuItem
                          key={header}
                          value={header}
                          disabled={usedFields.includes(header)}
                        >
                          {header}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSave}
          sx={{
            background: '#007BFF',
            color: '#FFFFFF',
            borderRadius: '12px',
            padding: '12px 16px',
          }}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// ResultDialog Component to show successful or failed entries 
const ResultDialog = ({
  open,
  errors,
  onClose,
  successfulImports,
  totalEntries,
  newCategories,
  newLocations,
  onInfoOpen,
}) => {
  const [showAddMachine, setShowAddMachine] = useState(false);

  const handleAddMachineClick = () => {
    setShowAddMachine(true);
  };

  const handleCloseAddMachine = () => {
    setShowAddMachine(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      minWidth="md"
      maxWidth="lg"
      sx={{ "& .MuiDialog-paper": { borderRadius: "24px", background: '#f1f6fd' } }}
    >
      <DialogTitle>
        {showAddMachine
          ? "Add Machine"
          : errors.length > 0
          ? "Failed Entries"
          : "Success"}
        <IconButton
          aria-label="info"
          onClick={onInfoOpen}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <InfoIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {showAddMachine ? (
          <AddMachineDialog
            open={showAddMachine}
            onClose={handleCloseAddMachine}
          />
        ) : (
          <>
            {errors.length > 0 ? (
              <>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  You can resolve it!
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Typography variant="outlined">Correct the CSV File</Typography>
                  <Typography variant="body1" sx={{ mx: 2 }}>
                    or
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{
                      background: '#007BFF',
                      color: '#FFFFFF',
                      borderRadius: '12px',
                      padding: '8px 12px',
                    }}
                    onClick={handleAddMachineClick}
                  >
                    Manually add Machine
                  </Button>
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 2,
                    textAlign: "center",
                    fontSize: "larger",
                    border: "1px solid",
                    borderRadius: "32px",
                    padding: "8px",
                  }}
                >
                  Successfully imported entries: {successfulImports} / {totalEntries}
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#9E9E9E" }}>
                        <TableCell sx={{ color: "#FFFFFF" }}>
                          <Typography variant="body1" fontWeight="bold">
                            Name
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ color: "#FFFFFF" }}>
                          <Typography variant="body1" fontWeight="bold">
                            UPC
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ color: "#FFFFFF" }}>
                          <Typography variant="body1" fontWeight="bold">
                            Description
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ color: "#FFFFFF" }}>
                          <Typography variant="body1" fontWeight="bold">
                            Instances
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ color: "#FFFFFF" }}>
                          <Typography variant="body1" fontWeight="bold">
                            Category
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ color: "#FFFFFF" }}>
                          <Typography variant="body1" fontWeight="bold">
                            Location
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ color: "#FFFFFF" }}>
                          <Typography variant="body1" fontWeight="bold">
                            Availability
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ color: "#FFFFFF" }}>
                          <Typography variant="body1" fontWeight="bold">
                            Purchase Date
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ color: "#FFFFFF" }}>
                          <Typography variant="body1" fontWeight="bold">
                            Warranty Expiration
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ color: "#FFFFFF" }}>
                          <Typography variant="body1" fontWeight="bold">
                            Error
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {errors.map((error, index) => (
                        <TableRow
                          key={index}
                          sx={{ backgroundColor: "#FFFFFF" }}
                        >
                          <TableCell>
                            <Typography variant="body1" sx={{ color: "#898989", textAlign: 'center' }}>
                              {error.entry.name || "N/A"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1" sx={{ color: "#898989", textAlign: 'center' }}>
                              {error.entry.upc || "N/A"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1" sx={{ color: "#898989", textAlign: 'center' }}>
                              {error.entry.description || "N/A"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1" sx={{ color: "#898989", textAlign: 'center' }}>
                              {error.entry.instances || "N/A"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1" sx={{ color: "#898989", textAlign: 'center' }}>
                              {error.entry.category || "N/A"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1" sx={{ color: "#898989", textAlign: 'center' }}>
                              {error.entry.location || "N/A"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1" sx={{ color: "#898989", textAlign: 'center' }}>
                              {error.entry.availability !== undefined ? error.entry.availability : "N/A"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1" sx={{ color: "#898989", textAlign: 'center' }}>
                              {error.entry.purchase_date ? error.entry.purchase_date : "N/A"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1" sx={{ color: "#898989", textAlign: 'center' }}>
                              {error.entry.warranty_expiration ? error.entry.warranty_expiration : "N/A"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1" sx={{ color: "red" }}>
                              {typeof error.error === "object"
                                ? JSON.stringify(error.error)
                                : error.error}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {(newCategories.length > 0 || newLocations.length > 0) && (
                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    {newCategories.length > 0 && (
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6">
                          New Categories Added:
                        </Typography>
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                          <Table>
                            <TableHead>
                              <TableRow sx={{ backgroundColor: "#9E9E9E" }}>
                                <TableCell sx={{ color: "#FFFFFF" }}>
                                  <Typography variant="body1" fontWeight="bold">
                                    Category Name
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {newCategories.map((category, index) => (
                                <TableRow
                                  key={index}
                                  sx={{ backgroundColor: "#F2F2F2" }}
                                >
                                  <TableCell>
                                    <Typography variant="body1">
                                      {category}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )}
                    {newLocations.length > 0 && (
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6">
                          New Locations Added:
                        </Typography>
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                          <Table>
                            <TableHead>
                              <TableRow sx={{ backgroundColor: "#9E9E9E" }}>
                                <TableCell sx={{ color: "#FFFFFF" }}>
                                  <Typography variant="body1" fontWeight="bold">
                                    Location Name
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {newLocations.map((location, index) => (
                                <TableRow
                                  key={index}
                                  sx={{ backgroundColor: "#F2F2F2" }}
                                >
                                  <TableCell>
                                    <Typography variant="body1">
                                      {location}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )}
                  </Grid>
                )}
              </>
            ) : (
              <Box sx={{ textAlign: "center", mt: 4 }}>
                <Typography variant="h4" sx={{ color: "green", mb: 2 }}>
                  All entries have been successfully added.
                </Typography>
                <Typography variant="body1" sx={{ color: '#898989' }}>
                  Successfully imported entries: {successfulImports} / {totalEntries}
                </Typography>
              </Box>
            )}
          </>
        )}
      </DialogContent>
      {!showAddMachine && (
        <DialogActions>
          <Button
            onClick={onClose}
            sx={{
              background: '#007BFF',
              color: '#FFFFFF',
              borderRadius: '12px',
              padding: '12px 8px',
            }}
          >
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

// InfoDialog Component to provide schema information
const InfoDialog = ({ open, onClose, schemaDescription }) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth="sm"
    fullWidth
    sx={{ "& .MuiDialog-paper": { borderRadius: "24px", padding: "20px" } }}
  >
    <DialogTitle sx={{ fontSize: "24px", fontWeight: "bold" }}>Schema Information</DialogTitle>
    <DialogContent>
      <Typography variant="body1" sx={{ mb: 2 }}>
        These are the mandatory fields:
      </Typography>
      <Box component="ul" sx={{ listStyleType: "none", padding: 0, mb: 2, pl: 2 }}>
        <li>
          <Typography variant="body2" sx={{ mb: 1, paddingBottom: "8px" }}>
            <strong>name:</strong> Product type is required
          </Typography>
        </li>
        <li>
          <Typography variant="body2" sx={{ mb: 1, paddingBottom: "8px" }}>
            <strong>instances:</strong> Instances is required
          </Typography>
        </li>
        {/* Add other mandatory fields as needed */}
      </Box>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Available fields:
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#9E9E9E" }}>
              <TableCell>
                <Typography variant="body1" fontWeight="bold" sx={{ color: "#FFFFFF" }}>
                  Field
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" fontWeight="bold" sx={{ color: "#FFFFFF" }}>
                  Description
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" fontWeight="bold" sx={{ color: "#FFFFFF" }}>
                  Options
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schemaDescription.map((desc, index) => {
              const [field, description] = desc.split(": ");
              let options = "";
              if (field === "availability") {
                options = "Moderate, Very Low, Low, High, Always";
              } else if (field === "status" || field === "status1") {
                options = "Operational, Under Maintenance, Out of Service";
              } else if (field === "supervised") {
                options = "Yes, No";
              } else if (field === "purchase_date" || field === "warranty_expiration") {
                options = "YYYY-MM-DD";
              }

              return (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="body2" sx={{ color: "#898989" }}>
                      {field}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" sx={{ color: "#898989" }}>
                      {description}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" sx={{ color: "#898989" }}>
                      {options}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        sx={{
          background: '#007BFF',
          color: '#FFFFFF',
          borderRadius: '12px',
          padding: '12px 8px',
        }}
      >
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default ImportMachine;
