
import { axiosApiService } from "./axios";
const Auth_APIs = {
    register: async function  (values) {
            const response = await axiosApiService.post(`/auth/register/`, values);
            return response;
    },

    adminLogin: async function (values) {
            const response = await axiosApiService.post(`/auth/admin_login/`, values);
            //dispatch({ type: actionTypes.USER_LOGIN_SUCCESS, payload: response.data });
            return response.data
    },

    orgAdminregister: async function  (values) {
             const response = await axiosApiService.post(`/auth/register/`, values);
             return response;
     },

    // orgAdminLogin: async function ({email, password}) {
    //     const formData = new FormData();
    //     formData.append("email", email);
    //     formData.append("password", password);
    //     //console.log('198', formData);
    //     try{
    //         const response = await axiosApiService.post(`/auth/org_admin_request/`, formData, {
    //             headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //         });
    //         return response;
    //     }catch(error){
    //         console.error("Error fetching data", error);
    //         return {};
    //     }
    // },

}
export default Auth_APIs;
