import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import {
  eachDayOfInterval,
  endOfWeek,
  format,
  startOfWeek,
  getDay,
  getDaysInMonth,
  startOfMonth,
  endOfMonth,
  getDate,
  getMonth,
  getMinutes,
} from "date-fns";
import {
  Paper,
  Grid,
  Button,
  TextField,
  Avatar,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
} from "@mui/material";
import BookingCard from "./cardCalender";

let arr=[];
function MonthsData({ reservationList, selectedDate, handleMachineSelect, handleProjectSelect }) {
  // const [selectedDate, setSelectedDate] = useState(
  //   new Date()
  // );
  const dates = eachDayOfInterval({
    start: startOfMonth(selectedDate),
    end: endOfMonth(selectedDate),
  });

  const machineNames = useMemo(() => {
    let names = [];
    reservationList.forEach((data) => {
      const chk1 = startOfMonth(selectedDate) < new Date(data.start_time);
      const chk2 = endOfMonth(selectedDate) > new Date(data.start_time);
      if(chk1 && chk2){
        names.push(data.name);
      }
    });
    return [...new Set(names)];
  }, [selectedDate]);

  useEffect(() => {
    handleMachineSelect(machineNames);
  }, [machineNames]);

  const projectNames = useMemo(() => {
    let names = [];
    reservationList.forEach((data) => {
      const chk1 = startOfMonth(selectedDate) < new Date(data.start_time);
      const chk2 = endOfMonth(selectedDate) > new Date(data.start_time);
      if(chk1 && chk2){
        names.push(data.project_name);
      }
    });
    return [...new Set(names)];
  }, [selectedDate]);

  useEffect(() => {
    handleProjectSelect(projectNames);
  }, [projectNames]);



  // const [date, setDate] = useState(1);
  return (
    <Grid container>
      <Grid item xs>
        {/* The rest of your calendar grid */}
        <Box sx={{ display: "flex", flexWrap: "wrap", margin:"1rem",  }}>
          {dates.map((day, index) => (
            <Box
              key={day.toISOString()}
              sx={{
                border: "1px solid #D3D3D3",
                flexGrow: 1,
                height: "11rem",
                width: "15rem",
                paddingBottom: "1rem",
                maxWidth: "20rem",
                padding:".5rem",
              }}
            >
              <Box
                sx={{
                  height: "3.8125rem",
                  // flexShrink: 0,
                  // alignSelf: 'stretch',
                  // background: "var(--Primary-Primary10, #E9E9E9)",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "var(--Primary-Primary03, #5C5C5C)",
                    fontFamily: "Segoe UI",
                    fontSize: "2rem",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "2rem",
                    paddingTop: "4px",
                    paddingLeft: "8px",
                    paddingBottom: "0px",
                    color: "black",
                  }}
                >
                  {format(day, "d")}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "var(--Primary-Primary03, #5C5C5C)",
                      fontFamily: "Segoe UI",
                      fontSize: "0.85rem",
                      fontStyle: "normal",
                      fontWeight: 600,
                      padding: "3px",
                      paddingLeft: "8px",
                      // lineHeight: '1.2rem',
                      color: "black",
                    }}
                  >
                    {format(day, "EEEE")}
                  </Typography>
                </Box>
                
              </Box>
              <Box
                key={index}
                sx={{
                  height: "7rem",
                  padding: "0.5rem",
                  // background: 'var(--Primary-Primary10, #E9E9E9)',
                  // borderTop: "1px solid #D3D3D3",
                  // flexGrow: 1,
                  // flexShrink: 0,
                  // width:"20rem",
                  // display: "flex",
                  // flexWrap: "wrap",
                }}
              >
                {reservationList.map((data, idx) => {
                  const dataDate = getDate(new Date(data.start_time));
                  const dataTime = format(new Date(data.start_time), "HH");
                  const dataMonth = getMonth(new Date(data.start_time));
                  const chk = (getDate(day) === dataDate) && (dataMonth === getMonth(selectedDate))
                  if(chk){
                    arr.push(data);
                    // console.log(data)
                  }
                  return null
                })}
                {
                  arr.map((data, idx)=>{
                    let cpy_arr = arr;
                    if(idx===0)
                    arr=[];
                    return ( idx===0 ?
                      <BookingCard
                        key={data.id}
                        bookingName={data.name}
                        timeDuration={
                          ((new Date(data.end_time) - new Date(data.start_time)) /
                          (60000 * 60)).toFixed(2)
                        }
                        id={data.id}
                        reservationList={cpy_arr}
                        type={null}
                        marginStart={null}
                      /> : null
                    )
                  })
                }
              </Box>
            </Box>
          ))}
        </Box>
        {/* ))}


        </Box> */}
      </Grid>
    </Grid>
  );
}
export default MonthsData;
