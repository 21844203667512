import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import { S3Client } from '@aws-sdk/client-s3';
import { Upload as S3Upload } from '@aws-sdk/lib-storage';
import { notifySuccess, notifyError, notifyInfo } from '../../../../utils/notifyToasts';
import AdminVideoAPIs from '../../../../utilities/api/tutorials/AdminVideoAPIs';

const AddVideo = ({ onRemove, onUploadSuccess, isFormFilled, handleUploading }) => {
  const [videoFile, setVideoFile] = useState(null);
  const [videoURL, setVideoURL] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [infoToastId, setInfoToastId] = useState(null);

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_ACCESS,
      secretAccessKey: process.env.REACT_APP_SECRET,
    },
  };

  useEffect(() => {
    handleUploading(uploading);
  }, [uploading, handleUploading]);

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Required'),
      description: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      // Form submit logic here if needed
    },
  });

  const handleFileInput = (file) => {
    if (file && file.type === 'video/mp4') {
      const userConfirmed = window.confirm(`Are you sure you want to upload the file: ${file.name}?`);
      if (userConfirmed) {
        setVideoFile(file);
        setVideoURL(URL.createObjectURL(file));
        const toastId = notifyInfo('Starting upload...');
        setInfoToastId(toastId);
        startUpload(file, toastId);
      }
    } else {
      notifyError('Please upload a .mp4 file');
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files[0];
    handleFileInput(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleFileInput(file);
  };

  const startUpload = async (file, toastId) => {
    setUploading(true);
    setUploadProgress(0);

    const s3Client = new S3Client({ region: config.region, credentials: config.credentials });

    try {
      const params = {
        Bucket: config.bucketName,
        Key: file.name,
        Body: file,
        ContentType: file.type,
      };

      const upload = new S3Upload({
        client: s3Client,
        params,
        leavePartsOnError: false,
      });

      upload.on('httpUploadProgress', (progress) => {
        const percent = Math.round((progress.loaded / progress.total) * 100);
        setUploadProgress(percent);
        notifySuccess(`Uploading... ${percent}%`, toastId);
      });

      await upload.done();

      const videoUrl = `https://${config.bucketName}.s3.${config.region}.amazonaws.com/${file.name}`;
      setVideoURL(videoUrl);
      notifySuccess('Upload successful!', toastId);

      const videoData = {
        title: formik.values.title,
        description: formik.values.description,
        link: videoUrl,
      };

      const response = await AdminVideoAPIs.CreateVideoPost(videoData);
      onUploadSuccess(response.id, videoUrl);
    } catch (err) {
      console.error('Error uploading video:', err);
      notifyError('Error uploading video.');
      setUploadProgress(0);
    } finally {
      setUploading(false);
    }
  };

  const canUpload = isFormFilled && formik.values.title && formik.values.description;

  return (
    <div style={{ marginBottom: '1rem', padding: '20px', border: '2px dotted #ccc', borderRadius: '8px', position: 'relative', display: 'flex', alignItems: 'center' }}>
      <button
        onClick={onRemove}
        style={{
          position: 'absolute',
          fontSize: '1.2rem',
          top: '10px',
          right: '10px',
          color: 'black',
          background: 'transparent',
          border: 'none',
          width: '20px',
          height: '20px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CloseIcon />
      </button>
      {videoURL && (
        <div style={{ position: 'relative', flex: '1' }}>
          <video width="300" controls>
            <source src={videoURL} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      <form onSubmit={formik.handleSubmit} style={{ flex: '2', marginLeft: '20px' }}>
        <div style={{ marginBottom: '20px' }}>
          <input
            type="text"
            placeholder="Title"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={{ marginRight: '10px', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', width: '80%', marginBottom: '1rem' }}
          />
          {formik.touched.title && formik.errors.title ? (
            <div style={{ color: 'red', marginBottom: '1rem' }}>{formik.errors.title}</div>
          ) : null}
          <input
            type="text"
            placeholder="Description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc', width: '80%' }}
          />
          {formik.touched.description && formik.errors.description ? (
            <div style={{ color: 'red' }}>{formik.errors.description}</div>
          ) : null}
        </div>
        {!videoURL && (
          <div
            onDrop={canUpload ? handleDrop : null}
            onDragOver={canUpload ? handleDragOver : null}
            onDragLeave={canUpload ? handleDragLeave : null}
            style={{
              border: canUpload ? (dragging ? '2px dashed #000' : '2px dashed #ccc') : '2px dashed #e0e0e0',
              padding: '20px',
              textAlign: 'center',
              borderRadius: '4px',
              background: canUpload ? (dragging ? '#f0f0f0' : '#fff') : '#f9f9f9',
              cursor: canUpload ? 'pointer' : 'not-allowed',
              width: '100%',
              boxSizing: 'border-box',
            }}
            title={!canUpload ? 'Fill details first' : ''}
          >
            <input
              type="file"
              accept=".mp4"
              onChange={canUpload ? handleFileChange : null}
              style={{ display: 'none' }}
              id="videoUpload"
              aria-label="Upload Video"
              disabled={!canUpload}
            />
            <label htmlFor="videoUpload" style={{ cursor: canUpload ? 'pointer' : 'not-allowed', display: 'block', width: '100%' }}>
              {dragging ? 'Drop your video here' : (
                <>
                  <PlayArrowIcon sx={{ fontSize: '3rem' }} />
                  <br />
                  Drag & Drop or Choose file
                </>
              )}
            </label>
          </div>
        )}
        {uploading && (
          <div style={{ margin: '20px 0', textAlign: 'center' }}>
            <div style={{ height: '20px', width: '100%', background: '#ccc', borderRadius: '4px' }}>
              <div
                style={{
                  height: '100%',
                  width: `${uploadProgress}%`,
                  background: '#4caf50',
                  borderRadius: '4px',
                  transition: 'width 0.2s',
                }}
              />
            </div>
            <p>{uploadProgress}%</p>
          </div>
        )}
      </form>
    </div>
  );
};

export default AddVideo;


// Integrating API using .env variables
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// import React, { useState, useEffect } from 'react';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import CloseIcon from '@mui/icons-material/Close';
// import { notifySuccess, notifyError, notifyInfo } from '../../../../utils/notifyToasts';
// import AdminUploadURL from '../../../../utilities/api/tutorials/AdminUploadURL';
// import { synchronizeTime } from '../../../../utilities/api/timeSync';

// const AddVideo = ({ onRemove, onUploadSuccess, isFormFilled, handleUploading }) => {
//   const [videoFile, setVideoFile] = useState(null);
//   const [videoURL, setVideoURL] = useState(null);
//   const [dragging, setDragging] = useState(false);
//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [uploading, setUploading] = useState(false);
//   const [infoToastId, setInfoToastId] = useState(null);
//   const [timeDifference, setTimeDifference] = useState(0);

//   useEffect(() => {
//     handleUploading(uploading);
//     const fetchTimeDifference = async () => {
//       const diff = await synchronizeTime();
//       setTimeDifference(diff);
//     };
//     fetchTimeDifference();
//   }, [uploading, handleUploading]);

//   const formik = useFormik({
//     initialValues: {
//       tutorialName: '',
//       description: '',
//     },
//     validationSchema: Yup.object({
//       tutorialName: Yup.string().required('Required'),
//       description: Yup.string().required('Required'),
//     }),
//     onSubmit: (values) => {
//       // Form submit logic here if needed
//     },
//   });

//   const handleFileInput = (file) => {
//     if (file && file.type === 'video/mp4') {
//       const userConfirmed = window.confirm(`Are you sure you want to upload the file: ${file.name}?`);
//       if (userConfirmed) {
//         setVideoFile(file);
//         setVideoURL(URL.createObjectURL(file));
//         const toastId = notifyInfo('Starting upload...');
//         setInfoToastId(toastId);
//         startUpload(file, toastId);
//       }
//     } else {
//       notifyError('Please upload a .mp4 file');
//     }
//   };

//   const handleDrop = (e) => {
//     e.preventDefault();
//     setDragging(false);
//     const file = e.dataTransfer.files[0];
//     handleFileInput(file);
//   };

//   const handleDragOver = (e) => {
//     e.preventDefault();
//     setDragging(true);
//   };

//   const handleDragLeave = () => {
//     setDragging(false);
//   };

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     handleFileInput(file);
//   };

//   const startUpload = async (file, toastId) => {
//     setUploading(true);
//     setUploadProgress(0);

//     try {
//       console.log('file_name', file.name);
//       const response = await AdminUploadURL.PresignedUrlGet(file.name, timeDifference);
//       const { url } = response;
//       await uploadToS3(file, url, toastId);
//     } catch (err) {
//       console.error('Error generating presigned URL:', err);
//       notifyError('Error generating presigned URL.');
//       setUploadProgress(0);
//       setUploading(false);
//     }
//   };

//   const uploadToS3 = async (file, url, toastId) => {
//     try {
//       const xhr = new XMLHttpRequest();
//       xhr.open('PUT', url);
//       xhr.setRequestHeader('Content-Type', file.type);

//       xhr.upload.onprogress = (event) => {
//         if (event.lengthComputable) {
//           const percentComplete = Math.round((event.loaded / event.total) * 100);
//           setUploadProgress(percentComplete);
//           notifySuccess(`Uploading... ${percentComplete}%`, toastId);
//         }
//       };

//       xhr.onload = () => {
//         if (xhr.status === 200) {
//           const videoUrl = url.split('?')[0]; // Strip query params to get the S3 object URL
//           setVideoURL(videoUrl);
//           notifySuccess('Upload successful!', toastId);
//           onUploadSuccess(videoUrl);
//         } else {
//           notifyError('Error uploading video.');
//         }
//         setUploading(false);
//       };

//       xhr.onerror = () => {
//         notifyError('Error uploading video.');
//         setUploading(false);
//       };

//       xhr.send(file);
//     } catch (err) {
//       console.error('Error uploading video:', err);
//       notifyError('Error uploading video.');
//       setUploading(false);
//     }
//   };

//   const canUpload = isFormFilled && formik.values.tutorialName && formik.values.description;

//   return (
//     <div style={{ marginBottom: '1rem', padding: '20px', border: '2px dotted #ccc', borderRadius: '8px', position: 'relative', display: 'flex', alignItems: 'center' }}>
//       <button
//         onClick={onRemove}
//         style={{
//           position: 'absolute',
//           fontSize: '1.2rem',
//           top: '10px',
//           right: '10px',
//           color: 'black',
//           background: 'transparent',
//           border: 'none',
//           width: '20px',
//           height: '20px',
//           cursor: 'pointer',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//         }}
//       >
//         <CloseIcon />
//       </button>
//       {videoURL && (
//         <div style={{ position: 'relative', flex: '1' }}>
//           <video width="300" controls>
//             <source src={videoURL} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         </div>
//       )}
//       <form onSubmit={formik.handleSubmit} style={{ flex: '2', marginLeft: '20px' }}>
//         <div style={{ marginBottom: '20px' }}>
//           <input
//             type="text"
//             placeholder="Tutorial Name"
//             name="tutorialName"
//             value={formik.values.tutorialName}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             style={{ marginRight: '10px', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', width: '80%', marginBottom: '1rem' }}
//           />
//           {formik.touched.tutorialName && formik.errors.tutorialName ? (
//             <div style={{ color: 'red', marginBottom: '1rem' }}>{formik.errors.tutorialName}</div>
//           ) : null}
//           <input
//             type="text"
//             placeholder="Description"
//             name="description"
//             value={formik.values.description}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc', width: '80%' }}
//           />
//           {formik.touched.description && formik.errors.description ? (
//             <div style={{ color: 'red' }}>{formik.errors.description}</div>
//           ) : null}
//         </div>
//         {!videoURL && (
//           <div
//             onDrop={canUpload ? handleDrop : null}
//             onDragOver={canUpload ? handleDragOver : null}
//             onDragLeave={canUpload ? handleDragLeave : null}
//             style={{
//               border: canUpload ? (dragging ? '2px dashed #000' : '2px dashed #ccc') : '2px dashed #e0e0e0',
//               padding: '20px',
//               textAlign: 'center',
//               borderRadius: '4px',
//               background: canUpload ? (dragging ? '#f0f0f0' : '#fff') : '#f9f9f9',
//               cursor: canUpload ? 'pointer' : 'not-allowed',
//               width: '100%',
//               boxSizing: 'border-box',
//             }}
//             title={!canUpload ? 'Fill details first' : ''}
//           >
//             <input
//               type="file"
//               accept=".mp4"
//               onChange={canUpload ? handleFileChange : null}
//               style={{ display: 'none' }}
//               id="videoUpload"
//               aria-label="Upload Video"
//               disabled={!canUpload}
//             />
//             <label htmlFor="videoUpload" style={{ cursor: canUpload ? 'pointer' : 'not-allowed', display: 'block', width: '100%' }}>
//               {dragging ? 'Drop your video here' : (
//                 <>
//                   <PlayArrowIcon sx={{ fontSize: '3rem' }} />
//                   <br />
//                   Drag & Drop or Choose file
//                 </>
//               )}
//             </label>
//           </div>
//         )}
//         {uploading && (
//           <div style={{ margin: '20px 0', textAlign: 'center' }}>
//             <div style={{ height: '20px', width: '100%', background: '#ccc', borderRadius: '4px' }}>
//               <div
//                 style={{
//                   height: '100%',
//                   width: `${uploadProgress}%`,
//                   background: '#4caf50',
//                   borderRadius: '4px',
//                   transition: 'width 0.2s',
//                 }}
//               />
//             </div>
//             <p>{uploadProgress}%</p>
//           </div>
//         )}
//       </form>
//     </div>
//   );
// };

// export default AddVideo;



///////////////////////////////////////////////////////////////////////////////////////////////

// import React, { useState, useEffect } from 'react';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import CloseIcon from '@mui/icons-material/Close';
// import { notifySuccess, notifyError, notifyInfo } from '../../../../utils/notifyToasts';
// import AdminUploadURL from '../../../../utilities/api/tutorials/AdminUploadURL';

// const AddVideo = ({ onRemove, onUploadSuccess, isFormFilled, handleUploading }) => {
//   const [videoFile, setVideoFile] = useState(null);
//   const [videoURL, setVideoURL] = useState(null);
//   const [dragging, setDragging] = useState(false);
//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [uploading, setUploading] = useState(false);
//   const [infoToastId, setInfoToastId] = useState(null);

//   useEffect(() => {
//     handleUploading(uploading);
//   }, [uploading, handleUploading]);

//   const formik = useFormik({
//     initialValues: {
//       tutorialName: '',
//       description: '',
//     },
//     validationSchema: Yup.object({
//       tutorialName: Yup.string().required('Required'),
//       description: Yup.string().required('Required'),
//     }),
//     onSubmit: (values) => {
//       // Form submit logic here if needed
//     },
//   });

//   const handleFileInput = (file) => {
//     if (file && file.type === 'video/mp4') {
//       const userConfirmed = window.confirm(`Are you sure you want to upload the file: ${file.name}?`);
//       if (userConfirmed) {
//         setVideoFile(file);
//         setVideoURL(URL.createObjectURL(file));
//         const toastId = notifyInfo('Starting upload...');
//         setInfoToastId(toastId);
//         startUpload(file, toastId);
//       }
//     } else {
//       notifyError('Please upload a .mp4 file');
//     }
//   };

//   const handleDrop = (e) => {
//     e.preventDefault();
//     setDragging(false);
//     const file = e.dataTransfer.files[0];
//     handleFileInput(file);
//   };

//   const handleDragOver = (e) => {
//     e.preventDefault();
//     setDragging(true);
//   };

//   const handleDragLeave = () => {
//     setDragging(false);
//   };

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     handleFileInput(file);
//   };

//   const startUpload = async (file, toastId) => {
//     setUploading(true);
//     setUploadProgress(0);

//     try {
//       console.log('file_name',file.name);
//       const response = await AdminUploadURL.PresignedUrlGet(file.name);
//       const { url } = response;
//       await uploadToS3(file, url, toastId);
//     } catch (err) {
//       console.error('Error generating presigned URL:', err);
//       notifyError('Error generating presigned URL.');
//       setUploadProgress(0);
//       setUploading(false);
//     }
//   };

//   const uploadToS3 = async (file, url, toastId) => {
//     try {
//       const xhr = new XMLHttpRequest();
//       xhr.open('PUT', url);
//       xhr.setRequestHeader('Content-Type', file.type);

//       xhr.upload.onprogress = (event) => {
//         if (event.lengthComputable) {
//           const percentComplete = Math.round((event.loaded / event.total) * 100);
//           setUploadProgress(percentComplete);
//           notifySuccess(`Uploading... ${percentComplete}%`, toastId);
//         }
//       };

//       xhr.onload = () => {
//         if (xhr.status === 200) {
//           const videoUrl = url.split('?')[0]; // Strip query params to get the S3 object URL
//           setVideoURL(videoUrl);
//           notifySuccess('Upload successful!', toastId);
//           onUploadSuccess(videoUrl);
//         } else {
//           notifyError('Error uploading video.');
//         }
//         setUploading(false);
//       };

//       xhr.onerror = () => {
//         notifyError('Error uploading video.');
//         setUploading(false);
//       };

//       xhr.send(file);
//     } catch (err) {
//       console.error('Error uploading video:', err);
//       notifyError('Error uploading video.');
//       setUploading(false);
//     }
//   };

//   const canUpload = isFormFilled && formik.values.tutorialName && formik.values.description;

//   return (
//     <div style={{ marginBottom: '1rem', padding: '20px', border: '2px dotted #ccc', borderRadius: '8px', position: 'relative', display: 'flex', alignItems: 'center' }}>
//       <button
//         onClick={onRemove}
//         style={{
//           position: 'absolute',
//           fontSize: '1.2rem',
//           top: '10px',
//           right: '10px',
//           color: 'black',
//           background: 'transparent',
//           border: 'none',
//           width: '20px',
//           height: '20px',
//           cursor: 'pointer',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//         }}
//       >
//         <CloseIcon />
//       </button>
//       {videoURL && (
//         <div style={{ position: 'relative', flex: '1' }}>
//           <video width="300" controls>
//             <source src={videoURL} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         </div>
//       )}
//       <form onSubmit={formik.handleSubmit} style={{ flex: '2', marginLeft: '20px' }}>
//         <div style={{ marginBottom: '20px' }}>
//           <input
//             type="text"
//             placeholder="Tutorial Name"
//             name="tutorialName"
//             value={formik.values.tutorialName}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             style={{ marginRight: '10px', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', width: '80%', marginBottom: '1rem' }}
//           />
//           {formik.touched.tutorialName && formik.errors.tutorialName ? (
//             <div style={{ color: 'red', marginBottom: '1rem' }}>{formik.errors.tutorialName}</div>
//           ) : null}
//           <input
//             type="text"
//             placeholder="Description"
//             name="description"
//             value={formik.values.description}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc', width: '80%' }}
//           />
//           {formik.touched.description && formik.errors.description ? (
//             <div style={{ color: 'red' }}>{formik.errors.description}</div>
//           ) : null}
//         </div>
//         {!videoURL && (
//           <div
//             onDrop={canUpload ? handleDrop : null}
//             onDragOver={canUpload ? handleDragOver : null}
//             onDragLeave={canUpload ? handleDragLeave : null}
//             style={{
//               border: canUpload ? (dragging ? '2px dashed #000' : '2px dashed #ccc') : '2px dashed #e0e0e0',
//               padding: '20px',
//               textAlign: 'center',
//               borderRadius: '4px',
//               background: canUpload ? (dragging ? '#f0f0f0' : '#fff') : '#f9f9f9',
//               cursor: canUpload ? 'pointer' : 'not-allowed',
//               width: '100%',
//               boxSizing: 'border-box',
//             }}
//             title={!canUpload ? 'Fill details first' : ''}
//           >
//             <input
//               type="file"
//               accept=".mp4"
//               onChange={canUpload ? handleFileChange : null}
//               style={{ display: 'none' }}
//               id="videoUpload"
//               aria-label="Upload Video"
//               disabled={!canUpload}
//             />
//             <label htmlFor="videoUpload" style={{ cursor: canUpload ? 'pointer' : 'not-allowed', display: 'block', width: '100%' }}>
//               {dragging ? 'Drop your video here' : (
//                 <>
//                   <PlayArrowIcon sx={{ fontSize: '3rem' }} />
//                   <br />
//                   Drag & Drop or Choose file
//                 </>
//               )}
//             </label>
//           </div>
//         )}
//         {uploading && (
//           <div style={{ margin: '20px 0', textAlign: 'center' }}>
//             <div style={{ height: '20px', width: '100%', background: '#ccc', borderRadius: '4px' }}>
//               <div
//                 style={{
//                   height: '100%',
//                   width: `${uploadProgress}%`,
//                   background: '#4caf50',
//                   borderRadius: '4px',
//                   transition: 'width 0.2s',
//                 }}
//               />
//             </div>
//             <p>{uploadProgress}%</p>
//           </div>
//         )}
//       </form>
//     </div>
//   );
// };

// export default AddVideo;



// import React, { useState, useEffect } from 'react';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import CloseIcon from '@mui/icons-material/Close';
// import { S3Client } from '@aws-sdk/client-s3';
// import { Upload as S3Upload } from '@aws-sdk/lib-storage';
// import { notifySuccess, notifyError, notifyInfo } from '../../../../utils/notifyToasts';

// const AddVideo = ({ onRemove, onUploadSuccess, isFormFilled, handleUploading }) => {
//   const [videoFile, setVideoFile] = useState(null);
//   const [videoURL, setVideoURL] = useState(null);
//   const [dragging, setDragging] = useState(false);
//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [uploading, setUploading] = useState(false);
//   const [infoToastId, setInfoToastId] = useState(null);

//   const config = {
//     bucketName: process.env.REACT_APP_BUCKET_NAME,
//     region: process.env.REACT_APP_REGION,
//     credentials: {
//       accessKeyId: process.env.REACT_APP_ACCESS,
//       secretAccessKey: process.env.REACT_APP_SECRET,
//     },
//   };

//   useEffect(() => {
//     handleUploading(uploading);
//   }, [uploading, handleUploading]);

//   const formik = useFormik({
//     initialValues: {
//       tutorialName: '',
//       description: '',
//     },
//     validationSchema: Yup.object({
//       tutorialName: Yup.string().required('Required'),
//       description: Yup.string().required('Required'),
//     }),
//     onSubmit: (values) => {
//       // Form submit logic here if needed
//     },
//   });

//   const handleFileInput = (file) => {
//     if (file && file.type === 'video/mp4') {
//       const userConfirmed = window.confirm(`Are you sure you want to upload the file: ${file.name}?`);
//       if (userConfirmed) {
//         setVideoFile(file);
//         setVideoURL(URL.createObjectURL(file));
//         const toastId = notifyInfo('Starting upload...');
//         setInfoToastId(toastId);
//         startUpload(file, toastId);
//       }
//     } else {
//       notifyError('Please upload a .mp4 file');
//     }
//   };

//   const handleDrop = (e) => {
//     e.preventDefault();
//     setDragging(false);
//     const file = e.dataTransfer.files[0];
//     handleFileInput(file);
//   };

//   const handleDragOver = (e) => {
//     e.preventDefault();
//     setDragging(true);
//   };

//   const handleDragLeave = () => {
//     setDragging(false);
//   };

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     handleFileInput(file);
//   };

//   const startUpload = async (file, toastId) => {
//     setUploading(true);
//     setUploadProgress(0);

//     const s3Client = new S3Client({ region: config.region, credentials: config.credentials });

//     try {
//       const params = {
//         Bucket: config.bucketName,
//         Key: file.name,
//         Body: file,
//         ContentType: file.type,
//       };

//       const upload = new S3Upload({
//         client: s3Client,
//         params,
//         leavePartsOnError: false,
//       });

//       upload.on('httpUploadProgress', (progress) => {
//         const percent = Math.round((progress.loaded / progress.total) * 100);
//         setUploadProgress(percent);
//         notifySuccess(`Uploading... ${percent}%`, toastId);
//       });

//       await upload.done();

//       const videoUrl = `https://${config.bucketName}.s3.${config.region}.amazonaws.com/${file.name}`;
//       setVideoURL(videoUrl);
//       notifySuccess('Upload successful!', toastId);
//       onUploadSuccess(videoUrl);
//     } catch (err) {
//       console.error('Error uploading video:', err);
//       notifyError('Error uploading video.');
//       setUploadProgress(0);
//     } finally {
//       setUploading(false);
//     }
//   };

//   const canUpload = isFormFilled && formik.values.tutorialName && formik.values.description;

//   return (
//     <div style={{ marginBottom: '1rem', padding: '20px', border: '2px dotted #ccc', borderRadius: '8px', position: 'relative', display: 'flex', alignItems: 'center' }}>
//       <button
//         onClick={onRemove}
//         style={{
//           position: 'absolute',
//           fontSize: '1.2rem',
//           top: '10px',
//           right: '10px',
//           color: 'black',
//           background: 'transparent',
//           border: 'none',
//           width: '20px',
//           height: '20px',
//           cursor: 'pointer',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//         }}
//       >
//         <CloseIcon />
//       </button>
//       {videoURL && (
//         <div style={{ position: 'relative', flex: '1' }}>
//           <video width="300" controls>
//             <source src={videoURL} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         </div>
//       )}
//       <form onSubmit={formik.handleSubmit} style={{ flex: '2', marginLeft: '20px' }}>
//         <div style={{ marginBottom: '20px' }}>
//           <input
//             type="text"
//             placeholder="Tutorial Name"
//             name="tutorialName"
//             value={formik.values.tutorialName}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             style={{ marginRight: '10px', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', width: '80%', marginBottom: '1rem' }}
//           />
//           {formik.touched.tutorialName && formik.errors.tutorialName ? (
//             <div style={{ color: 'red', marginBottom: '1rem' }}>{formik.errors.tutorialName}</div>
//           ) : null}
//           <input
//             type="text"
//             placeholder="Description"
//             name="description"
//             value={formik.values.description}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc', width: '80%' }}
//           />
//           {formik.touched.description && formik.errors.description ? (
//             <div style={{ color: 'red' }}>{formik.errors.description}</div>
//           ) : null}
//         </div>
//         {!videoURL && (
//           <div
//             onDrop={canUpload ? handleDrop : null}
//             onDragOver={canUpload ? handleDragOver : null}
//             onDragLeave={canUpload ? handleDragLeave : null}
//             style={{
//               border: canUpload ? (dragging ? '2px dashed #000' : '2px dashed #ccc') : '2px dashed #e0e0e0',
//               padding: '20px',
//               textAlign: 'center',
//               borderRadius: '4px',
//               background: canUpload ? (dragging ? '#f0f0f0' : '#fff') : '#f9f9f9',
//               cursor: canUpload ? 'pointer' : 'not-allowed',
//               width: '100%',
//               boxSizing: 'border-box',
//             }}
//             title={!canUpload ? 'Fill details first' : ''}
//           >
//             <input
//               type="file"
//               accept=".mp4"
//               onChange={canUpload ? handleFileChange : null}
//               style={{ display: 'none' }}
//               id="videoUpload"
//               aria-label="Upload Video"
//               disabled={!canUpload}
//             />
//             <label htmlFor="videoUpload" style={{ cursor: canUpload ? 'pointer' : 'not-allowed', display: 'block', width: '100%' }}>
//               {dragging ? 'Drop your video here' : (
//                 <>
//                   <PlayArrowIcon sx={{ fontSize: '3rem' }} />
//                   <br />
//                   Drag & Drop or Choose file
//                 </>
//               )}
//             </label>
//           </div>
//         )}
//         {uploading && (
//           <div style={{ margin: '20px 0', textAlign: 'center' }}>
//             <div style={{ height: '20px', width: '100%', background: '#ccc', borderRadius: '4px' }}>
//               <div
//                 style={{
//                   height: '100%',
//                   width: `${uploadProgress}%`,
//                   background: '#4caf50',
//                   borderRadius: '4px',
//                   transition: 'width 0.2s',
//                 }}
//               />
//             </div>
//             <p>{uploadProgress}%</p>
//           </div>
//         )}
//       </form>
//     </div>
//   );
// };

// export default AddVideo;




// import React, { useState, useEffect } from 'react';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import CloseIcon from '@mui/icons-material/Close';
// import { S3Client } from '@aws-sdk/client-s3';
// import { Upload as S3Upload } from '@aws-sdk/lib-storage';

// const AddVideo = ({ onRemove, onUploadSuccess, isFormFilled, handleUploading }) => {
//   const [videoFile, setVideoFile] = useState(null);
//   const [videoURL, setVideoURL] = useState(null);
//   const [dragging, setDragging] = useState(false);
//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [uploading, setUploading] = useState(false);

//   const config = {
//     bucketName: process.env.REACT_APP_BUCKET_NAME,
//     region: process.env.REACT_APP_REGION,
//     credentials: {
//       accessKeyId: process.env.REACT_APP_ACCESS,
//       secretAccessKey: process.env.REACT_APP_SECRET,
//     },
//   };

//   useEffect(() => {
//     handleUploading(uploading);
//   }, [uploading, handleUploading]);

//   const formik = useFormik({
//     initialValues: {
//       tutorialName: '',
//       description: '',
//     },
//     validationSchema: Yup.object({
//       tutorialName: Yup.string().required('Required'),
//       description: Yup.string().required('Required'),
//     }),
//     onSubmit: (values) => {
//       // Form submit logic here if needed
//     },
//   });

//   const handleFileInput = (file) => {
//     if (file && file.type === 'video/mp4') {
//       const userConfirmed = window.confirm(`Are you sure you want to upload the file: ${file.name}?`);
//       if (userConfirmed) {
//         setVideoFile(file);
//         setVideoURL(URL.createObjectURL(file));
//         startUpload(file);
//       }
//     } else {
//       alert('Please upload a .mp4 file');
//     }
//   };

//   const handleDrop = (e) => {
//     e.preventDefault();
//     setDragging(false);
//     const file = e.dataTransfer.files[0];
//     handleFileInput(file);
//   };

//   const handleDragOver = (e) => {
//     e.preventDefault();
//     setDragging(true);
//   };

//   const handleDragLeave = () => {
//     setDragging(false);
//   };

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     handleFileInput(file);
//   };

//   const startUpload = async (file) => {
//     setUploading(true);
//     setUploadProgress(0);

//     const s3Client = new S3Client({ region: config.region, credentials: config.credentials });

//     try {
//       const params = {
//         Bucket: config.bucketName,
//         Key: file.name,
//         Body: file,
//         ContentType: file.type,
//       };

//       const upload = new S3Upload({
//         client: s3Client,
//         params,
//         leavePartsOnError: false,
//       });

//       upload.on('httpUploadProgress', (progress) => {
//         const percent = Math.round((progress.loaded / progress.total) * 100);
//         setUploadProgress(percent);
//       });

//       await upload.done();

//       const videoUrl = `https://${config.bucketName}.s3.${config.region}.amazonaws.com/${file.name}`;
//       setVideoURL(videoUrl);
//       console.log('Video URL:', videoUrl);
//       onUploadSuccess(videoUrl);
//     } catch (err) {
//       console.error('Error uploading video:', err);
//       alert('Error uploading video.');
//       setUploadProgress(0);
//     } finally {
//       setUploading(false);
//     }
//   };

//   const canUpload = isFormFilled && formik.values.tutorialName && formik.values.description;

//   return (
//     <div style={{ marginBottom: '1rem', padding: '20px', border: '2px dotted #ccc', borderRadius: '8px', position: 'relative', display: 'flex', alignItems: 'center' }}>
//       <button
//         onClick={onRemove}
//         style={{
//           position: 'absolute',
//           fontSize: '1.2rem',
//           top: '10px',
//           right: '10px',
//           color: 'black',
//           background: 'transparent',
//           border: 'none',
//           width: '20px',
//           height: '20px',
//           cursor: 'pointer',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//         }}
//       >
//         <CloseIcon />
//       </button>
//       {videoURL && (
//         <div style={{ position: 'relative', flex: '1' }}>
//           <video width="300" controls>
//             <source src={videoURL} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         </div>
//       )}
//       <form onSubmit={formik.handleSubmit} style={{ flex: '2', marginLeft: '20px' }}>
//         <div style={{ marginBottom: '20px' }}>
//           <input
//             type="text"
//             placeholder="Tutorial Name"
//             name="tutorialName"
//             value={formik.values.tutorialName}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             style={{ marginRight: '10px', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', width: '80%', marginBottom: '1rem' }}
//           />
//           {formik.touched.tutorialName && formik.errors.tutorialName ? (
//             <div style={{ color: 'red', marginBottom: '1rem' }}>{formik.errors.tutorialName}</div>
//           ) : null}
//           <input
//             type="text"
//             placeholder="Description"
//             name="description"
//             value={formik.values.description}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc', width: '80%' }}
//           />
//           {formik.touched.description && formik.errors.description ? (
//             <div style={{ color: 'red' }}>{formik.errors.description}</div>
//           ) : null}
//         </div>
//         {!videoURL && (
//           <div
//             onDrop={canUpload ? handleDrop : null}
//             onDragOver={canUpload ? handleDragOver : null}
//             onDragLeave={canUpload ? handleDragLeave : null}
//             style={{
//               border: canUpload ? (dragging ? '2px dashed #000' : '2px dashed #ccc') : '2px dashed #e0e0e0',
//               padding: '20px',
//               textAlign: 'center',
//               borderRadius: '4px',
//               background: canUpload ? (dragging ? '#f0f0f0' : '#fff') : '#f9f9f9',
//               cursor: canUpload ? 'pointer' : 'not-allowed',
//               width: '100%',
//               boxSizing: 'border-box',
//             }}
//             title={!canUpload ? 'Fill details first' : ''}
//           >
//             <input
//               type="file"
//               accept=".mp4"
//               onChange={canUpload ? handleFileChange : null}
//               style={{ display: 'none' }}
//               id="videoUpload"
//               aria-label="Upload Video"
//               disabled={!canUpload}
//             />
//             <label htmlFor="videoUpload" style={{ cursor: canUpload ? 'pointer' : 'not-allowed', display: 'block', width: '100%' }}>
//               {dragging ? 'Drop your video here' : (
//                 <>
//                   <PlayArrowIcon sx={{ fontSize: '3rem' }} />
//                   <br />
//                   Drag & Drop or Choose file
//                 </>
//               )}
//             </label>
//           </div>
//         )}
//         {uploading && (
//           <div style={{ margin: '20px 0', textAlign: 'center' }}>
//             <div style={{ height: '20px', width: '100%', background: '#ccc', borderRadius: '4px' }}>
//               <div
//                 style={{
//                   height: '100%',
//                   width: `${uploadProgress}%`,
//                   background: '#4caf50',
//                   borderRadius: '4px',
//                   transition: 'width 0.2s',
//                 }}
//               />
//             </div>
//             <p>{uploadProgress}%</p>
//           </div>
//         )}
//       </form>
//     </div>
//   );
// };

// export default AddVideo;