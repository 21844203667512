// CollegeInfo.js
import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { TextField, Button, MenuItem, Grid, Box, Typography, FormControl, Select, InputLabel, FormHelperText } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup';
import AdminUserDetail from '../../utilities/api/user/AdminUserDetail';
import { getUserSuccess, updateUser } from '../../redux/slices/userSlice';

const CollegeInfo = ({ user, refreshUserData }) => {
  console.log('CollegeInfo user',user);
  const dispatch = useDispatch();
  const [editMode, setEditMode] = React.useState(false);

  const handleEdit = () => setEditMode(!editMode);

  const handleSave = async (values) => {
    try {
      await AdminUserDetail.AdminDetailUpdate(user.id, values);
      const updatedUser = await AdminUserDetail.AdminDetailGet(user.id);
      dispatch(updateUser(updatedUser));
      await refreshUserData();
      setEditMode(false);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const validationSchema = Yup.object().shape({
    department: Yup.string().required('Department is required'),
    degree: Yup.string().required('Degree is required'),
    current_year: Yup.number().required('Current Year is required'),
    graduationYear: Yup.number().required('Graduation Year is required'),
  });

  const formik = useFormik({
    initialValues: {
      department: user.department,
      degree: user.degree,
      current_year: user.current_year,
      graduationYear: user.graduation_year,
    },
    validationSchema,
    onSubmit: (values) => handleSave(values),
  });

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', margin: '1rem', width: '95%', border: '1px solid #D3D3D3', borderRadius: '10px', padding: '25px' }}>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
          <Typography variant="h4" sx={{ color: '#007BFF' }}>College Information</Typography>
          {!editMode && (
            <Box sx={{ position: 'absolute', right: '20px', cursor: 'pointer' }}>
              <Typography onClick={handleEdit} sx={{ display: 'flex', alignItems: 'center', padding: '4px 10px', color: 'rgb(122, 122, 122)', fontSize: '1.1rem', border: '1px solid rgb(122, 122, 122)', borderRadius: '20px' }}>Edit<EditIcon sx={{ fontSize: '1rem', color: 'rgb(122, 122, 122)', marginLeft: '5px' }} /></Typography>
            </Box>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ marginBottom: '3px', fontSize: '1.4rem', color: 'rgb(122, 122, 122)' }}>Branch</Typography>
            <FormControl fullWidth>
              <InputLabel id="department-label">Branch</InputLabel>
              <Select labelId="department-label" id="department" name="department" value={formik.values.department} label="department" onChange={formik.handleChange} error={formik.touched.department && Boolean(formik.errors.department)} disabled={!editMode}>
                {['CSE', 'ECE', 'ME', 'CE', 'EE', 'CHE', 'MME', 'BT', 'CL', 'MA', 'PH', 'HSS', 'Other'].map((option, index) => (
                  <MenuItem key={index} value={option}>{option}</MenuItem>
                ))}
              </Select>
              {formik.touched.department && formik.errors.department && <FormHelperText error>{formik.errors.department}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ marginBottom: '3px', fontSize: '1.4rem', color: 'rgb(122, 122, 122)' }}>Current Year</Typography>
            <FormControl fullWidth>
              <InputLabel id="current-year-label">Current Year</InputLabel>
              <Select labelId="current-year-label" id="current_year" name="current_year" value={formik.values.current_year} label="current_year" onChange={formik.handleChange} error={formik.touched.current_year && Boolean(formik.errors.current_year)} disabled={!editMode}>
                {[{ value: 1, label: 'Fresher' }, { value: 2, label: 'Sophomore' }, { value: 3, label: 'Third' }, { value: 4, label: 'Fourth' }, { value: 5, label: 'Fifth' }].map((option) => (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
              {formik.touched.current_year && formik.errors.current_year && <FormHelperText error>{formik.errors.current_year}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ marginBottom: '3px', fontSize: '1.4rem', color: 'rgb(122, 122, 122)' }}>Degree</Typography>
            <FormControl fullWidth>
              <InputLabel id="degree-label">Degree</InputLabel>
              <Select labelId="degree-label" id="degree" name="degree" value={formik.values.degree} label="degree" onChange={formik.handleChange} error={formik.touched.degree && Boolean(formik.errors.degree)} disabled={!editMode}>
                {['B.Tech', 'M.Tech', 'M.Sc', 'PhD', 'MCA', 'MBA', 'Other'].map((option, index) => (
                  <MenuItem key={index} value={option}>{option}</MenuItem>
                ))}
              </Select>
              {formik.touched.degree && formik.errors.degree && <FormHelperText error>{formik.errors.degree}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ marginBottom: '3px', fontSize: '1.4rem', color: 'rgb(122, 122, 122)' }}>Graduation Year</Typography>
            <TextField label="Graduation Year" variant="outlined" name="graduationYear" value={formik.values.graduationYear} onChange={formik.handleChange} fullWidth disabled={!editMode} />
          </Grid>
        </Grid>
        {editMode && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
            <Button type="submit" variant="contained" sx={{ display: 'flex', alignItems: 'center', padding: '4px 10px', color: 'white', fontSize: '1.1rem', borderRadius: '20px', backgroundColor: 'rgb(0, 123, 255)' }}>Save</Button>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default CollegeInfo;

