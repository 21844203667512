import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AdminVideoAPIs from '../../../../utilities/api/tutorials/AdminVideoAPIs';

const EditVideo = ({ video, onRemove, onChange }) => {
  const [videoData, setVideoData] = useState(video);

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const data = await AdminVideoAPIs.VideoDetailGet(video.id);
        setVideoData((prevData) => ({
          ...prevData,
          title: data.title || prevData.title,
          description: data.description || prevData.description,
          link: data.link,  // Ensure link is updated correctly from the API
        }));
      } catch (error) {
        console.error('Error fetching video data:', error);
      }
    };

    fetchVideoData();
  }, [video.id]);

  const handleFieldChange = (field, value) => {
    setVideoData((prevData) => ({ ...prevData, [field]: value }));
    onChange(video.id, field, value);
  };

  return (
    <div style={{ marginBottom: '1rem', padding: '20px', border: '2px dotted #ccc', borderRadius: '8px', position: 'relative', display: 'flex', alignItems: 'center' }}>
      <button
        onClick={onRemove}
        style={{
          position: 'absolute',
          fontSize: '1.2rem',
          top: '10px',
          right: '10px',
          color: 'black',
          background: 'transparent',
          border: 'none',
          width: '20px',
          height: '20px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CloseIcon />
      </button>
      {videoData.link && (
        <div style={{ position: 'relative', flex: '1' }}>
          <video width="300" controls>
            <source src={videoData.link} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      <div style={{ flex: '2', marginLeft: '20px' }}>
        <TextField
          label="Title"
          value={videoData.title}
          onChange={(e) => handleFieldChange('title', e.target.value)}
          fullWidth
          style={{ marginBottom: '1rem' }}
        />
        <TextField
          label="Description"
          value={videoData.description}
          onChange={(e) => handleFieldChange('description', e.target.value)}
          fullWidth
          multiline
          rows={2}
        />
      </div>
    </div>
  );
};

export default EditVideo;




// import React, { useState, useEffect } from 'react';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import CloseIcon from '@mui/icons-material/Close';
// import AdminVideoAPIs from '../../../../utilities/api/tutorials/AdminVideoAPIs';
// import { notifySuccess, notifyError, notifyInfo } from '../../../../utils/notifyToasts';

// const EditVideo = ({ videoId, onRemove, onEditSuccess }) => {
//   const [videoData, setVideoData] = useState(null);
//   const [videoURL, setVideoURL] = useState(null);

//   useEffect(() => {
//     const fetchVideoData = async () => {
//       try {
//         const data = await AdminVideoAPIs.VideoDetailGet(videoId);
//         setVideoData(data);
//         setVideoURL(data.link);
//       } catch (error) {
//         console.error('Error fetching video data:', error);
//       }
//     };

//     fetchVideoData();
//   }, [videoId]);

//   const formik = useFormik({
//     initialValues: {
//       title: videoData?.title || '',
//       description: videoData?.description || '',
//     },
//     enableReinitialize: true,
//     validationSchema: Yup.object({
//       title: Yup.string().required('Required'),
//       description: Yup.string().required('Required'),
//     }),
//     onSubmit: async (values) => {
//       try {
//         await AdminVideoAPIs.UpdateVideo(videoId, values);
//         notifySuccess('Video updated successfully');
//         onEditSuccess(videoId, videoURL);
//       } catch (error) {
//         notifyError('Error updating video');
//       }
//     },
//   });

//   return (
//     <div style={{ marginBottom: '1rem', padding: '20px', border: '2px dotted #ccc', borderRadius: '8px', position: 'relative', display: 'flex', alignItems: 'center' }}>
//       <button
//         onClick={onRemove}
//         style={{
//           position: 'absolute',
//           fontSize: '1.2rem',
//           top: '10px',
//           right: '10px',
//           color: 'black',
//           background: 'transparent',
//           border: 'none',
//           width: '20px',
//           height: '20px',
//           cursor: 'pointer',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//         }}
//       >
//         <CloseIcon />
//       </button>
//       {videoURL && (
//         <div style={{ position: 'relative', flex: '1' }}>
//           <video width="300" controls>
//             <source src={videoURL} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         </div>
//       )}
//       <form onSubmit={formik.handleSubmit} style={{ flex: '2', marginLeft: '20px' }}>
//         <div style={{ marginBottom: '20px' }}>
//           <input
//             type="text"
//             placeholder="Title"
//             name="title"
//             value={formik.values.title}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             style={{ marginRight: '10px', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', width: '80%', marginBottom: '1rem' }}
//           />
//           {formik.touched.title && formik.errors.title ? (
//             <div style={{ color: 'red', marginBottom: '1rem' }}>{formik.errors.title}</div>
//           ) : null}
//           <input
//             type="text"
//             placeholder="Description"
//             name="description"
//             value={formik.values.description}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc', width: '80%' }}
//           />
//           {formik.touched.description && formik.errors.description ? (
//             <div style={{ color: 'red' }}>{formik.errors.description}</div>
//           ) : null}
//         </div>
//         <button type="submit" style={{ padding: '10px 20px', borderRadius: '4px', border: 'none', background: '#007BFF', color: 'white' }}>
//           Save Changes
//         </button>
//       </form>
//     </div>
//   );
// };

// export default EditVideo;
