import React from 'react';
import { Container, Grid, Box } from '@mui/material';
import InfoCards from './InfoCards';
import useFetchData from './AdminDashboardCharts/useFetchData';
import MostUsedMachinesChart from './AdminDashboardCharts/MostUsedMachinesChart';
import WeeklyStatisticsChart from './AdminDashboardCharts/WeeklyStatisticsChart';
import CostVsUseChart from './AdminDashboardCharts/CostVsUseChart';
import AdminRolesChart from './AdminDashboardCharts/AdminRolesChart';
import KeyProjectsChart from './AdminDashboardCharts/KeyProjectsChart';
import MostIssuedItemsChart from './AdminDashboardCharts/MostIssuedItemsChart';
import MostPurchasedItemsChart from './AdminDashboardCharts/MostPurchasedItemsChart';

const Dashboard = () => {
  const {
    mostUsedMachinesData,
    mostIssuedItemsData,
    mostPurchasedItemsData,
    weeklyStatisticsData,
    costVsUseData,
    adminRolesData,
    keyProjectsData,
    stats,
  } = useFetchData();

  return (
    <Container sx={{ marginTop: '1rem' }}>
      <InfoCards stats={stats} />
      <Box sx={{ flexGrow: 1, mb:3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <MostUsedMachinesChart data={mostUsedMachinesData} />
          </Grid>
          <Grid item xs={12} md={6}>
            <WeeklyStatisticsChart data={weeklyStatisticsData} />
          </Grid>
          <Grid item xs={12} md={6}>
            <CostVsUseChart data={costVsUseData} />
          </Grid>
          <Grid item xs={12} md={6}>
            <MostIssuedItemsChart data={mostIssuedItemsData} />
          </Grid>
          <Grid item xs={12} md={6}>
            <KeyProjectsChart data={keyProjectsData} />
          </Grid>
          <Grid item xs={12} md={6}>
            <AdminRolesChart data={adminRolesData} />
          </Grid>
          <Grid item xs={12} md={6}>
            <MostPurchasedItemsChart data={mostPurchasedItemsData} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Dashboard;