import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';

const AttendHeaders = () => {
  const cellStyles = {
    // padding: '0.5rem 0.75rem', // Reduced padding
    borderBottom: '1px solid #F2F2F2',
    textAlign: 'center',
  };

  const labelStyles = {
    color: '#5C5C5C',
    fontWeight: '600',
    fontSize: '1rem', // Increased text size
  };

  const columnWidths = ['30%','25%','26%','6%'];

  const headers = [
    'Registrant',
    'Present',
    'Absent',
    'Delete'
  ];

  return (
    <Table sx={{ width: '100%' }}>
      <TableHead>
        <TableRow sx={{ bgcolor: '#F7F7F7', borderBottom: '1px solid #F2F2F2' }}>
          {headers.map((header, index) => (
            <TableCell key={header} sx={{ ...cellStyles, width: columnWidths[index] }}>
              <TableSortLabel hideSortIcon sx={labelStyles}>
                {header}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </Table>
  );
};

export default AttendHeaders;
