import React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { toggleEditMode, setCurrentItem } from '../../../redux/slices/editModeSlice'; // Adjust the path as per your file structure
import AdminWorkshopAPIs from '../../../utilities/api/workshop/AdminWorkshopAPIs';

const TableListRow = ({ item, onDelete }) => {
  const dispatch = useDispatch();
  const isEdit = useSelector((state) => state.editMode.editMode); // Accessing editMode state from Redux
  const user = useSelector(state => state.user.user);
  const token = user.access;

  console.log('TableListRow', item);

  const calculateAttendance = (attendees) => {
    return attendees.filter(attendee => attendee.is_present).length;
  };

  const isWorkshopCompleted = (fromDate, fromTime, toDate, toTime) => {
    const currentDate = new Date();
    const startDate = new Date(`${fromDate}T${fromTime}`);
    const endDate = new Date(`${toDate}T${toTime}`);
    if (endDate < currentDate) {
      return 'completed';
    } else if (startDate <= currentDate && currentDate <= endDate) {
      return 'ongoing';
    } else {
      return 'upcoming';
    }
  };

  item.tag = isWorkshopCompleted(item.from_date, item.from_time, item.to_date, item.to_time);

  const buttonStyles = {
    display: 'flex',
    width: '100%',
    padding: '0px',
    margin: '0px',
    minWidth: '0px',
    color: '#273F77',
    backgroundColor: 'transparent',
    boxShadow: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  };

  const handleOpenDialog = () => {
    dispatch(toggleEditMode()); // Dispatching toggle edit mode action
    dispatch(setCurrentItem(item)); // Dispatching set item id action with item id
  };

  const handleDelete = async () => {
    try {
      await AdminWorkshopAPIs.workshopDetailDelete(item.id, token);
      onDelete(item.id); // Notify parent component about deletion
      console.log('Workshop deleted successfully');
    } catch (error) {
      console.error('Error deleting workshop:', error);
    }
  };

  const handleOverflow = (event) => {
    const element = event.target;
    element.style.maxHeight = '2rem';
    element.style.overflow = 'hidden';
    element.style.textOverflow = 'ellipsis';
  };

  return (
    <Box sx={{ overflowX: 'auto', maxHeight: 'calc(100vh - 20px)', overflowY: 'auto' }}>
      <Card sx={{ display: 'flex', alignItems: 'center', bgcolor: 'white', marginBottom: '8px', width: '100%', height: '100%' }}>
        <Typography
          sx={{
            textAlign: 'center',
            color: '#898989',
            fontWeight: '400',
            fontSize: '1rem',
            padding: '0.875rem 0.75rem',
            width: '17%',
            wordWrap: 'break-word',
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            maxHeight: '2rem',
          }}
          onOverflow={handleOverflow}
        >
          {item.title || 'N/A'}
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            color: '#898989',
            fontWeight: '400',
            fontSize: '1rem',
            padding: '0.875rem 0.75rem',
            width: '15%',
            wordWrap: 'break-word',
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            maxHeight: '2rem',
          }}
          onOverflow={handleOverflow}
        >
          {item.description || 'N/A'}
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            color: '#898989',
            fontWeight: '400',
            fontSize: '1rem',
            padding: '0.875rem 0.75rem',
            width: '20%',
            wordWrap: 'break-word',
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            maxHeight: '2rem',
          }}
          onOverflow={handleOverflow}
        >
          {`${item.from_date},${item.from_time} TO ${item.to_date},${item.to_time}` || 'N/A'}
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            color: '#898989',
            fontWeight: '400',
            fontSize: '1rem',
            padding: '0.875rem 0.75rem',
            width: '10%',
            wordWrap: 'break-word',
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
          }}
        >
          {item.attendees.length || 'N/A'}
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            color: '#898989',
            fontWeight: '400',
            fontSize: '1rem',
            padding: '0.875rem 0.75rem',
            width: '10%',
            wordWrap: 'break-word',
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
          }}
        >
          <span>{item.tag || 'N/A'}</span>
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            color: '#898989',
            fontWeight: '400',
            fontSize: '1rem',
            padding: '0.875rem 0.75rem',
            width: '10%',
            wordWrap: 'break-word',
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
          }}
        >
          {item.attendees ? calculateAttendance(item.attendees) : 'N/A'}
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            overflow: 'hidden',
            width: '10%',
            justifyContent: 'center',
            fontSize: '0.5rem',
            marginInline: '0.5rem',
          }}
        >
          <Button variant="contained" sx={buttonStyles} onClick={handleDelete}>
            <DeleteOutlineIcon sx={{ color: 'rgb(208,36,36)' }} />
          </Button>
          <Button variant="contained" sx={buttonStyles} onClick={handleOpenDialog}>
            <VisibilityIcon sx={{ color: 'rgb(0,123,255)' }} />
          </Button>
          {item.tag !== 'completed' && (
            <Button variant="contained" sx={buttonStyles} onClick={handleOpenDialog}>
              <EditIcon />
            </Button>
          )}
        </Typography>
      </Card>
    </Box>
  );
};

export default TableListRow;