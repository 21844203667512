import React, { useState } from 'react';
import { Box, InputBase, IconButton } from '@mui/material';
import { Send } from '@mui/icons-material';

const MessageInput = ({ onSendMessage }) => {
    const [message, setMessage] = useState("");

    const handleInputChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (message.trim()) {
            onSendMessage(message);
            setMessage("");
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', alignItems: 'center', padding: '10px', borderTop: '1px solid #ddd', backgroundColor: '#fff' }}>
            <InputBase sx={{ flex: 1, padding: '5px 10px', borderRadius: '20px', border: '1px solid #ddd', marginRight: '10px' }} placeholder="Enter your message" inputProps={{ 'aria-label': 'enter your message' }} value={message} onChange={handleInputChange} />
            <IconButton type="submit"><Send color="primary" /></IconButton>
        </Box>
    );
};

export default MessageInput;
